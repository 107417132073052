import { SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS } from 'actions';
import { CustomerLocation } from 'typings';

export function setCustomerLookupCustomerLocations(
  customerLookupCustomerLocations: Array<CustomerLocation>,
  customerLookupAllowLocationsNotInServiceZone: boolean,
) {
  return {
    customerLookupAllowLocationsNotInServiceZone,
    customerLookupCustomerLocations,
    type: SET_CUSTOMER_LOOKUP_CUSTOMER_LOCATIONS,
  };
}
