import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'components/Modal';
import { IState } from 'typings';

export const LoginModal = ({ children }: React.PropsWithChildren<unknown>) => {
  const { isVisible } = useSelector((state: IState) => ({
    isVisible: state.modal.isLoginModalVisible,
  }));

  return (
    <Modal height={716} isHeightStatic={false} isVisible={isVisible}>
      {children}
    </Modal>
  );
};
