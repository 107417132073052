import { ContextBubbleAnimation } from '@homex/se-react-components';

import {
  hideServiceModal,
  resetServiceCarouselForCsr,
  setCurrentCarouselItem,
} from 'actions';
import {
  Button,
  ContextBubbleStyled,
  Label,
  LabelContainer,
  Svg,
  SvgContainer,
} from 'components/CarouselBottomButtonArrowSvg';
import { Phrase } from 'components/Phrase';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselBottomButtonType,
  CarouselReduxReducer,
  ICarouselContinueButtonProps,
} from 'typings';

export const CarouselContinueButton = ({
  buttonType = CarouselBottomButtonType.CONTINUE,
  closeLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.CLOSE" />,
  errorMessage,
  homeLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.HOME" />,
  isDisabled = false,
  isLoading = false,
  label = <Phrase id="CAROUSEL_CONTINUE_BUTTON.LABEL" />,
  onClick,
  retryLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.RETRY" />,
  destinationItemId,
}: ICarouselContinueButtonProps) => {
  const dispatch = useThunkDispatch();

  const handleClick = () => {
    if (buttonType === CarouselBottomButtonType.CLOSE) {
      dispatch(hideServiceModal());
      dispatch(resetServiceCarouselForCsr());
    } else if (buttonType === CarouselBottomButtonType.HOME) {
      dispatch(resetServiceCarouselForCsr());
    } else if (destinationItemId) {
      dispatch(
        setCurrentCarouselItem(CarouselReduxReducer.SERVICE, destinationItemId),
      );
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      buttonType={buttonType}
      disabled={isDisabled || isLoading}
      isLoading={isLoading}
      onClick={handleClick}
      type="submit"
    >
      {errorMessage && (
        <ContextBubbleStyled
          animation={ContextBubbleAnimation.FADE_IN_OUT}
          pointer={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          {errorMessage}
        </ContextBubbleStyled>
      )}
      {buttonType === CarouselBottomButtonType.BACK && (
        <LabelContainer isLoading={isLoading}>
          <Label isVisible={buttonType === CarouselBottomButtonType.BACK}>
            {label}
          </Label>
        </LabelContainer>
      )}
      <SvgContainer buttonType={buttonType} height={54} isLoading={isLoading}>
        <Svg
          buttonType={buttonType}
          height={54}
          isLoading={isLoading}
          width={54}
        />
      </SvgContainer>
      <LabelContainer isLoading={isLoading}>
        <Label isVisible={buttonType === CarouselBottomButtonType.RETRY}>
          {retryLabel}
        </Label>
        <Label isVisible={buttonType === CarouselBottomButtonType.HOME}>
          {homeLabel}
        </Label>
        <Label isVisible={buttonType === CarouselBottomButtonType.CLOSE}>
          {closeLabel}
        </Label>
        <Label
          isVisible={
            buttonType === CarouselBottomButtonType.CONTINUE ||
            buttonType === CarouselBottomButtonType.CONTINUE_DOWN
          }
        >
          {label}
        </Label>
      </LabelContainer>
    </Button>
  );
};
