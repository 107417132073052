import { useEffect } from 'react';

import { setViewportSize } from 'actions';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { ViewportSize } from 'typings';

// Borrowed from: https://usehooks.com/useMedia/
export const useMedia = <T extends ViewportSize>(
  queries: Array<string>,
  values: Array<T>,
  defaultValue: T,
) => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    // Array containing a media query list for each query
    const mediaQueryLists = queries.map((q) => window.matchMedia(q));

    // Function that gets value based on matching media query
    const getValue = (): T => {
      // Get index of first media query that matches
      const index = mediaQueryLists.findIndex((mql) => mql.matches);

      // Return related value or defaultValue if none
      return values?.[index] || defaultValue;
    };

    // Event listener callback
    const handler = () => dispatch(setViewportSize(getValue()));

    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => {
      // Fixes issue in Safari 13
      if (typeof mql.addListener !== 'undefined') {
        mql.addListener(handler);
      } else {
        mql.addEventListener('change', handler);
      }
    });

    // Remove listeners on cleanup
    return () =>
      mediaQueryLists.forEach((mql) =>
        mql.removeEventListener('change', handler),
      );
  }, [defaultValue, dispatch, queries, values]);
};
