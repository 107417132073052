import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { SET_HAS_INTERACTION } from 'actions';
import {
  GoogleAnalyticsAction,
  IState,
  SeAnalyticsAction,
  SeAnalyticsCategory,
  SeEmbedEvent,
} from 'typings';
import analytics from 'utils/analytics';
import { buildGoogleAnalyticsEvent } from 'utils/buildGoogleAnalyticsEvent';
import { buildSeAnalyticsEvent } from 'utils/buildSeAnalyticsEvent';
import postMessageToParent from 'utils/postMessageToParent';

export function reportWidgetInteraction() {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const state = getState();
    const {
      client: {
        analyticsTrackingEnabled,
        flow,
        googleAnalyticsTrackingEnabled,
        validOrigins,
      },
      modal: { hasInteraction, isServiceModalVisible },
    } = state;

    if (!isServiceModalVisible || hasInteraction) {
      return;
    }

    if (googleAnalyticsTrackingEnabled) {
      postMessageToParent(
        {
          ga: buildGoogleAnalyticsEvent(
            GoogleAnalyticsAction.BOOKING_PROCESS_STARTED,
            state,
          ),
          type: 'se-send-client-google-analytics',
        },
        validOrigins,
      );
    }

    if (analyticsTrackingEnabled) {
      analytics.send(
        'event',
        buildSeAnalyticsEvent(
          SeAnalyticsCategory.CAROUSEL,
          SeAnalyticsAction.INTERACTION,
          state,
        ),
      );
    }

    postMessageToParent(
      {
        analyticsKey: flow?.key,
        eventName: SeEmbedEvent.BOOKING_PROCESS_STARTED,
        type: 'se-event',
      },
      validOrigins,
    );

    dispatch({ type: SET_HAS_INTERACTION });
  };
}
