import {
  ADJUST_CAROUSEL_BOTTOM_HEIGHT,
  ADJUST_CAROUSEL_TOP_HEIGHT,
  SET_CAROUSEL_BOTTOM_HEIGHT,
  SET_CAROUSEL_HISTORY,
  SET_CAROUSEL_NATURAL_TOP_HEIGHT,
  SET_CAROUSEL_TOP_HEIGHT,
  SET_CURRENT_CAROUSEL_ITEM,
  SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS,
  SET_SHOULD_RECALCULATE_HEIGHT,
} from 'actions';
import { CarouselReduxReducer, ICarouselAction, ICarouselState } from 'typings';

export function carousel<TState extends ICarouselState = ICarouselState>(
  state: TState,
  action: ICarouselAction,
): TState {
  switch (action.type) {
    case ADJUST_CAROUSEL_BOTTOM_HEIGHT: {
      const { bottomHeight } = state;
      const { heightDelta = 0 } = action;

      const nextState = { ...state };
      nextState.bottomHeight = bottomHeight
        ? bottomHeight + heightDelta
        : bottomHeight;

      return nextState;
    }

    case ADJUST_CAROUSEL_TOP_HEIGHT: {
      const { topHeight } = state;
      const { heightDelta = 0 } = action;

      const nextState = { ...state };
      nextState.topHeight = topHeight ? topHeight + heightDelta : topHeight;

      return nextState;
    }

    case SET_CAROUSEL_BOTTOM_HEIGHT: {
      const nextState = { ...state };
      nextState.bottomHeight = action.bottomHeight;

      return nextState;
    }

    case SET_CAROUSEL_HISTORY: {
      const nextState = { ...state };
      nextState.history = action.history ? action.history : [];

      return nextState;
    }

    case SET_CAROUSEL_NATURAL_TOP_HEIGHT: {
      const nextState = { ...state };
      nextState.naturalTopHeight = action.naturalTopHeight;

      return nextState;
    }

    case SET_CAROUSEL_TOP_HEIGHT: {
      const nextState = { ...state };
      nextState.topHeight = action.topHeight;

      return nextState;
    }

    case SET_CURRENT_CAROUSEL_ITEM: {
      const { currentItemId, reduxReducer } = action;

      if (
        !currentItemId ||
        currentItemId === state.history[state.history.length - 1]
      ) {
        return state;
      }

      const nextState = { ...state };
      nextState.currentItemId = currentItemId;
      nextState.history =
        reduxReducer === CarouselReduxReducer.APPOINTMENT
          ? []
          : [...state.history, currentItemId];

      return nextState;
    }

    case SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS: {
      const { previousItemId, previousItemIndex } = action;

      if (!previousItemId || previousItemIndex === undefined) {
        return state;
      }

      const nextState = { ...state };
      nextState.currentItemId = previousItemId;
      nextState.history = state.history.slice(0, previousItemIndex + 1);

      return nextState;
    }

    case SET_SHOULD_RECALCULATE_HEIGHT: {
      if (typeof action.shouldRecalculateHeight === 'undefined') {
        return state;
      }

      const nextState = { ...state };
      nextState.shouldRecalculateHeight = action.shouldRecalculateHeight;

      return nextState;
    }

    default:
      return state;
  }
}
