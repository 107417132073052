import { useSelector } from 'react-redux';

import { ModalHeaderButton } from 'components/ModalHeaderButton';
import { ReactComponent as CloseSvg } from 'components/svg/ModalClose.svg';
import { ReactComponent as HomeSvg } from 'components/svg/ModalHome.svg';
import { IModalCloseButtonProps, IState, Mode } from 'typings';

import { svgStyles } from './styles';

export const ModalCloseButton = ({ onClick }: IModalCloseButtonProps) => {
  const { isStatic, mode } = useSelector((state: IState) => ({
    isStatic: state.client.isStatic,
    mode: state.client.mode,
  }));

  return (
    <ModalHeaderButton onClick={onClick} side="right">
      {isStatic || mode === Mode.APP ? (
        <HomeSvg aria-label="Close Dialog" css={svgStyles} />
      ) : (
        <CloseSvg aria-label="Close Dialog" css={svgStyles} />
      )}
    </ModalHeaderButton>
  );
};
