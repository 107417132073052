import { Tooltip } from '@homex/se-react-components';
import { useTheme } from 'styled-components/macro';

import { IThemedTooltipProps } from 'typings';

export const ThemedTooltip = ({
  children,
  isInverted,
  ...props
}: IThemedTooltipProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      themeValues={{
        backgroundColor: isInverted
          ? theme.contextBubbleInvertBackgroundColor
          : theme.topBackgroundColor,
        borderColor: isInverted
          ? theme.contextBubbleInvertBorderColor
          : theme.topForegroundPrimary,
        transitionDuration: theme.transitionDurationPrimary,
      }}
    >
      {children}
    </Tooltip>
  );
};
