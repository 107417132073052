import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityFanSvg } from 'components/svg/RoomElectricityFan.svg';
import { ReactComponent as RoomElectricityLightBulbSvg } from 'components/svg/RoomElectricityLightBulb.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricityPanelSvg } from 'components/svg/RoomElectricityPanel.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomHvacAirHandlerSvg } from 'components/svg/RoomHvacAirHandler.svg';
import { ReactComponent as RoomSceneryRoofSvg } from 'components/svg/RoomSceneryRoof.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  AirHandler,
  Container,
  Fan,
  Light,
  Outlet,
  Panel,
  Switch,
  roofStylesLeft,
  roofStylesRight,
} from './styles';

export const RoomAttic = ({ icons }: IRoomProps) => {
  const fanIcon = icons.find(({ key }) => key === RoomIconKey.EXHAUST_FAN);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SIMPLE);
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const panelIcon = icons.find(({ key }) => key === RoomIconKey.PANEL);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const airHandlerIcon = icons.find(
    ({ key }) => key === RoomIconKey.HVAC_UNIT_ATTIC,
  );

  return (
    <Container>
      <RoomSceneryRoofSvg css={roofStylesLeft} />
      <RoomSceneryRoofSvg css={roofStylesRight} />
      <Fan>
        <RoomButton icon={fanIcon} iconSvg={<RoomElectricityFanSvg />} />
      </Fan>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightBulbSvg />}
        />
      </Light>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Panel>
        <RoomButton icon={panelIcon} iconSvg={<RoomElectricityPanelSvg />} />
      </Panel>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <AirHandler>
        <RoomButton icon={airHandlerIcon} iconSvg={<RoomHvacAirHandlerSvg />} />
      </AirHandler>
    </Container>
  );
};
