import { CarouselItemId } from '@homex/se-widget-flow-types';
import styled, { css, keyframes } from 'styled-components/macro';

import { ReactComponent as CarouselNavPointerSvg } from 'components/svg/CarouselNavPointer.svg';
import { large } from 'styles/breakpoints';
import { IWithThemeProps } from 'typings';

interface IContainerProps {
  currentItemId: CarouselItemId;
  isIn: boolean;
  isVisible: boolean;
}

export type PointerAnimation =
  | 'default'
  | 'in-alone'
  | 'in-alone-modified'
  | 'in-with-steps';

interface IPointerProps {
  animationType: PointerAnimation;
}

const containerIn = keyframes`
  0% { top: 105px; }
  50% { visibility: visible; }
  100% { top: 60px; visibility: visible; }
`;

const containerOut = keyframes`
  0% { top: 60px; }
  100% { top: 100%; }
`;

export const Container = styled.div<IContainerProps>`
  animation-fill-mode: forwards;
  animation-name: ${containerOut};
  animation-timing-function: ease;
  height: 27px;
  left: 15px;
  opacity: 1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 15px;
  top: 60px;
  visibility: hidden;
  z-index: 1;

  ${({ theme }: IWithThemeProps) => `
    animation-duration: ${theme.transitionDurationPrimary};
    transition: opacity ${theme.transitionDurationCarouselTrack} ease;
  `}

  ${({ isIn, isVisible }) => {
    if (isIn) {
      return css`
        animation-delay: $transition-duration-carousel-track + 200ms;
        animation-name: ${containerIn};
        opacity: ${isVisible ? 1 : 0};
      `;
    }

    return '';
  }}

  ${large`
    left: 17px;
    right: 17px;
  `}
`;

export const StepList = styled.ul`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;

/**
 * from   0ms    0ms start at 0, the larger arrow
 * 24%  250ms  250ms translateY to move downward and out of view
 * 76%  800ms  550ms delay
 * to  1050ms  250ms translateY to move upward and into smaller arrow view
 *     1050ms 1050ms total
 */
const pointerInWithSteps = keyframes`
  from { transform: translateY(-22px); }
  24% { transform: translateY(0); }
  76% { transform: translateY(0); }
  to { transform: translateY(-11px); }
`;

interface IPointerSvgProps {
  navPointerX: number;
  shouldTransition: boolean;
}

const PointerSvgWithProps = ({
  navPointerX: _navPointerX,
  shouldTransition: _shouldTransition,
  ...props
}: IPointerSvgProps) => {
  return <CarouselNavPointerSvg {...props} />;
};

export const PointerSvg = styled(PointerSvgWithProps)<IPointerSvgProps>`
  height: 23px;
  width: 69px;

  ${({ navPointerX }) => `
    transform: translateX(${navPointerX}px);
  `}

  ${({ theme }) => `
    color: ${theme.bottomBackgroundColor};
  `}

  ${({ shouldTransition, theme }) => `
    transition: ${
      shouldTransition
        ? `transform ${theme.transitionDurationPrimary} ease`
        : 'none'
    };
  `}
`;

export const Pointer = styled.div<IPointerProps>`
  height: 23px;
  left: 0;
  padding: 0 17px;
  position: absolute;
  text-align: left;
  top: 0;
  transform: translateY(0);
  width: 100%;

  ${({ theme }: IWithThemeProps) => `
    transition: transform ${theme.transitionDurationPrimary} ease;
  `}

  ${({ animationType, theme }) => {
    switch (animationType) {
      case 'in-alone':
        return `
          transform: translateY(-22px);
        `;
      case 'in-alone-modified':
        return `
          transform: translateY(-22px);
          padding: 0 57px;
        `;
      case 'in-with-steps':
        return css`
          animation-duration: 1050ms;
          animation-fill-mode: reverse;
          animation-name: ${pointerInWithSteps};
          animation-timing-function: ease;
          transform: translateY(-11px);

          ${PointerSvg} {
            transition-duration: ${theme.transitionDurationCarouselTrack};
          }
        `;
      default:
        return '';
    }
  }}
`;
