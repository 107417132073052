import {
  CarouselItemId,
  FlowCarouselItemId,
} from '@homex/se-widget-flow-types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  SET_CURRENT_CAROUSEL_ITEM,
  reportCarouselItemCompleted,
  reportCarouselItemViewed,
} from 'actions';
import { CarouselReduxReducer, IState } from 'typings';
import { isIosVersion13 } from 'utils/isIosVersion13';

export function setCurrentCarouselItem(
  reduxReducer: CarouselReduxReducer,
  flowCurrentItemId: FlowCarouselItemId,
) {
  // TODO(CUSTOM): This cast is safe and was done to keep the places calling
  // this action clean. However, we really should update the full codebase to
  // work with the new `FlowCarouselItemId`.
  const currentItemId = flowCurrentItemId as CarouselItemId;

  // iOS 13+ devices contain bugs that mess up the UI when
  // transitioning between widget views. Scrolling to the
  // top prior to transitioning fixes it.
  // Details: https://www.pivotaltracker.com/story/show/169325021
  if (isIosVersion13()) {
    window.scrollTo(0, 0);
  }

  return async (dispatch: ThunkDispatch<IState, null, Action>) => {
    await dispatch(reportCarouselItemCompleted());
    await dispatch({
      currentItemId,
      reduxReducer,
      type: SET_CURRENT_CAROUSEL_ITEM,
    });
    await dispatch(reportCarouselItemViewed());
  };
}
