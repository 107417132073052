import { ICurrentCustomerAppointment, ICustomer } from 'typings';

export function getAppointmentsFromCustomer(
  customer?: ICustomer,
): Array<ICurrentCustomerAppointment> {
  let appointments: Array<ICurrentCustomerAppointment> = [];

  if (customer && customer.locations) {
    appointments = customer.locations.reduce(
      (list: Array<ICurrentCustomerAppointment>, location) => {
        if (location && location.appointments) {
          list = list.concat(location.appointments);
        }

        return list;
      },
      [],
    );
  }

  return appointments;
}

export function sortAppointmentsByDate(
  appointments: Array<ICurrentCustomerAppointment>,
): Array<ICurrentCustomerAppointment> {
  return appointments.sort(
    (a, b) =>
      new Date(a.serviceDate).getTime() - new Date(b.serviceDate).getTime(),
  );
}
