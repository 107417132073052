import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  SHOW_SERVICE_MODAL,
  reportCarouselItemViewed,
  reportWidgetOpened,
  startSession,
} from 'actions';
import { IState } from 'typings';

export function showServiceModal(skipTokenCreation?: boolean) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    _getState: () => IState,
  ) => {
    await Promise.all([
      dispatch({ type: SHOW_SERVICE_MODAL }),
      dispatch(
        startSession({
          shouldVerifyExistingSession: true,
          skipTokenCreation: !!skipTokenCreation,
        }),
      ),
    ]);
    await Promise.all([
      dispatch(reportWidgetOpened()),
      dispatch(reportCarouselItemViewed()),
    ]);
  };
}
