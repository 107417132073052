import { useRef } from 'react';

import { IAccordionProps } from 'typings';

import { Container, Contents, Title, TitleText } from './styles';

// We keep track of the number of components created as a simple means of
// creating unique ids
let accordionCount = 0;

export const Accordion = ({
  body,
  header,
  isCollapsed,
  onClick,
}: IAccordionProps) => {
  const idRef = useRef(++accordionCount);
  const bodyId = `AccordionBody-${idRef.current}`;
  const titleId = `AccordionHeader-${idRef.current}`;

  return (
    <Container>
      <Title
        aria-controls={bodyId}
        id={titleId}
        onClick={onClick}
        role="tab"
        tabIndex={0}
      >
        <TitleText isCollapsed={isCollapsed}>{header}</TitleText>
      </Title>
      <Contents
        aria-hidden={isCollapsed}
        aria-labelledby={titleId}
        id={bodyId}
        isCollapsed={isCollapsed}
        role="tabpanel"
      >
        {body}
      </Contents>
    </Container>
  );
};
