import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityFanSvg } from 'components/svg/RoomElectricityFan.svg';
import { ReactComponent as RoomElectricityLightPendantSvg } from 'components/svg/RoomElectricityLightPendant.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomSceneryCabinetSvg } from 'components/svg/RoomSceneryCabinet.svg';
import { ReactComponent as RoomSceneryOvenSvg } from 'components/svg/RoomSceneryOven.svg';
import { ReactComponent as RoomSinkKitchenSvg } from 'components/svg/RoomSinkKitchen.svg';
import { ReactComponent as RoomSinkKitchenDisposalSvg } from 'components/svg/RoomSinkKitchenDisposal.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Disposal,
  Fan,
  Light,
  Outlet,
  Sink,
  Switch,
  Vent,
  cabinetLeftStyles,
  cabinetRightStyles,
  ovenStyles,
} from './styles';

export const RoomKitchen = ({ icons }: IRoomProps) => {
  const fanIcon = icons.find(({ key }) => key === RoomIconKey.EXHAUST_FAN);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_PENDANT);
  const sinkIcon = icons.find(({ key }) => key === RoomIconKey.SINK_KITCHEN);
  const disposalIcon = icons.find(
    ({ key }) => key === RoomIconKey.SINK_KITCHEN_DISPOSAL,
  );
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <RoomSceneryCabinetSvg css={cabinetLeftStyles} />
      <RoomSceneryCabinetSvg css={cabinetRightStyles} />
      <RoomSceneryOvenSvg css={ovenStyles} />
      <Fan>
        <RoomButton icon={fanIcon} iconSvg={<RoomElectricityFanSvg />} />
      </Fan>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightPendantSvg />}
        />
      </Light>
      <Sink>
        <RoomButton icon={sinkIcon} iconSvg={<RoomSinkKitchenSvg />} />
      </Sink>
      <Disposal>
        <RoomButton
          icon={disposalIcon}
          iconSvg={<RoomSinkKitchenDisposalSvg />}
        />
      </Disposal>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
