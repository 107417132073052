import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  fetchWithAuth,
  registerPmTrackingFailure,
  registerPmTrackingRequest,
  registerPmTrackingSuccess,
  setCampaignNotificationId,
} from 'actions';
import {
  GoogleAnalyticsAction,
  IState,
  SeAnalyticsAction,
  SeAnalyticsCategory,
  SeEmbedEvent,
} from 'typings';
import analytics from 'utils/analytics';
import { buildGoogleAnalyticsEvent } from 'utils/buildGoogleAnalyticsEvent';
import { buildSeAnalyticsEvent } from 'utils/buildSeAnalyticsEvent';
import postMessageToParent from 'utils/postMessageToParent';
import { buildPostOptions } from 'utils/request';

const WIDGET_OPENED_EVENT_KEY = 'widget-opened';

export function reportWidgetOpened() {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const state = getState();
    const {
      auth: { deviceToken },
      client: {
        analyticsTrackingEnabled,
        flow,
        googleAnalyticsTrackingEnabled,
        validOrigins,
      },
      modal: { isServiceModalVisible },
      pmTracking: { campaignMemberId, campaignNotificationId },
    } = state;

    if (!isServiceModalVisible) {
      return;
    }

    if (analyticsTrackingEnabled) {
      analytics.send(
        'event',
        buildSeAnalyticsEvent(
          SeAnalyticsCategory.CAROUSEL,
          SeAnalyticsAction.CHANGE,
          state,
          { widgetOpened: true },
        ),
      );
    }

    if (googleAnalyticsTrackingEnabled) {
      postMessageToParent(
        {
          ga: buildGoogleAnalyticsEvent(
            GoogleAnalyticsAction.BOOKING_STARTED,
            state,
          ),
          type: 'se-send-client-google-analytics',
        },
        validOrigins,
      );
    }

    postMessageToParent(
      {
        analyticsKey: flow?.key,
        eventName: SeEmbedEvent.BOOKING_STARTED,
        type: 'se-event',
      },
      validOrigins,
    );

    // tracking opening of widget for PM if campaign notification id exists
    if (campaignMemberId && campaignNotificationId) {
      dispatch(registerPmTrackingRequest(WIDGET_OPENED_EVENT_KEY));

      try {
        const body = {
          data: {
            campaignMemberId,
            campaignNotificationId,
          },
          event: 'Widget Page Load',
        };

        const options = buildPostOptions({ body, token: deviceToken });
        const response = await dispatch(
          fetchWithAuth(`${process.env.REACT_APP_TRACKING_URI}`, options),
        );

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        dispatch(registerPmTrackingSuccess(WIDGET_OPENED_EVENT_KEY));
        dispatch(setCampaignNotificationId(''));
      } catch (error) {
        dispatch(registerPmTrackingFailure(error));
      }
    }
  };
}
