import { ContextBubbleAnimation } from '@homex/se-react-components';

import {
  hideServiceModal,
  resetServiceCarouselForCsr,
  setCurrentCarouselItem,
  setCurrentCarouselItemToPrevious,
} from 'actions';
import {
  ContextBubbleStyled,
  Label,
  Svg,
  SvgContainer,
} from 'components/CarouselBottomButtonArrowSvg';
import { Phrase } from 'components/Phrase';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselBottomButtonType,
  CarouselReduxReducer,
  ICarouselBiDirectionButtonProps,
} from 'typings';

import {
  BiDirecitonButtonContainer,
  BiDirectionButton,
  BiDirectionLabelContainer,
} from './styles';

export const CarouselBiDirectionButton = ({
  backButtonLabel,
  buttonType = CarouselBottomButtonType.CONTINUE,
  closeLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.CLOSE" />,
  continueButtonLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.LABEL" />,
  destinationItemId,
  errorMessage,
  homeLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.HOME" />,
  isDisabled,
  isLoading,
  onBackClick,
  onContinueClick,
  retryLabel = <Phrase id="CAROUSEL_CONTINUE_BUTTON.RETRY" />,
}: ICarouselBiDirectionButtonProps) => {
  const dispatch = useThunkDispatch();

  const handleContinueClick = () => {
    if (buttonType === CarouselBottomButtonType.CLOSE) {
      dispatch(hideServiceModal());
      dispatch(resetServiceCarouselForCsr());
    } else if (buttonType === CarouselBottomButtonType.HOME) {
      dispatch(resetServiceCarouselForCsr());
    } else if (destinationItemId) {
      dispatch(
        setCurrentCarouselItem(CarouselReduxReducer.SERVICE, destinationItemId),
      );
    } else if (onContinueClick) {
      onContinueClick();
    }
  };

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      dispatch(setCurrentCarouselItemToPrevious(CarouselReduxReducer.SERVICE));
    }
  };

  return (
    <BiDirecitonButtonContainer>
      <BiDirectionButton
        buttonType={CarouselBottomButtonType.BACK}
        disabled={false}
        isLoading={false}
        onClick={handleBackClick}
        type="button"
      >
        <SvgContainer
          buttonType={CarouselBottomButtonType.BACK}
          height={36}
          isLoading={false}
        >
          <Svg
            buttonType={CarouselBottomButtonType.BACK}
            height={36}
            isLoading={false}
            width={36}
          />
        </SvgContainer>
        <BiDirectionLabelContainer isBack isLoading={false}>
          <Label isVisible>{backButtonLabel ?? 'Go Back'}</Label>
        </BiDirectionLabelContainer>
      </BiDirectionButton>
      <BiDirectionButton
        buttonType={CarouselBottomButtonType.CONTINUE}
        disabled={isDisabled || !!isLoading}
        isLoading={!!isLoading}
        onClick={handleContinueClick}
        type="submit"
      >
        {errorMessage && (
          <ContextBubbleStyled
            animation={ContextBubbleAnimation.FADE_IN_OUT}
            pointer={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            {errorMessage}
          </ContextBubbleStyled>
        )}
        <BiDirectionLabelContainer isLoading={!!isLoading}>
          <Label isVisible={buttonType === CarouselBottomButtonType.RETRY}>
            {retryLabel}
          </Label>
          <Label isVisible={buttonType === CarouselBottomButtonType.HOME}>
            {homeLabel}
          </Label>
          <Label isVisible={buttonType === CarouselBottomButtonType.CLOSE}>
            {closeLabel}
          </Label>
          <Label
            isVisible={
              buttonType === CarouselBottomButtonType.CONTINUE ||
              buttonType === CarouselBottomButtonType.CONTINUE_DOWN
            }
          >
            {continueButtonLabel}
          </Label>
        </BiDirectionLabelContainer>
        <SvgContainer
          buttonType={CarouselBottomButtonType.CONTINUE}
          height={36}
          isLoading={false}
        >
          <Svg
            buttonType={CarouselBottomButtonType.CONTINUE}
            height={36}
            isLoading={!!isLoading}
            width={36}
          />
        </SvgContainer>
      </BiDirectionButton>
    </BiDirecitonButtonContainer>
  );
};
