import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from 'actions';
import {
  ILoginFailureResponse,
  ILoginSuccessfulResponse,
  IState,
  LoginResponse,
} from 'typings';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

function loginFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_LOGIN_URI,
  });

  return { error, type: LOGIN_FAILURE };
}

function loginRequest() {
  return { type: LOGIN_REQUEST };
}

function loginSuccess(phone: LoginResponse) {
  return { phone, type: LOGIN_SUCCESS };
}

export function login(phone = '', phoneId = '') {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ): Promise<LoginResponse> => {
    const {
      auth: { sessionToken },
      client,
    } = getState();

    dispatch(loginRequest());

    try {
      // If we have a phoneId, dont pass phone
      phone = phoneId ? '' : phone;

      const body = {
        client_key: client.key,
        phone_id: phoneId,
        phone_number: phone,
      };
      const options = buildPostOptions({ body, token: sessionToken });
      const response = await fetch(
        `${process.env.REACT_APP_LOGIN_URI}`,
        options,
      );

      const phoneResponse = await response.json();

      if ([400, 404].includes(response.status)) {
        return {};
      } else if (response.status === 409) {
        return phoneResponse.data as ILoginFailureResponse;
      } else if (!response.ok) {
        throw new Error(response.statusText);
      }

      dispatch(loginSuccess(phoneResponse));

      return phoneResponse as ILoginSuccessfulResponse;
    } catch (error) {
      dispatch(loginFailure(error));

      throw error;
    }
  };
}
