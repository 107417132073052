import { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';

export const container = css`
  margin: 0 auto;
  width: 100%;

  ${medium`
    max-width: 386px;
  `}

  ${large`
    max-width: none;
    padding: 0;
    width: 444px;
  `}
`;

export const containerSmall = css`
  ${container}

  ${large`
    width: 404px;
  `}
`;

export const containerTall = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 460px;
`;

export const containerWide = css`
  ${container}

  ${large`
    width: 650px;
  `}
`;

export const removeCarouselPadding = css`
  margin: 0 -9px;

  ${large`
    margin: 0 -17px;
  `}
`;

export const room = css`
  ${container}

  background: #eaebec;
  border: 1px solid #4a4a4a;
  border-bottom: 0;
  height: 307px;
  margin: 2px 0 0;
  overflow: hidden;
  position: relative;

  ${large`
    height: 335px;
    margin: 4px 0 0;
  `}

  /* Floor */
  &:after {
    background: #fff;
    border: 1px solid #4a4a4a;
    border-width: 1px 0;
    bottom: 90px;
    content: '';
    height: 9px;
    left: 0;
    position: absolute;
    right: 0;
  }
`;
