import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

const cabinetStyles = css`
  bottom: 100px;
  height: 95px;
  position: absolute;
  width: 53px;

  ${medium`
    height: 101px;
    width: 57px;
  `}
`;

export const cabinetLeftStyles = css`
  ${cabinetStyles}

  left: 16%;

  ${medium`
    left: 61px;
  `}

  ${large`
    left: 108px;
  `}
`;

export const cabinetRightStyles = css`
  ${cabinetStyles}

  left: 63.5%;

  ${medium`
    left: 222px;
  `}

  ${large`
    left: 269px;
  `}
`;

export const ovenStyles = css`
  bottom: 100px;
  height: 113px;
  left: -11.5%;
  position: absolute;
  width: 89px;

  ${medium`
    height: 121px;
    left: -33px;
    width: 95px;
  `}

  ${large`
    left: 14px;
  `}
`;

export const Light = styled.div`
  height: 70px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: -1px;
  width: 59px;

  ${medium`
    height: 75px;
    margin-left: -32px;
    top: -12px;
    width: 63px;
  `}

  ${large`
    top: 0;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 62.5%;
  position: absolute;
  top: 74px;
  width: 14px;

  ${medium`
    height: 25px;
    left: 219px;
    top: 65px;
    width: 15px;
  `}

  ${large`
    left: 268px;
    top: 92px;
  `}
`;

export const Outlet = styled.div`
  height: 23px;
  left: 30.7%;
  position: absolute;
  top: 74px;
  width: 14px;

  ${medium`
    top: 65px;
    height: 25px;
    left: 111px;
    width: 15px;
  `}

  ${large`
    left: 160px;
    top: 92px;
  `}
`;

export const Fan = styled.div`
  height: 37px;
  left: 2.5%;
  position: absolute;
  top: 16px;
  width: 37px;

  ${medium`
    height: 39px;
    left: 15px;
    top: 17px;
    width: 39px;
  `}

  ${large`
    left: 41px;
    top: 37px;
  `}
`;

export const Sink = styled.div`
  bottom: 100px;
  height: 131px;
  left: 31.7%;
  position: absolute;
  width: 113px;

  ${medium`
    height: 140px;
    left: 114px;
    width: 121px;
  `}

  ${large`
    left: 161px;
  `}
`;

export const Disposal = styled.div`
  bottom: 149px;
  height: 31px;
  left: 44%;
  position: absolute;
  width: 24px;

  ${medium`
    bottom: 153px;
    height: 33px;
    left: 158px;
    width: 26px;
  `}

  ${large`
    left: 205px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 22px;
  position: absolute;
  right: 2.1%;
  width: 51px;

  ${medium`
    height: 23px;
    right: 11px;
    width: 54px;
  `}

  ${large`
    right: 40px;
  `}
`;
