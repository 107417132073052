import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityLightPendantSvg } from 'components/svg/RoomElectricityLightPendant.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomSceneryDoorSvg } from 'components/svg/RoomSceneryDoor.svg';
import { ReactComponent as RoomSceneryEndTableSvg } from 'components/svg/RoomSceneryEndTable.svg';
import { ReactComponent as RoomSceneryWindowSvg } from 'components/svg/RoomSceneryWindow.svg';
import { ReactComponent as RoomThermostatSvg } from 'components/svg/RoomThermostat.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Light,
  Outlet,
  Switch,
  Thermostat,
  Vent,
  doorStyles,
  endTableStyles,
  windowStyles,
} from './styles';

export const RoomHallway = ({ icons }: IRoomProps) => {
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_PENDANT);
  const thermostatIcon = icons.find(
    ({ key }) => key === RoomIconKey.THERMOSTAT,
  );
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <RoomSceneryDoorSvg css={doorStyles} />
      <RoomSceneryEndTableSvg css={endTableStyles} />
      <RoomSceneryWindowSvg css={windowStyles} />
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightPendantSvg />}
        />
      </Light>
      <Thermostat>
        <RoomButton icon={thermostatIcon} iconSvg={<RoomThermostatSvg />} />
      </Thermostat>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
