type RequestMode = 'no-cors' | 'navigate' | 'same-origin' | 'cors' | undefined;

interface IBuildGetOptionsConfig {
  token?: string;
}

interface IBuildDeleteOptionsConfig {
  token?: string;
}

interface IGetRequestHeaders {
  [name: string]: any;
}

interface IPostRequestHeaders {
  [name: string]: any;
  Accept: string;
  Authorization?: string;
  'Content-Type': string;
}

interface IBuildPostOptionsConfig {
  body?: RequestInit['body'] | Record<string, unknown>;
  token?: string;
}

const postHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const buildGetOptions = ({ token }: IBuildGetOptionsConfig) => {
  const authorization = token ? { Authorization: `Bearer ${token}` } : {};
  const headers: IGetRequestHeaders = { ...authorization };

  return {
    headers,
    method: 'get',
    mode: 'cors' as RequestMode,
  };
};

export const buildPostOptions = ({ body, token }: IBuildPostOptionsConfig) => {
  const authorization = token ? { Authorization: `Bearer ${token}` } : {};
  const headers: IPostRequestHeaders = {
    ...postHeaders,
    ...authorization,
  };

  return {
    body: body && JSON.stringify(body),
    headers,
    method: 'post',
    mode: 'cors' as RequestMode,
  };
};

export const buildGetMediaOptions = ({ token }: IBuildGetOptionsConfig) => {
  const authorization = token ? { Authorization: `Bearer ${token}` } : {};

  return {
    headers: {
      Accept: 'application/octet-stream',
      'Access-Control-Allow-Headers':
        'Content-Type,x-requested-with,Authorization',
      'Content-Type': 'application/octet-stream',
      ...authorization,
    },
    method: 'get',
    mode: 'cors',
  } as unknown as RequestInit;
};

export const buildDeleteOptions = ({ token }: IBuildDeleteOptionsConfig) => {
  const authorization = token ? { Authorization: `Bearer ${token}` } : {};

  return {
    headers: {
      'Access-Control-Allow-Headers':
        'Content-Type,x-requested-with,Authorization',
      ...authorization,
    },
    method: 'delete',
    mode: 'cors',
  } as unknown as RequestInit;
};
