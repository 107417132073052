import React, { useContext } from 'react';

interface IStringMap {
  [key: string]: string;
}

interface IStringProviderProps {
  children?: React.ReactNode;
  strings: IStringMap;
}

export const StringContext = React.createContext<IStringMap>({});

export const StringProvider = ({ children, strings }: IStringProviderProps) => {
  return (
    <StringContext.Provider value={strings}>{children}</StringContext.Provider>
  );
};

export const useStringContext = () => useContext(StringContext);
