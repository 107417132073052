import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  GoogleAnalyticsAction,
  IState,
  SeAnalyticsAction,
  SeAnalyticsCategory,
  SeEmbedEvent,
  ServiceType,
} from 'typings';
import analytics from 'utils/analytics';
import { buildGoogleAnalyticsEvent } from 'utils/buildGoogleAnalyticsEvent';
import { buildSeAnalyticsEvent } from 'utils/buildSeAnalyticsEvent';
import postMessageToParent from 'utils/postMessageToParent';

export function reportBookingComplete() {
  return async (
    _dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const state = getState();
    const {
      client: {
        analyticsTrackingEnabled,
        flow,
        googleAnalyticsTrackingEnabled,
        validOrigins,
      },
      modal: { isServiceModalVisible },
      userInput: { serviceType },
    } = state;

    if (serviceType !== ServiceType.REPAIR || !isServiceModalVisible) {
      return;
    }

    if (analyticsTrackingEnabled) {
      analytics.send(
        'event',
        buildSeAnalyticsEvent(
          SeAnalyticsCategory.BOOKING,
          SeAnalyticsAction.CREATE,
          state,
        ),
      );
    }

    if (googleAnalyticsTrackingEnabled) {
      postMessageToParent(
        {
          ga: buildGoogleAnalyticsEvent(
            GoogleAnalyticsAction.BOOKING_BOOKED,
            state,
          ),
          type: 'se-send-client-google-analytics',
        },
        validOrigins,
      );
    }

    postMessageToParent(
      {
        analyticsKey: flow?.key,
        eventName: SeEmbedEvent.BOOKING_BOOKED,
        type: 'se-event',
      },
      validOrigins,
    );
  };
}
