import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityLightChandelierSvg } from 'components/svg/RoomElectricityLightChandelier.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomSceneryDiningSetSvg } from 'components/svg/RoomSceneryDiningSet.svg';
import { ReactComponent as RoomThermostatSvg } from 'components/svg/RoomThermostat.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Light,
  Outlet,
  Switch,
  Thermostat,
  Vent,
  diningSetStyles,
} from './styles';

export const RoomDining = ({ icons }: IRoomProps) => {
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const lightIcon = icons.find(
    ({ key }) => key === RoomIconKey.LIGHT_CHANDELIER,
  );
  const thermostatIcon = icons.find(
    ({ key }) => key === RoomIconKey.THERMOSTAT,
  );
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <RoomSceneryDiningSetSvg css={diningSetStyles} />
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightChandelierSvg />}
        />
      </Light>
      <Thermostat>
        <RoomButton icon={thermostatIcon} iconSvg={<RoomThermostatSvg />} />
      </Thermostat>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
