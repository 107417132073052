import styled from 'styled-components/macro';

import { large } from 'styles/breakpoints';
import { container } from 'styles/layout';
import { heading, headingLower, paragraph } from 'styles/typography';
import { IWithThemeProps } from 'typings';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }: IWithThemeProps) => `
    background-color: ${theme.bottomBackgroundColor};
  `}
`;

export const Header = styled.header`
  overflow: hidden;
  padding: 23px 0;
  position: relative;

  ${({ theme }: IWithThemeProps) => `
    background: ${theme.topBackgroundColor};
  `}

  &:before {
    content: '';
    height: 440px;
    position: absolute;
    right: 0;
    top: -120px;
    width: 300px;

    ${({ theme }: IWithThemeProps) => `
      background-image: ${theme.topBackgroundGradient};
    `}
  }
`;

export const HeaderText = styled.h1`
  ${heading}

  position: relative;

  & {
    margin: 0;
  }

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.topForegroundPrimary};
  `}
`;

export const Container = styled.div`
  ${container}

  flex: 1 0 auto;
  padding: 67px 9px 0;

  ${large`
    padding: 78px 17px 0;
  `}
`;

export const Subhead = styled.h2`
  ${headingLower}

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}
`;

export const Description = styled.p`
  ${paragraph}

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}

  a {
    font-style: italic;
    font-weight: bold;
    text-decoration: none;

    ${({ theme }: IWithThemeProps) => `
      color: ${theme.bottomForegroundPrimary};
    `}
  }
`;
