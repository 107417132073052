import {
  CarouselItemId,
  CarouselNavStepName,
} from '@homex/se-widget-flow-types';
import React, { useMemo } from 'react';
import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/macro';

import { setCurrentCarouselItemToPrevious } from 'actions';
import { CarouselNavItem } from 'components/CarouselNavItem';
import { Phrase } from 'components/Phrase';
import { usePrevious } from 'hooks/usePrevious';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselReduxReducer,
  ICarouselNavProps,
  IState,
  ServiceType,
} from 'typings';
import getCarouselStepName from 'utils/getCarouselStepName';

import {
  Container,
  Pointer,
  PointerAnimation,
  PointerSvg,
  StepList,
} from './styles';

const POINTER_IMAGE_WIDTH = 69;

type StepLiRefMap = {
  [TStepName in CarouselNavStepName]?: React.MutableRefObject<HTMLLIElement | null>;
};

interface INavStep {
  destinationItemId?: CarouselItemId;
  name: CarouselNavStepName;
}

type CarouselNavFlow = { [TServiceType in ServiceType]: Array<INavStep> };

export const CarouselNav = ({ carouselBottomRef }: ICarouselNavProps) => {
  const dispatch = useThunkDispatch();

  const {
    currentItemId,
    flow,
    navPointerX,
    serviceType,
    serviceTypeRadioValue,
    validServiceTypes,
    viewportSize,
  } = useSelector((state: IState) => ({
    currentItemId: state.serviceCarousel.currentItemId,
    flow: state.client.flow,
    navPointerX: state.serviceCarousel.navPointerX,
    serviceType: state.userInput.serviceType || ServiceType.REPAIR,
    serviceTypeRadioValue: state.userInput.serviceTypeRadioValue,
    validServiceTypes: state.client.validServiceTypes,
    viewportSize: state.device.viewportSize,
  }));

  const prevServiceTypeRadioValue = usePrevious(serviceTypeRadioValue);
  const {
    mediaUploadEnabled,
    repairCommentsEnabled,
    schedulePreferenceEnabled,
  } = useTheme();

  const currentStep = useMemo((): CarouselNavStepName => {
    const stepName = getCarouselStepName(
      currentItemId,
      serviceTypeRadioValue,
      flow,
    );

    if (stepName && stepName !== CarouselNavStepName.Opened) {
      return stepName;
    }

    return CarouselNavStepName.Issue;
  }, [currentItemId, serviceTypeRadioValue, flow]);

  const originalFlows: CarouselNavFlow = useMemo(() => {
    return {
      [ServiceType.SALES]: [
        {
          destinationItemId: CarouselItemId.ContactInfo,
          name: CarouselNavStepName.Contact,
        },
        {
          destinationItemId: CarouselItemId.SalesAddress,
          name: CarouselNavStepName.Address,
        },
        {
          destinationItemId: CarouselItemId.ScheduleEmergency,
          name: CarouselNavStepName.Schedule,
        },
        {
          destinationItemId: CarouselItemId.SystemEquipment,
          name: CarouselNavStepName.System,
        },
        {
          destinationItemId: CarouselItemId.SalesSummary,
          name: CarouselNavStepName.Confirm,
        },
      ],
      [ServiceType.REPAIR]: [
        {
          destinationItemId: CarouselItemId.IssueSpecifier,
          name: CarouselNavStepName.Issue,
        },
        {
          destinationItemId: mediaUploadEnabled
            ? CarouselItemId.MediaUpload
            : repairCommentsEnabled
            ? CarouselItemId.RepairComments
            : CarouselItemId.CustomerLookup,
          name: CarouselNavStepName.Details,
        },
        {
          destinationItemId: CarouselItemId.CustomerLookup,
          name: CarouselNavStepName.Customer,
        },
        {
          destinationItemId: schedulePreferenceEnabled
            ? CarouselItemId.SchedulePreference
            : CarouselItemId.ScheduleCalendarRepair,
          name: CarouselNavStepName.Schedule,
        },
        {
          destinationItemId: CarouselItemId.RepairSummary,
          name: CarouselNavStepName.Confirm,
        },
      ],
      [ServiceType.MAINTENANCE]: [],
    };
  }, [schedulePreferenceEnabled, mediaUploadEnabled, repairCommentsEnabled]);

  const flowSteps = useMemo(() => {
    return flow?.navSteps.map((ns) => ({
      destinationItemId:
        'onClickCarouselItemId' in ns
          ? (ns.onClickCarouselItemId as CarouselItemId)
          : undefined,
      name: ns.name,
    }));
  }, [flow]);

  const steps: Array<INavStep> = !flowSteps
    ? originalFlows[serviceType]
    : flowSteps;
  const currentStepIndex = steps.map((step) => step.name).indexOf(currentStep);
  const carouselBottomElement = carouselBottomRef
    ? carouselBottomRef.current
    : null;

  const stepLiRefs = useMemo(() => {
    return steps.reduce((acc: StepLiRefMap, step) => {
      acc[step.name] = { current: null };

      return acc;
    }, {});
  }, [steps]);

  const isIn = currentItemId !== CarouselItemId.ServiceType;
  const isVisible = ![
    CarouselItemId.ScheduleMaintenanceNoTimeSlots,
    CarouselItemId.ConfirmFinishRepair,
    CarouselItemId.ConfirmFinishSales,
    CarouselItemId.ScheduleMaintenanceExpired,
  ].includes(currentItemId);

  const handleNavItemClick = (step: INavStep) => {
    dispatch(
      setCurrentCarouselItemToPrevious(
        CarouselReduxReducer.SERVICE,
        step.destinationItemId,
      ),
    );
  };

  const renderPointerSvg = () => {
    let currentNavPointerX = navPointerX;

    if (currentItemId !== CarouselItemId.ServiceType) {
      const isCentered =
        currentItemId === CarouselItemId.RepairSummary ||
        currentItemId === CarouselItemId.SalesSummary ||
        currentItemId === CarouselItemId.SchedulePreferenceSales ||
        currentItemId === CarouselItemId.ScheduleCalendarRepair ||
        currentItemId === CarouselItemId.ScheduleMaintenanceExpired ||
        currentItemId === CarouselItemId.ScheduleMaintenanceNoTimeSlots;
      let targetStep = currentStep;

      if (isCentered) {
        switch (serviceType) {
          case ServiceType.SALES:
            targetStep = CarouselNavStepName.Schedule;

            break;
          case ServiceType.REPAIR:
            targetStep = CarouselNavStepName.Customer;

            if (flow) {
              const mid =
                flow.navSteps.length % 2 === 0
                  ? flow.navSteps.length / 2
                  : Math.floor(flow.navSteps.length / 2);
              targetStep =
                flow.navSteps[mid]?.name ?? CarouselNavStepName.Customer;

              break;
            }

            break;
          case ServiceType.MAINTENANCE:
            // TODO: implement when we have maintenance flow
            break;
          default:
            // Do nothing
            break;
        }
      }

      const currentStepLi = stepLiRefs[targetStep]?.current;

      if (currentStepLi) {
        const { offsetLeft, offsetWidth } = currentStepLi;

        //Works since CarouselNavItems are the same width
        const adjustedOffsetWidth =
          isCentered && flow && flow?.navSteps.length % 2 === 0
            ? 0
            : offsetWidth / 2;

        currentNavPointerX =
          offsetLeft + adjustedOffsetWidth - POINTER_IMAGE_WIDTH / 2;
      }
    }

    return (
      <PointerSvg
        navPointerX={currentNavPointerX}
        shouldTransition={!!prevServiceTypeRadioValue}
      />
    );
  };

  const renderPointer = () => {
    if (!carouselBottomElement) {
      return null;
    }

    let animationType: PointerAnimation = 'default';

    switch (currentItemId) {
      case CarouselItemId.ServiceType: {
        if (navPointerX && viewportSize === 'large') {
          animationType =
            validServiceTypes.length === 2 ? 'in-alone-modified' : 'in-alone';
        }

        break;
      }

      case CarouselItemId.RepairSummary:
        animationType = viewportSize === 'small' ? 'default' : 'in-alone';

        break;
      case CarouselItemId.SalesSummary:
      case CarouselItemId.ScheduleCalendarRepair:
      case CarouselItemId.ScheduleMaintenanceExpired:
      case CarouselItemId.ScheduleMaintenanceNoTimeSlots:
        animationType = 'in-alone';

        break;
      case CarouselItemId.AvailablePartners:
      case CarouselItemId.ContactInfo:
      case CarouselItemId.ScheduleEmergency:
      case CarouselItemId.ServiceAreaSales:
      case CarouselItemId.SalesAddress:
      case CarouselItemId.SalesAddressCorrection:
      case CarouselItemId.SalesAddressNotFound:
      case CarouselItemId.SystemComments:
      case CarouselItemId.SystemEquipment:
      case CarouselItemId.SystemEnergy:
      case CarouselItemId.IssueSpecifier:
      case CarouselItemId.RepairComments:
      case CarouselItemId.MediaUpload:
      case CarouselItemId.MediaDetail:
      case CarouselItemId.CustomerLookup:
      case CarouselItemId.CustomerLocations:
      case CarouselItemId.CustomerLocationsRecovered:
      case CarouselItemId.ServiceAreaRepair:
      case CarouselItemId.RepairAddress:
      case CarouselItemId.RepairAddressCorrection:
      case CarouselItemId.RepairAddressNotFound:
      case CarouselItemId.RepairContactInfo:
      case CarouselItemId.SchedulePreference:
      case CarouselItemId.SchedulePreferenceSales:
        animationType = 'in-with-steps';

        break;
      case CarouselItemId.ConfirmFinishRepair:
      case CarouselItemId.ConfirmFinishSales:
      default:
        if (
          currentItemId.startsWith(CarouselItemId.IssueDetails) ||
          currentItemId.startsWith(CarouselItemId.CustomForm)
        ) {
          animationType = 'in-with-steps';
        }

        break;
    }

    return ReactDom.createPortal(
      <Pointer animationType={animationType}>{renderPointerSvg()}</Pointer>,
      carouselBottomElement,
    );
  };

  return (
    <Container currentItemId={currentItemId} isIn={isIn} isVisible={isVisible}>
      <StepList>
        {steps.map((step, index) => (
          <CarouselNavItem
            currentStepIndex={currentStepIndex}
            index={index}
            key={`CarouselNavItem-${step.name}`}
            onClick={handleNavItemClick.bind(this, step)}
            ref={stepLiRefs[step.name]}
            step={Phrase({ id: `CAROUSEL_NAV.${step.name}` })}
          />
        ))}
      </StepList>
      {renderPointer()}
    </Container>
  );
};
