import useResizeObserver from '@react-hook/resize-observer';
import React, { forwardRef, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import {
  setCarouselBottomHeight,
  setCarouselNaturalTopHeight,
  setCarouselTopHeight,
  setCurrentCarouselItemToDefault,
  setShouldRecalculateHeight,
} from 'actions';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselPosition,
  ICarouselItemProps,
  ICarouselProps,
  IState,
} from 'typings';

import { Container, Track } from './styles';

export const Carousel = forwardRef<HTMLDivElement, ICarouselProps>(
  function Carousel(
    {
      backgroundStyle,
      carouselItemRefs,
      children,
      position,
      reduxReducer,
    }: ICarouselProps,
    ref,
  ) {
    const dispatch = useThunkDispatch();
    const {
      bottomHeight,
      currentItemId,
      naturalTopHeight = 0,
      shouldRecalculateHeight,
      topHeight = 0,
      viewportSize,
    } = useSelector((state: IState) => ({
      bottomHeight: state[reduxReducer].bottomHeight,
      currentItemId: state[reduxReducer].currentItemId,
      naturalTopHeight: state[reduxReducer].naturalTopHeight,
      shouldRecalculateHeight: state[reduxReducer].shouldRecalculateHeight,
      topHeight: state[reduxReducer].topHeight,
      viewportSize: state.device.viewportSize,
    }));

    const index = useMemo(() => {
      const nextIndex = React.Children.map(children, (child) => {
        if (React.isValidElement<ICarouselItemProps>(child)) {
          return child?.props?.id;
        }

        return null;
      })?.indexOf(currentItemId);

      return nextIndex ?? -1;
    }, [children, currentItemId]);

    const trackRef = useRef<HTMLDivElement | null>(null);

    const currentChildrenCount = React.Children.count(children);
    // Keep track of the last two changes that occurred to the number of
    // carousel items in the track
    const prevAndCurrentChildrenCountRef = useRef<[number, number]>([
      0,
      currentChildrenCount,
    ]);
    // Of the last two child counts, use the higher number as the number of
    // columns to render in the track. This will force the track to always have
    // enough columns when large changes in the number of carousel items occur
    const trackColumnCount = Math.max(
      ...prevAndCurrentChildrenCountRef.current,
    );

    useEffect(() => {
      const [, prevChildrenCount] = prevAndCurrentChildrenCountRef.current;

      // When the child count changes, update the previous count, and set the
      // new current count
      prevAndCurrentChildrenCountRef.current = [
        prevChildrenCount,
        currentChildrenCount,
      ];
    }, [currentChildrenCount]);

    // Reset to default if the index of the ID could not be found.
    useEffect(() => {
      if (index === -1) {
        dispatch(setCurrentCarouselItemToDefault(reduxReducer, true));
      }
    }, [dispatch, index, reduxReducer]);

    // If shouldRecalculateHeight is ever true, immediately set it to false
    useEffect(() => {
      if (shouldRecalculateHeight) {
        dispatch(setShouldRecalculateHeight(reduxReducer, false));
      }
    }, [dispatch, reduxReducer, shouldRecalculateHeight]);

    // Calculate topHeight, naturalTopHeight, and bottomHeight
    useEffect(() => {
      const targetChild = carouselItemRefs.current[currentItemId];

      if (targetChild) {
        const childHeight = targetChild.clientHeight;

        if (position === CarouselPosition.TOP) {
          const nextNaturalTopHeight = trackRef.current?.clientHeight || 0;

          dispatch(
            setCarouselNaturalTopHeight(reduxReducer, nextNaturalTopHeight),
          );
        }

        if (position === CarouselPosition.TOP && topHeight !== childHeight) {
          dispatch(setCarouselTopHeight(reduxReducer, childHeight));
        } else if (
          position === CarouselPosition.BOTTOM &&
          bottomHeight !== childHeight
        ) {
          dispatch(setCarouselBottomHeight(reduxReducer, childHeight));
        }
      }
    }, [
      bottomHeight,
      carouselItemRefs,
      currentItemId,
      dispatch,
      position,
      reduxReducer,
      shouldRecalculateHeight,
      topHeight,
      viewportSize,
    ]);

    // Set shouldRecalculateHeight to true if anything changed size
    useResizeObserver(trackRef, () => {
      dispatch(setShouldRecalculateHeight(reduxReducer, true));
    });

    return (
      <Container
        backgroundStyle={backgroundStyle}
        naturalTopHeight={naturalTopHeight}
        position={position}
        ref={ref}
        topHeight={topHeight}
      >
        <Track columnCount={trackColumnCount} index={index} ref={trackRef}>
          <TransitionGroup component={null}>{children}</TransitionGroup>
        </Track>
      </Container>
    );
  },
);
