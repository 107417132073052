import { SET_SHOULD_RECALCULATE_HEIGHT } from 'actions';
import { CarouselReduxReducer } from 'typings';

export function setShouldRecalculateHeight(
  reduxReducer: CarouselReduxReducer,
  shouldRecalculateHeight: boolean,
) {
  return {
    reduxReducer,
    shouldRecalculateHeight,
    type: SET_SHOULD_RECALCULATE_HEIGHT,
  };
}
