import { SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE } from 'actions';

export function setIsIssueSpecifierListViewTooltipVisible(
  isIssueSpecifierListViewTooltipVisible: boolean,
) {
  return {
    isIssueSpecifierListViewTooltipVisible,
    type: SET_IS_ISSUE_SPECIFIER_LIST_VIEW_TOOLTIP_VISIBLE,
  };
}
