import { SET_CAMPAIGN_MEMBER_ID, SET_CAMPAIGN_NOTIFICATION_ID } from 'actions';
import { IPmTrackingAction, IPmTrackingState } from 'typings';

const defaultState: IPmTrackingState = {};

export default function pmTracking(
  state: IPmTrackingState = defaultState,
  action: IPmTrackingAction,
): IPmTrackingState {
  switch (action.type) {
    case SET_CAMPAIGN_MEMBER_ID:
      return {
        ...state,
        campaignMemberId: action.campaignMemberId,
      };
    case SET_CAMPAIGN_NOTIFICATION_ID:
      return {
        ...state,
        campaignNotificationId: action.campaignNotificationId,
      };
    default:
      return state;
  }
}
