import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityFanSvg } from 'components/svg/RoomElectricityFan.svg';
import { ReactComponent as RoomElectricityLightBulbSvg } from 'components/svg/RoomElectricityLightBulb.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricityPanelSvg } from 'components/svg/RoomElectricityPanel.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomFloodingSvg } from 'components/svg/RoomFlooding.svg';
import { ReactComponent as RoomFurnaceSvg } from 'components/svg/RoomFurnace.svg';
import { ReactComponent as RoomHotWaterHeaterSvg } from 'components/svg/RoomHotWaterHeater.svg';
import { ReactComponent as RoomSinkUtilitySvg } from 'components/svg/RoomSinkUtility.svg';
import { ReactComponent as RoomWellPumpSvg } from 'components/svg/RoomWellPump.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Fan,
  Flooding,
  Furnace,
  HotWaterHeater,
  Light,
  Outlet,
  Panel,
  Pump,
  Sink,
  Switch,
} from './styles';

export const RoomBasement = ({ icons }: IRoomProps) => {
  const fanIcon = icons.find(({ key }) => key === RoomIconKey.EXHAUST_FAN);
  const floodIcon = icons.find(({ key }) => key === RoomIconKey.FLOOD);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SIMPLE);
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const panelIcon = icons.find(({ key }) => key === RoomIconKey.PANEL);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const furnaceIcon = icons.find(({ key }) => key === RoomIconKey.FURNACE);
  const sinkIcon = icons.find(({ key }) => key === RoomIconKey.SINK_UTILITY);
  const wellPumpIcon = icons.find(({ key }) => key === RoomIconKey.WELL_PUMP);
  const hotWaterHeaterIcon = icons.find(
    ({ key }) => key === RoomIconKey.HW_HEATER,
  );

  return (
    <Container>
      <Fan>
        <RoomButton icon={fanIcon} iconSvg={<RoomElectricityFanSvg />} />
      </Fan>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightBulbSvg />}
        />
      </Light>
      <Panel>
        <RoomButton icon={panelIcon} iconSvg={<RoomElectricityPanelSvg />} />
      </Panel>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Flooding>
        <RoomButton icon={floodIcon} iconSvg={<RoomFloodingSvg />} />
      </Flooding>
      <Furnace>
        <RoomButton icon={furnaceIcon} iconSvg={<RoomFurnaceSvg />} />
      </Furnace>
      <Sink>
        <RoomButton icon={sinkIcon} iconSvg={<RoomSinkUtilitySvg />} />
      </Sink>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Pump>
        <RoomButton icon={wellPumpIcon} iconSvg={<RoomWellPumpSvg />} />
      </Pump>
      <HotWaterHeater>
        <RoomButton
          icon={hotWaterHeaterIcon}
          iconSvg={<RoomHotWaterHeaterSvg />}
        />
      </HotWaterHeater>
    </Container>
  );
};
