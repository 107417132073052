import styled, { css } from 'styled-components/macro';

import { large } from 'styles/breakpoints';
import { IWithThemeProps } from 'typings';

interface IModalHeaderButtonProps {
  side?: 'left' | 'right';
}

export const ModalHeaderButton = styled.button<IModalHeaderButtonProps>`
  background: none;
  border: 0;
  color: ${({ theme }: IWithThemeProps) => theme.topForegroundMuted};
  cursor: pointer;
  height: 36px;
  padding: 7px;
  position: absolute;
  top: 15px;
  width: 36px;
  z-index: 2;

  ${({ theme }: IWithThemeProps) => `
    transition: color ${theme.transitionDurationPrimary} ease;
  `}

  &:hover, &:focus {
    color: ${({ theme }: IWithThemeProps) => theme.topForegroundPrimary};
  }

  ${large`
    top: 17px;
  `}

  ${({ side }) => {
    switch (side) {
      case 'left':
        return css`
          left: 4px;

          ${large`
            left: 17px;
          `}
        `;
      case 'right':
        return css`
          right: 4px;

          ${large`
            right: 17px;
          `}
        `;
      default:
        return '';
    }
  }}
`;
