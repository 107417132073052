import styled from 'styled-components/macro';

import { containerWide } from 'styles/layout';
import { inputSmall } from 'styles/typography';

interface IButtonProps {
  isSelected: boolean;
}

export const Container = styled.div`
  ${containerWide}
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
`;

export const Button = styled.button<IButtonProps>`
  align-items: center;
  background-color: ${(props) => props.theme.bottomBackgroundColor};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  box-shadow: 0 0 5px -5px ${(props) => props.theme.pillBoxSelectedBackgroundColor};
  color: ${(props) => props.theme.bottomForegroundPrimary};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: center;
  margin: 7px 7px;
  outline: none;
  padding: 27px 0 17px 0;
  position: relative;
  width: 27%;

  ${({ isSelected, theme }) => `
    transition: background-color ${theme.transitionDurationPrimary} ease,
      box-shadow ${theme.transitionDurationPrimary} ease,
      color ${theme.transitionDurationPrimary} ease;

    &:focus {
      box-shadow: 0 0 5px 0
        ${theme.pillBoxSelectedBackgroundColor};
    }

    ${
      isSelected
        ? `
      background-color: ${theme.pillBoxSelectedBackgroundColor};
      color: ${theme.pillBoxSelectedForegroundColor};
      > svg {
        [data-svg-class="icon-fill"] {
          fill: ${theme.pillBoxSelectedForegroundColor}
        }
        [data-svg-class="icon-stroke"] {
          stroke: ${theme.pillBoxSelectedForegroundColor}
        }
      }
    `
        : ``
    }
  `};
`;

export const Label = styled.p`
  ${inputSmall}
  flex: 1 1 auto;
  margin: 9px 0 0;
  text-align: center;
`;
