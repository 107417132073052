import {
  HIDE_ACCOUNT_MODAL,
  HIDE_APPOINTMENT_MODAL,
  HIDE_LOGIN_MODAL,
  HIDE_SERVICE_MODAL,
  LOGOUT,
  RESET_APP,
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  SET_HAS_INTERACTION,
  SHOW_ACCOUNT_MODAL,
  SHOW_APPOINTMENT_MODAL,
  SHOW_LOGIN_MODAL,
  SHOW_SERVICE_MODAL,
  VIEW_WIDGET_ONLY,
} from 'actions';
import { IModalAction, IModalState } from 'typings';

const defaultState: IModalState = {
  hasInteraction: false,
  isAccountModalVisible: false,
  isAppointmentModalVisible: false,
  isLoginModalVisible: false,
  isServiceModalVisible: false,
  isViewWidgetOnly: false,
};

export default function modal(
  state: IModalState = defaultState,
  action: IModalAction,
): IModalState {
  switch (action.type) {
    case HIDE_ACCOUNT_MODAL:
      return {
        ...state,
        isAccountModalVisible: false,
      };
    case HIDE_APPOINTMENT_MODAL:
      return {
        ...state,
        isAppointmentModalVisible: false,
      };
    case HIDE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalVisible: false,
      };
    case HIDE_SERVICE_MODAL:
      return {
        ...state,
        isServiceModalVisible: false,
      };
    case LOGOUT:
      return {
        ...state,
        isAccountModalVisible: false,
        isAppointmentModalVisible: false,
      };
    case SET_HAS_INTERACTION:
      return {
        ...state,
        hasInteraction: true,
      };
    case SHOW_ACCOUNT_MODAL:
      return {
        ...state,
        isAccountModalVisible: true,
      };
    case SHOW_APPOINTMENT_MODAL:
      return {
        ...state,
        isAppointmentModalVisible: true,
      };
    case SHOW_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalVisible: true,
      };
    case SHOW_SERVICE_MODAL:
      return {
        ...state,
        isServiceModalVisible: true,
      };
    // For Google organics we want to view the widget without the modal.
    case VIEW_WIDGET_ONLY:
      return {
        ...state,
        isViewWidgetOnly: !!action.isViewWidgetOnly,
      };
    case RESET_APP:
    case RESET_SERVICE_CAROUSEL_FOR_CSR:
    case RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE:
      return {
        ...state,
        hasInteraction: false,
      };
    default:
      return state;
  }
}
