import React from 'react';

import { setCurrentCarouselItemToPrevious } from 'actions';
import { ModalHeaderButton } from 'components/ModalHeaderButton';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { CarouselReduxReducer, ICarouselBackButtonProps } from 'typings';

import { BackSvg } from './styles';

export const CarouselBackButton = ({ onClick }: ICarouselBackButtonProps) => {
  const dispatch = useThunkDispatch();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onClick) {
      onClick(event);
    } else {
      dispatch(setCurrentCarouselItemToPrevious(CarouselReduxReducer.SERVICE));
    }
  };

  return (
    <ModalHeaderButton onClick={handleClick} side="left">
      <BackSvg aria-label="Go Back" />
    </ModalHeaderButton>
  );
};
