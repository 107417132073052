import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  START_SESSION_FAILURE,
  START_SESSION_REQUEST,
  START_SESSION_SUCCESS,
  fetchWithAuth,
} from 'actions';
import { IState } from 'typings';
import analytics, {
  isAnalyticsInitialized,
  setSession as setAnalyticsSession,
} from 'utils/analytics';
import { tryLocalStorageGetItem } from 'utils/localStorage';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

import { upgradeSession } from './upgradeSession';

const SESSION_REFRESH_INTERVAL_DURATION = 60 * 1000; // 60 seconds
let sessionRefreshIntervalId: number;

interface IStartSessionConfig {
  shouldVerifyExistingSession: boolean;
  skipTokenCreation?: boolean;
}

function startSessionFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_SESSIONS_REFRESH_URI,
  });

  return { error, type: START_SESSION_FAILURE };
}

function startSessionRequest() {
  return { type: START_SESSION_REQUEST };
}

function startSessionSuccess(sessionToken: string) {
  setAnalyticsSession(sessionToken);

  return { sessionToken, type: START_SESSION_SUCCESS };
}

export function startSession({
  shouldVerifyExistingSession = true,
  skipTokenCreation = false,
}: IStartSessionConfig) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    clearInterval(sessionRefreshIntervalId);
    sessionRefreshIntervalId = window.setInterval(async () => {
      const {
        auth: { sessionToken },
      } = getState();
      const url = `${process.env.REACT_APP_SESSIONS_REFRESH_URI}`;
      const options = buildPostOptions({ token: sessionToken });
      const response = await dispatch(fetchWithAuth(url, options));

      if (response.status !== 401) {
        const { token } = await response.json();
        dispatch(startSessionSuccess(token));
      }
    }, SESSION_REFRESH_INTERVAL_DURATION);

    const {
      auth: { deviceToken, sessionToken: initialSessionToken },
      userInput: { appointmentId, leadId },
    } = getState();

    // If the user booked an appointment previously, they will have an
    // appointmentId or leadId, so we disregard shouldVerifyExistingSession
    // because in this case we always want to restart the session
    if (shouldVerifyExistingSession && !appointmentId && !leadId) {
      const url = `${process.env.REACT_APP_SESSIONS_REFRESH_URI}`;
      const options = buildPostOptions({ token: initialSessionToken });
      const response = await fetch(url, options);

      if (response.ok) {
        return { sessionToken: initialSessionToken };
      }
    }

    dispatch(startSessionRequest());

    if (!skipTokenCreation) {
      try {
        const userToken = tryLocalStorageGetItem('userToken');
        const url = `${process.env.REACT_APP_SESSIONS_CREATE_URI}`;
        const options = buildPostOptions({ token: deviceToken });
        const response = await fetch(url, options);

        if (!response.ok) {
          throw new Error(response.statusText);
        }

        let { token } = await response.json();
        dispatch(startSessionSuccess(token));

        if (isAnalyticsInitialized()) {
          analytics.send('pageview');
        }

        if (userToken) {
          const { sessionToken } = await dispatch(upgradeSession(userToken));
          token = sessionToken;
        }

        return { sessionToken: token };
      } catch (error) {
        dispatch(startSessionFailure(error));
        throw error;
      }
    }
  };
}
