import moment from 'moment-timezone';

import { DateTimeFormat, ITimeSlot } from 'typings';

export function formatTimeSlot(timeSlot: ITimeSlot, timezone: string) {
  const { endTime, startTime } = timeSlot;
  let { label } = timeSlot;

  if (!label) {
    const endLabel = moment
      .tz(endTime, timezone)
      .format(DateTimeFormat.TIME_END);
    const startLabel = moment
      .tz(startTime, timezone)
      .format(DateTimeFormat.TIME_START);

    label = `${startLabel}-${endLabel}`;
  }

  return label;
}
