import { IRoomIcon } from 'typings';

export interface IRoomProps {
  icons: Array<IRoomIcon>;
}

export interface IIssueIconProps extends React.SVGAttributes<SVGElement> {
  className?: string;
  iconKey: RoomIconKey;
}

export enum RoomKey {
  ATTIC = 'attic',
  BASEMENT = 'basement',
  BATHROOM = 'bathroom',
  BEDROOM = 'bedroom',
  COMMERCIAL = 'commercial',
  DINING = 'dining',
  EMERGENCY = 'emergency',
  GARAGE = 'garage',
  GENERAL = 'general',
  HALL = 'hall',
  KITCHEN = 'kitchen',
  LAUNDRY = 'laundry',
  LIVING = 'living',
  OFFICE = 'office',
  OUTSIDE = 'outside',
  PREVENTATIVE_MAINTENANCE = 'preventative_maintenance',
  SALES = 'sales',
}

export enum RoomIconKey {
  AIR_QUALITY = 'air_quality',
  DEHUMIDIFIER = 'dehumidifier',
  EMERGENCY_CARBON_MONOXIDE = 'emergency_carbon_monoxide',
  EMERGENCY_ELECTRICITY = 'emergency_electricity',
  EMERGENCY_ELECTRICITY_LARGE = 'emergency_electricity_large',
  EMERGENCY_HVAC = 'emergency_hvac',
  EMERGENCY_HVAC_LARGE = 'emergency_hvac_large',
  EMERGENCY_LEAK = 'emergency_leak',
  EXHAUST_FAN = 'exhaust_fan',
  FLOOD = 'flood',
  FLOOD_LARGE = 'flood_large',
  FROZEN_PIPE = 'frozen_pipe',
  FURNACE = 'furnace',
  FURNACE_LARGE = 'furnace_large',
  GAS_LEAK = 'gas_leak',
  GENERATOR = 'generator',
  GRINDER = 'grinder',
  HUMIDIFIER = 'humidifier',
  HVAC_UNIT = 'hvac_unit',
  HVAC_UNIT_ATTIC = 'hvac_unit_attic',
  HW_HEATER = 'hw_heater',
  LIGHT_CHANDELIER = 'light_chandelier',
  LIGHT_EXTERIOR = 'light_exterior',
  LIGHT_PENDANT = 'light_pendant',
  LIGHT_SIMPLE = 'light_simple',
  LIGHT_SWITCH = 'light_switch',
  LIGHT_SWITCH_LARGE = 'light_switch_large',
  LOW_OR_NO_WATER = 'low_or_no_water',
  NOISE = 'noise',
  NO_HOT_WATER = 'no_hot_water',
  OUTLET = 'outlet',
  OUTLET_LARGE = 'outlet_large',
  PANEL = 'panel',
  PLUMBING_LEAK = 'plumbing_leak',
  REGISTER = 'register',
  REGISTER_LARGE = 'register_large',
  SHOWER = 'shower',
  SINK_BATHROOM = 'sink_bathroom',
  SINK_KITCHEN = 'sink_kitchen',
  SINK_KITCHEN_DISPOSAL = 'sink_kitchen_disposal',
  SINK_KITCHEN_LARGE = 'sink_kitchen_large',
  SINK_UTILITY = 'sink_utility',
  SMELL = 'smell',
  SOLAR = 'solar',
  SPIGOT = 'spigot',
  SUMP_PUMP = 'sump_pump',
  THERMOSTAT = 'thermostat',
  THERMOSTAT_LARGE = 'thermostat_large',
  TOILET = 'toilet',
  TRADE_ELECTRICAL = 'trade_electrical',
  TRADE_HVAC = 'trade_hvac',
  TRADE_OTHER = 'trade_other',
  TRADE_PLUMBING = 'trade_plumbing',
  UV_LIGHT = 'uv_light',
  WATER_TREATMENT = 'water_treatment',
  WELL_PUMP = 'well_pump',
}
