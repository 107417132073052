import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import isEqual from 'lodash.isequal';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { setLeadId } from 'actions';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { IState } from 'typings';
import {
  ILeadCustomerInput,
  ISubmitLeadMutation,
  ISubmitLeadMutationVariables,
  NotificationPreference,
  ServiceType,
} from 'typings/GraphQL';
import { buildBookingOptions } from 'utils/buildBookingOptions';

const submitLeadMutation = loader('../../queries/submitLead.graphql');

export const useSubmitLeadMutation = () => {
  const dispatch = useThunkDispatch();

  const [props] = useDebounce(
    useSelector(
      (state: IState) => ({
        accountTracking: state.accountTracking,
        address: state.userInput.address,
        analytics: state.sourceTrackingAnalytics,
        containsXssInput: state.userInput.containsXssInput,
        customForms: state.userInput.customForms,
        customerLocationPhone: state.userInput.customerLocationPhone,
        customerPhone: state.userInput.customerPhone,
        email: state.userInput.email,
        firstName: state.userInput.firstName,
        flowId: state.client.flow?.id,
        isEmergency: state.userInput.isEmergency,
        isServiceModalVisible: state.modal.isServiceModalVisible,
        issueStack: state.userInput.issueStack,
        lastName: state.userInput.lastName,
        leadId: state.userInput.leadId,
        notificationPreference: state.userInput.notificationPreference,
        preferredPartnerId: state.userInput.preferredPartner?.partnerId,
        repairComments: state.userInput.repairComments,
        selectedEnergySources: state.userInput.selectedEnergySources,
        selectedEquipment: state.userInput.selectedEquipment,
        selectedSchedulePreference: state.userInput.selectedSchedulePreference,
        selectedSchedulePreferenceTime:
          state.userInput.selectedSchedulePreferenceTime,
        selectedTimeSlot: state.userInput.selectedTimeSlot,
        serviceCodeId: state.userInput.serviceCodeId,
        serviceType: state.userInput.serviceType,
        systemComments: state.userInput.systemComments,
        theme: state.client.theme,
      }),
      isEqual,
    ),
    1000,
    { equalityFn: isEqual },
  );

  const propsRef = useRef(props);

  const [submitLead, { called }] = useMutation<
    ISubmitLeadMutation,
    ISubmitLeadMutationVariables
  >(submitLeadMutation, {
    onCompleted: (result: ISubmitLeadMutation) => {
      const { lead = null } = result.submitLead || {};

      if (lead) {
        dispatch(setLeadId(lead.id));
      }
    },
  });

  // skip if we've called submitLead before and the props have not changed
  if (called && isEqual(propsRef.current, props)) {
    return;
  }

  // update props ref
  propsRef.current = props;

  const {
    accountTracking,
    address,
    analytics,
    containsXssInput,
    customForms,
    customerLocationPhone,
    customerPhone,
    email,
    firstName,
    flowId,
    isEmergency,
    isServiceModalVisible,
    issueStack,
    lastName,
    leadId,
    notificationPreference,
    preferredPartnerId,
    repairComments,
    selectedEnergySources,
    selectedEquipment,
    selectedSchedulePreference,
    selectedSchedulePreferenceTime,
    selectedTimeSlot,
    serviceCodeId,
    serviceType,
    systemComments,
    theme,
  } = props;

  if (!isServiceModalVisible || !theme) {
    return;
  }

  const {
    settings: {
      customerNotificationPreferencesEnabled,
      enterpriseEnabled,
      enterpriseVersion,
      optionalCustomerEmailFieldEnabled,
      requiredCustomerEmailFieldEnabled,
    },
    strings,
  } = theme;

  const schedulePreferenceEnabled =
    serviceType === ServiceType.SALES ||
    theme.settings.schedulePreferenceEnabled;

  const phoneId = customerPhone.id;
  const phoneIdForNotification =
    [customerPhone, customerLocationPhone].find(({ isMobile }) => !!isMobile)
      ?.id ?? phoneId;

  const isEmailIncluded =
    !!email &&
    ((customerNotificationPreferencesEnabled === false &&
      optionalCustomerEmailFieldEnabled === true) ||
      requiredCustomerEmailFieldEnabled === true ||
      notificationPreference.includes(NotificationPreference.EMAIL));

  const customer: ILeadCustomerInput = {
    address: address
      ? {
          city: address.city,
          countryCode: address.countryCode,
          id: address.id,
          state: address.state,
          street1: address.street1,
          street2: address.street2,
          timezone: address.timezone,
          zipCode: address.zipCode,
        }
      : undefined,
    email: isEmailIncluded ? email : undefined,
    firstName,
    lastName,
    notificationPreference: customerNotificationPreferencesEnabled
      ? notificationPreference
      : undefined,
    phoneId,
    phoneIdForNotification,
  };

  const options = buildBookingOptions({
    accountTracking,
    analytics,
    customForms,
    enterpriseEnabled,
    enterpriseVersion,
    flowId,
    isEmergency,
    issueStack,
    leadId,
    preferredPartnerId,
    repairComments,
    schedulePreferenceEnabled,
    selectedEnergySources,
    selectedEquipment,
    selectedSchedulePreference,
    selectedSchedulePreferenceTime,
    serviceCodeId,
    strings,
    systemComments,
  });

  if (!containsXssInput)
    submitLead({
      variables: {
        customer,
        options,
        serviceType,
        startTime: selectedTimeSlot?.startTime,
      },
    });
};
