import {
  CarouselBottomButtonType,
  ICarouselBottomButtonSvgProps,
  ISvgProps,
} from 'typings';

const getArrowStrokeTransform = (buttonType: CarouselBottomButtonType) => {
  switch (buttonType) {
    case CarouselBottomButtonType.CLOSE:
      return 'translate(-13) rotate(0)';
    case CarouselBottomButtonType.CONTINUE_DOWN:
      return 'translate(0) rotate(90 27 27)';
    case CarouselBottomButtonType.BACK:
    case CarouselBottomButtonType.CONTINUE:
    default:
      return 'translate(0) rotate(0)';
  }
};

const getArrowTopTransform = (buttonType: CarouselBottomButtonType) => {
  switch (buttonType) {
    case CarouselBottomButtonType.CLOSE:
      return 'rotate(5 40 23)';
    case CarouselBottomButtonType.BACK:
    case CarouselBottomButtonType.CONTINUE:
    case CarouselBottomButtonType.CONTINUE_DOWN:
    default:
      return 'rotate(0 40 23)';
  }
};

const getArrowBottomTransform = (buttonType: CarouselBottomButtonType) => {
  switch (buttonType) {
    case CarouselBottomButtonType.CLOSE:
      return 'rotate(-5 40 23)';
    case CarouselBottomButtonType.BACK:
    case CarouselBottomButtonType.CONTINUE:
    case CarouselBottomButtonType.CONTINUE_DOWN:
    default:
      return 'rotate(0 40 23)';
  }
};

export const CarouselBottomButtonArrowSvg = ({
  buttonType = CarouselBottomButtonType.CONTINUE,
  ...props
}: ICarouselBottomButtonSvgProps) => (
  <svg height={54} viewBox="0 0 54 54" width={54} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle
        cx={27}
        cy={27}
        data-svg-class="CarouselContinueButton-circle"
        fill="#6273C9"
        r={26}
      />

      {buttonType === CarouselBottomButtonType.HOME ? (
        <g
          data-svg-class="CarouselContinueButton-arrow-stroke"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
        >
          <path d="M38.38 22v17h-8.696v-9.666H23.96V39h-8.58V22" />
          <path d="M12.38 25l15-13h0l15 13" />
        </g>
      ) : buttonType === CarouselBottomButtonType.RETRY ? (
        <g
          data-svg-class="CarouselContinueButton-arrow-stroke"
          fill="none"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="square"
        >
          <path d="M35.566 14.679l.694 6.555M28.116 22.607l8.138-.47M35.377 21.19c-3.72-4.936-10.736-5.922-15.672-2.202-4.937 3.72-5.923 10.737-2.203 15.673s10.737 5.922 15.673 2.203a11.146 11.146 0 004.156-6.362" />
        </g>
      ) : (
        <>
          {/**
           * Inner arrow
           * 40, 27.5 is convergence point for all lines
           */}
          <g
            data-svg-class="CarouselContinueButton-arrow-stroke"
            stroke="#6273C9"
            strokeLinejoin="round"
            transform={getArrowStrokeTransform(buttonType)}
          >
            {/* Top left to bottom right */}
            <path
              d="M53 38L27 17"
              data-svg-class="CarouselContinueButton-arrow-top"
              transform={getArrowTopTransform(buttonType)}
            />
            {/* Stem of the arrow. Line from convergence point back. */}
            <path
              d="M40 27.5H13"
              data-svg-class="CarouselContinueButton-arrow-middle"
            />
            {/* Bottom of arrow. Line from convergence point down and left. */}
            <path
              d="M53 17L27 38"
              data-svg-class="CarouselContinueButton-arrow-bottom"
              transform={getArrowBottomTransform(buttonType)}
            />
          </g>
        </>
      )}

      {/**
       * Outer circle
       * Formed with two arcs around the same circle. The smaller arc transitions to a
       * lighter color to make the circle look partially filled in.
       */}
      <path
        d="M27 1A26 26 0 0 0 3.4 38.2"
        data-svg-class="CarouselContinueButton-arc-light"
        stroke="#6273C9"
      />
      <path
        d="M27 1A26 26 0 1 1 3.4 38.2"
        data-svg-class="CarouselContinueButton-arc-dark"
        stroke="#6273C9"
      />
    </g>
  </svg>
);

export const CarouselBottomButtonArrowSvgWithProps = ({
  isLoading: _isLoading,
  ...props
}: ISvgProps & ICarouselBottomButtonSvgProps) => {
  return <CarouselBottomButtonArrowSvg {...props} />;
};
