export enum CameraDestinationType {
  DATA_URI = 0,
  FILE_URI = 1,
  NATIVE_URI = 2,
}

export enum CameraSourceType {
  PHOTOLIBRARY = 0,
  CAMERA = 1,
  SAVEDPHOTOALBUM = 2,
}

enum CameraEncodingType {
  JPEG = 0,
  PNG = 1,
}

export enum CameraMediaType {
  PICTURE = 0,
  VIDEO = 1,
  ALLMEDIA = 2,
}

export enum Connection {
  CELL = 'cellular',
  CELL_2G = '2g',
  CELL_3G = '3g',
  CELL_4G = '4g',
  ETHERNET = 'ethernet',
  NONE = 'none',
  UNKNOWN = 'unknown',
  WIFI = 'wifi',
}

export interface ICameraOptions {
  allowEdit?: boolean;
  correctOrientation?: boolean;
  destinationType?: CameraDestinationType;
  encodingType?: CameraEncodingType;
  mediaType?: CameraMediaType;
  quality?: number;
  saveToPhotoAlbum?: boolean;
  sourceType?: CameraSourceType;
  targetHeight?: number;
  targetWidth?: number;
}

interface ICaptureImageOptions {
  limit: number;
}

export interface ICaptureVideoOptions extends ICaptureImageOptions {
  duration: number;
}

export interface IMediaFile {
  fullPath: string;
  getFormatData: (
    onSuccess: (data: IMediaFileData) => void,
    onError: (err: Error) => void,
  ) => void;
  lastModifiedDate: Date | null;
  localURL: string | null;
  name: string;
  size: number;
  type: string | null;
}

interface IMediaFileData {
  duration: number;
  height: number;
  width: number;
}

export interface ILocalFile {
  file(
    success?: (file: File) => void,
    errorCallback?: (error: IFileError) => void,
  ): void;
  filesystem: string;
  fullPath: string;
  isDirectory: boolean;
  isFile: boolean;
  name: string;
  nativeURL: string;
  toURL(): string;
}

export enum LocalFileSystemAccessType {
  TEMPORARY,
  PERSISTENT,
}

export interface IFileError {
  code: number | null;
}

// This interface is the shape of `window.navigator` when Cordova is present
export interface IWindowNavigator extends Omit<Navigator, 'connection'> {
  camera: {
    getPicture(
      onSuccess: (data: string) => void,
      onError: (message: string) => void,
      options?: ICameraOptions,
    ): void;
  };
  connection: {
    type: Connection;
  };
  device: {
    capture: {
      captureVideo(
        onSuccess: (mediaFiles: Array<IMediaFile>) => void,
        onError: (err: Error) => void,
        options?: ICaptureVideoOptions,
      ): void;
    };
  };
  notification: {
    alert(
      message: string,
      callback?: () => void,
      title?: string,
      buttonTitle?: string,
    ): void;
  };
  splashscreen: {
    hide(): void;
    show(): void;
  };
}

// This interface is the shape of `window.cordova` when Cordova is present
export interface IWindowCordova extends Cordova {
  InAppBrowser: {
    open(url?: string, target?: string, features?: string): Window | null;
  };
}

export interface IWindowWithFileSystem extends Window {
  requestFileSystem?(
    accessType: LocalFileSystemAccessType,
    size: number,
    successCallback: (fs: any) => void,
    errorCallback: (error: any) => void,
  ): void;
  resolveLocalFileSystemURL?(
    path: string,
    successCallback: (localFile: ILocalFile) => void,
    errorCallback: (error: any) => void,
  ): void;
}
