import { TradeIcon, TradeId } from '@homex/se-react-components';
import { CarouselItemId } from '@homex/se-widget-flow-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { setSelectedIssue, setSelectedRoomIcon } from 'actions';
import { Phrase } from 'components/Phrase';
import { useStringContext } from 'components/StringContext';
import { ThemedTooltip } from 'components/ThemedTooltip';
import { usePrevious } from 'hooks/usePrevious';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { IRoomButtonProps, IState } from 'typings';

import {
  Button,
  Label,
  TooltipBody,
  TooltipContent,
  TooltipHeader,
  TooltipHeaderText,
  TooltipSubhead,
} from './styles';

export const RoomButton = ({
  hasTooltip = true,
  icon,
  iconSvg,
}: IRoomButtonProps) => {
  const dispatch = useThunkDispatch();
  const { isInView, selectedRoomIcon } = useSelector((state: IState) => ({
    isInView:
      state.serviceCarousel.currentItemId === CarouselItemId.IssueSpecifier &&
      state.modal.isServiceModalVisible,
    selectedRoomIcon: state.userInput.selectedRoomIcon,
  }));
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const prevIcon = usePrevious(icon);
  const prevSelectedRoomIcon = usePrevious(selectedRoomIcon);
  const wasInView = usePrevious(isInView);
  const strings = useStringContext();

  const handleIconChange = () => {
    if (icon) {
      if (!selectedRoomIcon || selectedRoomIcon.key !== icon.key) {
        // set visible
        setIsTooltipVisible(true);
        dispatch(setSelectedIssue());
        dispatch(setSelectedRoomIcon(icon));
      } else {
        // unset visible
        setIsTooltipVisible(false);
        dispatch(setSelectedIssue());
        dispatch(setSelectedRoomIcon());
      }
    }
  };

  let commonProblems = '';
  let name = '';
  let isDisabled = false;
  let tradeId: TradeId | null = null;

  if (icon) {
    const key = icon.key.toUpperCase();
    name = strings[`ISSUE_SPECIFIER.ICON.${key}.NAME`] || icon.name;
    commonProblems =
      strings[`ISSUE_SPECIFIER.ICON.${key}.COMMON_PROBLEMS`] ||
      icon.commonProblems ||
      '';

    if (icon.issue) {
      tradeId = icon.issue.trade.id;
    } else if (icon.serviceCode) {
      tradeId = icon.serviceCode.trade.id;
    } else {
      tradeId = TradeId.OTHER;
    }
  } else {
    isDisabled = true;
  }

  const isIconSelected =
    !!selectedRoomIcon && !!icon && icon.key === selectedRoomIcon.key;
  const wasSelected =
    !!prevSelectedRoomIcon &&
    !!prevIcon &&
    prevIcon.key === prevSelectedRoomIcon.key;

  useEffect(() => {
    if (isIconSelected && isInView) {
      setIsTooltipVisible(true);
    }
  }, [isIconSelected, isInView]);

  useEffect(() => {
    let timeoutId = 0;
    const didBecomeDeselected = !isIconSelected && wasSelected;
    const didLeaveView = isIconSelected && !isInView && wasInView;

    if (didBecomeDeselected || didLeaveView) {
      setIsTooltipVisible(false);
    } else if (isIconSelected && isInView && (!wasInView || !wasSelected)) {
      timeoutId = window.setTimeout(() => {
        if (isIconSelected && isInView) {
          setIsTooltipVisible(true);
        }
      }, 750);
    }

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isIconSelected, isInView, wasInView, wasSelected]);

  return (
    <>
      <Button
        disabled={isDisabled}
        isSelected={isIconSelected}
        onClick={handleIconChange}
        ref={buttonRef}
      >
        {iconSvg}
      </Button>
      {hasTooltip ? (
        <ThemedTooltip
          hasCloseButton={true}
          isInverted={true}
          isVisible={isTooltipVisible}
          onClose={handleIconChange}
          shouldCloseOnBlur={false}
          targetRef={buttonRef.current}
        >
          <TooltipContent>
            <TooltipHeader>
              {tradeId && <TradeIcon tradeId={tradeId} />}
              <TooltipHeaderText>{name}</TooltipHeaderText>
            </TooltipHeader>
            <TooltipSubhead>
              <Phrase id="ISSUE_SPECIFIER.ICON.TOOLTIP" />
            </TooltipSubhead>
            {commonProblems && <TooltipBody>{commonProblems}</TooltipBody>}
          </TooltipContent>
        </ThemedTooltip>
      ) : (
        <Label>{name}</Label>
      )}
    </>
  );
};
