import { Action, applyMiddleware, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import reducers from 'reducers';
import { IState } from 'typings';

export default function configureReduxStore() {
  return createStore<
    IState,
    Action,
    {
      dispatch: ThunkDispatch<IState, undefined, Action>;
    },
    unknown
  >(
    reducers,

    // Add store enhancers
    applyMiddleware(thunk),
  );
}
