import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  REGISTER_MEDIA_FAILURE,
  REGISTER_MEDIA_REQUEST,
  REGISTER_MEDIA_SUCCESS,
  fetchWithAuth,
} from 'actions';
import { IState } from 'typings';
import { logNetworkError } from 'utils/logging';
import { buildDeleteOptions } from 'utils/request';

// TODO: Consider making `removeMedia` its own action
function registerMediaFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_MEDIA_URI,
  });

  return { error, type: REGISTER_MEDIA_FAILURE };
}

function registerMediaRequest(url: string) {
  return { type: REGISTER_MEDIA_REQUEST, url };
}

function registerMediaSuccess(url: string) {
  return { type: REGISTER_MEDIA_SUCCESS, url };
}

export function removeMedia(mediaId: string) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const url = `${process.env.REACT_APP_MEDIA_URI}/${mediaId}`;
    const { sessionToken } = getState().auth;
    const options = buildDeleteOptions({ token: sessionToken });

    dispatch(registerMediaRequest(url));

    try {
      const response = await dispatch(fetchWithAuth(url, options));

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      dispatch(registerMediaSuccess(url));
    } catch (error) {
      // Handle state changes
      dispatch(registerMediaFailure(error));

      // Handle localized component error messages to user
      throw error;
    }
  };
}
