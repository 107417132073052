export const tryLocalStorageGetItem: typeof window.localStorage.getItem = (
  key,
) => {
  // `navigator.cookieEnabled` will handle most cases where cookies are disabled
  // by the user
  if (window.navigator.cookieEnabled) {
    try {
      return window.localStorage.getItem(key);
    } catch {
      // For some browsers, cookieEnabled will return `true` within a third-
      // party frame even if third-party cookies are disabled. This catch
      // handles those situations.
      return null;
    }
  }

  return null;
};

export const tryLocalStorageSetItem: typeof window.localStorage.setItem = (
  key,
  value,
) => {
  // `navigator.cookieEnabled` will handle most cases where cookies are disabled
  // by the user
  if (window.navigator.cookieEnabled) {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      // For some browsers, cookieEnabled will return `true` within a third-
      // party frame even if third-party cookies are disabled. This catch
      // handles those situations.
    }
  }
};

export const tryLocalStorageRemoveItem: typeof window.localStorage.removeItem =
  (key) => {
    // `navigator.cookieEnabled` will handle most cases where cookies are disabled
    // by the user
    if (window.navigator.cookieEnabled) {
      try {
        window.localStorage.removeItem(key);
      } catch {
        // For some browsers, cookieEnabled will return `true` within a third-
        // party frame even if third-party cookies are disabled. This catch
        // handles those situations.
      }
    }
  };
