import { css } from 'styled-components/macro';

export const pillGroupStyles = css`
  margin-bottom: 29px;
  margin-top: -8px;
`;

export const tradeIconStyles = css`
  margin: 0 8px 0 9px;
`;
