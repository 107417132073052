import { useSelector } from 'react-redux';

import { CarouselBackButton } from 'components/CarouselBackButton';
import { Phrase } from 'components/Phrase';
import { ICarouselItemTopProps, IState } from 'typings';

import { Header } from './styles';

export const CarouselItemTop = ({ headerPhraseId }: ICarouselItemTopProps) => {
  const { currentItemId, history } = useSelector((state: IState) => ({
    currentItemId: state.serviceCarousel.currentItemId,
    history: state.serviceCarousel.history,
  }));

  const isBackButtonHidden =
    history.length === 1 && history[0] === currentItemId;

  return (
    <>
      {!isBackButtonHidden && <CarouselBackButton />}
      <Header>
        <Phrase id={headerPhraseId} />
      </Header>
    </>
  );
};
