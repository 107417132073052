import { SET_LEAD_SOURCE_COMPANION_FIELD_VALUE } from 'actions';

export function setLeadSourceCompanionFieldValue(
  leadSourceCompanionFieldValue: string,
) {
  return {
    leadSourceCompanionFieldValue,
    type: SET_LEAD_SOURCE_COMPANION_FIELD_VALUE,
  };
}
