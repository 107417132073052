import { LoadingIndicator } from '@homex/se-react-components';
import styled, { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    width: 100%;
  }

  #root {
    height: 100%;
    padding: 0;
    width: 100%;
  }
`;

export const LoadingIndicatorStyled = styled(LoadingIndicator)`
  background-color: #fff;
  z-index: 1;
`;
