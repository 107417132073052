import styled from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const Fan = styled.div`
  height: 35px;
  position: absolute;
  right: 37.7%;
  top: 20px;
  width: 35px;

  ${medium`
    height: 39px;
    right: 131px;
    top: 8px;
    width: 39px;
  `}

  ${large`
    right: 164px;
    top: 10px;
  `}
`;

export const Light = styled.div`
  height: 54px;
  left: 6.3%;
  position: absolute;
  top: 0;
  width: 48px;

  ${medium`
    height: 59px;
    left: 21px;
    width: 53px;
  `}

  ${large`
    left: 47px;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 1.7%;
  position: absolute;
  top: 87px;
  width: 14px;

  ${medium`
    height: 25px;
    left: 5px;
    top: 75px;
    width: 15px;
  `}

  ${large`
    left: 11px;
    top: 92px;
  `}
`;

export const Panel = styled.div`
  height: 65px;
  position: absolute;
  right: 36.7%;
  top: 74px;
  width: 43px;

  ${medium`
    height: 72px;
    right: 129px;
    top: 61px;
    width: 47px;
  `}

  ${large`
    right: 164px;
    top: 70px;
  `}
`;

export const Outlet = styled.div`
  bottom: 124px;
  height: 23px;
  position: absolute;
  right: 41.5%;
  width: 14px;

  ${medium`
    bottom: 127px;
    height: 25px;
    right: 145px;
    width: 15px;
  `}

  ${large`
    bottom: 134px;
    right: 180px;
  `}
`;

export const Flooding = styled.div`
  bottom: 102px;
  height: 12px;
  position: absolute;
  right: 36.5%;
  width: 46px;

  ${medium`
    height: 13px;
    right: 129px;
    width: 50px;
  `}

  ${large`
    right: 160px;
  `}
`;

export const Furnace = styled.div`
  bottom: 101px;
  height: 174px;
  position: absolute;
  right: -9.1%;
  width: 140px;

  ${medium`
    bottom: 100px;
    height: 192px;
    right: -32px;
    width: 154px;
  `}

  ${large`
    right: -20px;
  `}
`;

export const Sink = styled.div`
  bottom: 101px;
  height: 109px;
  left: 2%;
  position: absolute;
  width: 75px;

  ${medium`
    height: 120px;
    left: 6px;
    width: 83px;
  `}

  ${large`
    left: 32px;
  `}
`;

export const Pump = styled.div`
  bottom: 69px;
  height: 64px;
  position: absolute;
  right: 0;
  width: 40px;
  z-index: 1;

  ${medium`
    bottom: 66px;
    height: 70px;
    right: -1px;
    width: 44px;
  `}

  ${large`
    right: 5px;
  `}
`;

export const HotWaterHeater = styled.div`
  bottom: 101px;
  height: 155px;
  left: 25.5%;
  position: absolute;
  width: 70px;

  ${medium`
    height: 171px;
    left: 89px;
    width: 77px;
  `}

  ${large`
    left: 126px;
  `}
`;
