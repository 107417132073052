import { combineReducers } from 'redux';

import { IState } from 'typings';

import accountTracking from './accountTracking';
import appointmentCarousel from './appointmentCarousel';
import auth from './auth';
import calendar from './calendar';
import client from './client';
import device from './device';
import modal from './modal';
import pmTracking from './pmTracking';
import serviceCarousel from './serviceCarousel';
import sourceTrackingAnalytics from './sourceTrackingAnalytics';
import userInput from './userInput';

export default combineReducers<IState>({
  accountTracking,
  appointmentCarousel,
  auth,
  calendar,
  client,
  device,
  modal,
  pmTracking,
  serviceCarousel,
  sourceTrackingAnalytics,
  userInput,
  // TODO: Look into this `as any` more
  // https://github.com/reduxjs/redux/issues/2709#issuecomment-357328709
} as any);
