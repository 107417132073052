import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { resetApp } from 'actions';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { IErrorBoundaryDispatchProps } from 'typings';

const mapDispatchToProps = (
  dispatch: Dispatch,
): IErrorBoundaryDispatchProps => ({
  handleResetApp: () => dispatch(resetApp()),
});

const ErrorBoundaryContainer = connect<null, IErrorBoundaryDispatchProps>(
  null,
  mapDispatchToProps,
)(ErrorBoundary);

export default ErrorBoundaryContainer;
