import { SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE } from 'actions';

export const setCustomerLookupPhoneInputValue = (
  customerLookupPhoneInputValue?: string,
) => {
  return {
    customerLookupPhoneInputValue,
    type: SET_CUSTOMER_LOOKUP_PHONE_INPUT_VALUE,
  };
};
