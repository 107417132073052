import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityFanSvg } from 'components/svg/RoomElectricityFan.svg';
import { ReactComponent as RoomElectricityLightPendantSvg } from 'components/svg/RoomElectricityLightPendant.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomShowerSvg } from 'components/svg/RoomShower.svg';
import { ReactComponent as RoomSinkBathroomSvg } from 'components/svg/RoomSinkBathroom.svg';
import { ReactComponent as RoomToiletSvg } from 'components/svg/RoomToilet.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Fan,
  Light,
  Outlet,
  Shower,
  Sink,
  Switch,
  Toilet,
  Vent,
} from './styles';

export const RoomBathroom = ({ icons }: IRoomProps) => {
  const fanIcon = icons.find(({ key }) => key === RoomIconKey.EXHAUST_FAN);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_PENDANT);
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const showerIcon = icons.find(({ key }) => key === RoomIconKey.SHOWER);
  const sinkIcon = icons.find(({ key }) => key === RoomIconKey.SINK_BATHROOM);
  const toiletIcon = icons.find(({ key }) => key === RoomIconKey.TOILET);
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <Fan>
        <RoomButton icon={fanIcon} iconSvg={<RoomElectricityFanSvg />} />
      </Fan>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightPendantSvg />}
        />
      </Light>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Shower>
        <RoomButton icon={showerIcon} iconSvg={<RoomShowerSvg />} />
      </Shower>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Sink>
        <RoomButton icon={sinkIcon} iconSvg={<RoomSinkBathroomSvg />} />
      </Sink>
      <Toilet>
        <RoomButton icon={toiletIcon} iconSvg={<RoomToiletSvg />} />
      </Toilet>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
