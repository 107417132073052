import Raven from 'raven-js';
import React from 'react';

import { Phrase } from 'components/Phrase';
import { ReactComponent as ErrorBoundarySvg } from 'components/svg/ErrorBoundary.svg';
import { IErrorBoundaryLocalState, IErrorBoundaryProps } from 'typings';

import {
  Container,
  Description,
  Header,
  HeaderText,
  Subhead,
  Wrapper,
} from './styles';

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryLocalState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    this.props.handleResetApp();
    Raven.captureException(error, {
      extra: errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Wrapper>
          <Header>
            <HeaderText>
              <Phrase id="ERROR_BOUNDARY.HEADER" />
            </HeaderText>
          </Header>
          <Container>
            <ErrorBoundarySvg />
            <Subhead>
              <Phrase id="ERROR_BOUNDARY.SUBHEAD" />
            </Subhead>
            <Description>
              <Phrase id="ERROR_BOUNDARY.DESCRIPTION.HTML" />
            </Description>
          </Container>
        </Wrapper>
      );
    } else {
      return this.props.children;
    }
  }
}
