import { WebAnalytics } from '@homex/ignite-analytics';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const analytics: WebAnalytics = new WebAnalytics();

let initialized = false;

// when setting the session, we pull the session id out of
// the session token/jwt
export function setSession(sessionToken: string | undefined) {
  if (initialized) {
    let token = '';

    if (sessionToken) {
      const jwt = jwtDecode<JwtPayload>(sessionToken);

      if (jwt && jwt.sub) {
        token = jwt.sub;
      }
    }

    analytics.set('corellationKey', token);
  }
}

export function init(clientKey: string, sessionToken: string | undefined) {
  analytics.configure({
    apiKey: `${process.env.REACT_APP_ANALYTICS_API_KEY}`,
    consumerKey: `${process.env.REACT_APP_ANALYTICS_CONSUMER_KEY}`,
    endpoint: `${process.env.REACT_APP_ANALYTICS_ROOT}/v1/events`,
    entityKey: clientKey,
  });
  initialized = true;
  setSession(sessionToken);
  analytics.send('pageview');
}

export function isAnalyticsInitialized() {
  return initialized;
}

export default analytics;
