import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  setCurrentCarouselItemToDefault,
} from 'actions';
import { CarouselReduxReducer, IState } from 'typings';

export function resetServiceCarouselForCsr() {
  return (dispatch: ThunkDispatch<IState, null, Action>) => {
    // Clear our session and user tokens. On the next request, we'll get a 401
    // and a new session will be started (via configureApolloClient.ts)
    // Then direct all reducers to clear their state
    dispatch({ type: RESET_SERVICE_CAROUSEL_FOR_CSR });

    // Reset the service carousel and clear history
    dispatch(
      setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE, true),
    );
  };
}
