import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'components/Modal';
import { IState } from 'typings';

export const AppointmentModal = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const {
    bottomHeight = 0,
    isVisible,
    topHeight = 0,
  } = useSelector((state: IState) => ({
    bottomHeight: state.appointmentCarousel.bottomHeight,
    isVisible: state.modal.isAppointmentModalVisible,
    topHeight: state.appointmentCarousel.topHeight,
  }));

  return (
    <Modal height={bottomHeight + topHeight} isVisible={isVisible}>
      {children}
    </Modal>
  );
};
