import { Connection, Mode, Platform } from 'typings';

// We *must* import cordovaApi in this file. This is necessary because we need the reference to the original window.File
// to get copied to cordovaApi.DomFile prior to loading the cordova scripts, which will overwrite window.File.
// Related issue: https://github.com/apache/cordova-plugin-file/issues/265
import cordovaApi from './cordovaApi';

const initializeTime: number = Date.now();

// called when the app gets paused
function onPause() {
  return;
}

// called when app gets resumed
function onResume() {
  // to keep the app running the latest widget-ui source
  // we're going to refresh on resume but only after an hour
  const refreshIntervalMinutes = 60;

  if (Date.now() - refreshIntervalMinutes * 60000 > initializeTime) {
    // show the splash screen to avoid white flash on reload
    cordovaApi.splashscreen.show();

    // force window to refresh
    window.location.reload();
  }
}

// notifies the user a connection is not available. gives them
// a Retry button which checks the connection type and either
// hides the splashscreen or calls itself again
function displayNoConnection() {
  cordovaApi.notification.alert(
    'Make sure your device is connected to the internet',
    () => {
      setTimeout(() => {
        if (cordovaApi.connection.getType() === Connection.NONE) {
          displayNoConnection();
        } else {
          cordovaApi.splashscreen.hide();
        }
      }, 3000);
    },
    'No Internet Connection',
    'Retry',
  );
}

// called when the app loses connectivity
function onOffline() {
  // if we have no connection show the splashscreen, notify the
  // user and watch for a change in connectivity
  if (cordovaApi.connection.getType() === Connection.NONE) {
    cordovaApi.splashscreen.show();
    displayNoConnection();
  }
}

function onDeviceReady() {
  // handle the Cordova pause and resume events
  document.addEventListener('pause', onPause, false);
  document.addEventListener('resume', onResume, false);

  // handle the Cordova offline event
  document.addEventListener('offline', onOffline, false);

  // override window open
  window.open = cordovaApi.browser.open;

  // hide the splashscreen in the app
  cordovaApi.splashscreen.hide();
}

export default async function configureCordova(mode: Mode, platform: Platform) {
  return new Promise<void>((resolve) => {
    // if we're running as the app let's load our cordova scripts
    if (
      mode === Mode.APP &&
      (platform === Platform.ANDROID || platform === Platform.IOS)
    ) {
      // add listener that the cordova frame will call once it has been initialized
      document.addEventListener(
        'deviceready',
        () => {
          resolve();

          // Our app does not render until the above resolve is called, so add a slight delay before calling
          // onDeviceReady to prevent a white screen flash
          setTimeout(() => {
            onDeviceReady();
          }, 10);
        },
        false,
      );

      // Dynamically add the cordova script to the document
      const cordovaScript = document.createElement('script');
      cordovaScript.type = 'text/javascript';
      cordovaScript.src = `${process.env.PUBLIC_URL}/cordova/${platform}/cordova.js`;

      if (document.head) {
        document.head.appendChild(cordovaScript);
      } else {
        document.body.appendChild(cordovaScript);
      }
    } else {
      resolve();
    }
  });
}
