import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'components/Modal';
import { IState } from 'typings';

export const ServiceModal = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const {
    bottomHeight = 0,
    isStatic,
    isViewWidgetOnly,
    isVisible,
    topHeight = 0,
  } = useSelector((state: IState) => ({
    bottomHeight: state.serviceCarousel.bottomHeight,
    isStatic: state.client.isStatic,
    isViewWidgetOnly: state.modal.isViewWidgetOnly,
    isVisible: state.modal.isServiceModalVisible,
    topHeight: state.serviceCarousel.topHeight,
  }));

  return (
    <Modal
      clearCurtain={isViewWidgetOnly}
      height={bottomHeight + topHeight}
      isStatic={isStatic}
      isVisible={isVisible}
    >
      {children}
    </Modal>
  );
};
