import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  IGoogleAnalyticsEvent,
  IState,
} from 'typings';

export function buildGoogleAnalyticsEvent(
  action: GoogleAnalyticsAction,
  state: IState,
  options?: {
    label?: string;
    value?: number;
  },
): IGoogleAnalyticsEvent {
  return {
    action,
    analyticsKey: state.client.flow?.key,
    category: GoogleAnalyticsCategory.SCHEDULE_ENGINE_BOOKING,
    gaAnalyticsDimension: state.client.flow?.googleAnalyticsDimensionField,
    label: options?.label,
    value: options?.value,
  };
}
