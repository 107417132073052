import { SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE } from 'actions';

export function setIsIssueSpecifierDropdownTooltipVisible(
  isIssueSpecifierDropdownTooltipVisible: boolean,
) {
  return {
    isIssueSpecifierDropdownTooltipVisible,
    type: SET_IS_ISSUE_SPECIFIER_DROPDOWN_TOOLTIP_VISIBLE,
  };
}
