/* eslint-disable */

import { CarouselItemId } from '@homex/se-widget-flow-types';

import { Flow, ServiceType } from 'typings';

export function getDefaultServiceCarouselItemId(
  validServiceTypes: Array<ServiceType>,
  preferredServiceType?: ServiceType,
  flow?: Flow,
) {
  if (flow?.defaultCarouselItemId) {
    return flow.defaultCarouselItemId as CarouselItemId;
  }

  if (validServiceTypes.length > 1) {
    return CarouselItemId.ServiceType;
  }

  const [validServiceType] = validServiceTypes;
  const serviceType = preferredServiceType || validServiceType;

  switch (serviceType) {
    case ServiceType.SALES:
      return CarouselItemId.ContactInfo;
    case ServiceType.REPAIR:
    default:
      return CarouselItemId.IssueSpecifier;
  }
}
