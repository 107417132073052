import { useMutation, useQuery } from '@apollo/client';
import {
  ILoadingIndicatorProps,
  NormalizeGlobalStyles,
} from '@homex/se-react-components';
import { CarouselItemId } from '@homex/se-widget-flow-types';
import { loader } from 'graphql.macro';
import isEqual from 'lodash.isequal';
import { Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components/macro';

import {
  getUiConfig,
  hideAccountModal,
  hideAppointmentModal,
  hideLoginModal,
  hideServiceModal,
  postMessageAppReady,
  postMessageThemeReady,
  resetServiceCarouselForCsr,
  resetServiceCarouselForFlowChange,
  setAcceleratedEntryPoint,
  setAccountOrganizationId,
  setAccountPersonId,
  setAccountPropertyId,
  setAffiliateSourceType,
  setAnalyticsTrackingEnabled,
  setBrandSourceType,
  setBrokerSourceType,
  setCampaignMemberId,
  setCampaignNotificationId,
  setCampaignSourceType,
  setCarouselHistory,
  setClidSourceType,
  setClientReferrer,
  setContactInfoPhoneInputValue,
  setCurrentCarouselItem,
  setCurrentCarouselItemToDefault,
  setCurrentCustomer,
  setCustomField1,
  setCustomField10,
  setCustomField2,
  setCustomField3,
  setCustomField4,
  setCustomField5,
  setCustomField6,
  setCustomField7,
  setCustomField8,
  setCustomField9,
  setCustomerLookupPhoneInputValue,
  setCustomerPhone,
  setEmail,
  setFbclidSourceType,
  setFirstName,
  setGclidSourceType,
  setGoogleAnalyticsTrackingEnabled,
  setIsAuthorizedForRepairs,
  setIsIssueSpecifierDropdownTooltipVisible,
  setIsPreviousCustomer,
  setLastName,
  setLeadSourceSourceType,
  setPreferredServiceType,
  setSchedulePreferenceEnabled,
  setSeActionAppointmentId,
  setSelectedIssue,
  setSelectedRoomIcon,
  setSelectedRoomKey,
  setServiceAddress,
  setServiceCodeId,
  setSubcontractor,
  setThankYouRedirectUrl,
  setUtmCampaignSourceType,
  setUtmContentSourceType,
  setUtmMediumSourceType,
  setUtmSourceSourceType,
  setUtmTermSourceType,
  showAppointmentModal,
  showServiceModal,
  upgradeSession,
} from 'actions';
import { AccountModal } from 'components/AccountModal';
import { AppointmentModal } from 'components/AppointmentModal';
import { LoginModal } from 'components/LoginModal';
import { ModalCloseButton } from 'components/ModalCloseButton';
import { ServiceModal } from 'components/ServiceModal';
import { SessionTokenHandler } from 'components/SessionTokenHandler';
import { StringProvider } from 'components/StringContext';
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer';
import { useMedia } from 'hooks/useMedia';
import { usePrevious } from 'hooks/usePrevious';
import { useSubmitLeadMutation } from 'hooks/useSubmitLeadMutation';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { largeQuery, mediumQuery } from 'styles/breakpoints';
import {
  CarouselReduxReducer,
  IAppProps,
  IScheduleEngineMessageEvent,
  IState,
  Mode,
  RoomKey,
  ViewportSize,
} from 'typings';
import {
  IAbandonLeadMutation,
  IAbandonLeadMutationVariables,
  ICurrentCustomerQuery,
  ICurrentCustomerQueryVariables,
  ServiceType,
} from 'typings/GraphQL';
import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';
import lazyAndWait from 'utils/lazyAndWait';
import { logException } from 'utils/logging';

import { GlobalStyles, LoadingIndicatorStyled } from './styles';

const Account = lazyAndWait(
  () =>
    import('components/Account').then((module) => ({
      default: module.Account,
    })),
  500,
);
const AppointmentCarouselGroup = lazyAndWait(
  () =>
    import('components/AppointmentCarouselGroup').then((module) => ({
      default: module.AppointmentCarouselGroup,
    })),
  500,
);
const Home = lazyAndWait(
  () =>
    import('components/Home').then((module) => ({
      default: module.Home,
    })),
  500,
);
const Login = lazyAndWait(
  () =>
    import('components/Login').then((module) => ({
      default: module.Login,
    })),
  500,
);
const ServiceCarouselGroup = lazyAndWait(
  () =>
    import('components/ServiceCarouselGroup').then((module) => ({
      default: module.ServiceCarouselGroup,
    })),
  500,
);

const abandonLeadMutation = loader('../../queries/abandonLead.graphql');
const currentCustomerChanged = loader(
  '../../queries/currentCustomerChanged.graphql',
);
const currentCustomerQuery = loader('../../queries/currentCustomer.graphql');

const MEDIA_QUERIES = [mediumQuery, largeQuery];
const MEDIA_VALUES: Array<ViewportSize> = ['medium', 'large'];
const DEFAULT_MEDIA_VALUE: ViewportSize = 'small';

export const App = ({ wsClient }: IAppProps) => {
  const dispatch = useThunkDispatch();
  const resetTimeoutIdRef = useRef(0);
  const reconfigureForFlow = useRef(false);

  const {
    flow,
    isServiceModalVisible,
    isStatic,
    isViewWidgetOnly,
    leadId,
    mode,
    theme,
    validOrigins,
  } = useSelector(
    (state: IState) => ({
      flow: state.client.flow,
      isServiceModalVisible: state.modal.isServiceModalVisible,
      isStatic: state.client.isStatic,
      isViewWidgetOnly: state.modal.isViewWidgetOnly,
      leadId: state.userInput.leadId,
      mode: state.client.mode,
      theme: state.client.theme,
      validOrigins: state.client.validOrigins,
    }),
    isEqual,
  );
  const prevIsStatic = usePrevious(isStatic);
  const prevTheme = usePrevious(theme);

  const [abandonLead] = useMutation<
    IAbandonLeadMutation,
    IAbandonLeadMutationVariables
  >(abandonLeadMutation);

  const {
    data: { currentCustomer } = {},
    refetch: refetchCurrentCustomer,
    subscribeToMore,
  } = useQuery<ICurrentCustomerQuery, ICurrentCustomerQueryVariables>(
    currentCustomerQuery,
    // If the user switches accounts, we need to make sure we don't get
    // a cached `customer`, so use `network-only` to avoid using Apollo's
    // cache
    { fetchPolicy: 'network-only' },
  );

  const prevCurrentCustomer = usePrevious(currentCustomer);

  const handleAccountModalClose = () => {
    dispatch(hideAccountModal());
  };

  const handleAppointmentModalClose = () => {
    dispatch(hideAppointmentModal());
  };

  const handleLoginModalClose = () => {
    dispatch(hideLoginModal());
  };

  const handleServiceModalCloseClick = () => {
    if (!isStatic) {
      dispatch(hideServiceModal());
    }

    const resetDelay = parseInt(
      theme?.styles?.transitionDurationCarouselTrack || '',
      10,
    );

    resetTimeoutIdRef.current = window.setTimeout(
      () => {
        if (leadId) {
          abandonLead({
            variables: {
              id: leadId,
            },
          });
        }

        dispatch(resetServiceCarouselForCsr());
      },
      isNaN(resetDelay) ? 600 : resetDelay,
    );
  };

  useMedia(MEDIA_QUERIES, MEDIA_VALUES, DEFAULT_MEDIA_VALUE);

  useSubmitLeadMutation();

  useEffect(() => {
    const handleMessageFromParent = async (
      event: IScheduleEngineMessageEvent,
    ) => {
      if (!validOrigins.includes(event.origin)) {
        return;
      }

      const { options, type } = event.data;

      if (options) {
        const {
          addressId,
          affiliate,
          brand,
          broker,
          campaign,
          campaignMemberId,
          campaignNotificationId,
          clid,
          clientReferrer,
          customField1,
          customField2,
          customField3,
          customField4,
          customField5,
          customField6,
          customField7,
          customField8,
          customField9,
          customField10,
          email,
          expirationDate,
          fbclid,
          firstName,
          gclid,
          isAuthorizedForRepairs,
          isPreviousCustomer,
          lastName,
          leadSource,
          organizationId,
          personId,
          phone,
          propertyId,
          roomKey,
          serviceCodeId,
          skipToCalendar,
          street1,
          street2,
          subcontractor,
          timezone,
          utmCampaign,
          utmContent,
          utmMedium,
          utmSource,
          utmTerm,
          zipCode,
        } = options;

        const { settings: { schedulePreferenceEnabled = false } = {} } =
          theme || {};

        if (roomKey !== undefined) {
          if (roomKey && Object.values(RoomKey).includes(roomKey)) {
            dispatch(setSelectedRoomKey(roomKey));
            dispatch(setIsIssueSpecifierDropdownTooltipVisible(false));
          } else {
            dispatch(setSelectedRoomKey());
            dispatch(setSelectedIssue());
            dispatch(setSelectedRoomIcon());
          }
        }

        if (serviceCodeId !== undefined) {
          dispatch(setServiceCodeId(serviceCodeId || ''));
        }

        if (firstName !== undefined) {
          dispatch(setFirstName(firstName || ''));
        }

        if (lastName !== undefined) {
          dispatch(setLastName(lastName || ''));
        }

        if (email !== undefined) {
          dispatch(setEmail(email || ''));
        }

        if (addressId !== undefined) {
          dispatch(setServiceAddress({ id: addressId || '' }));
        }

        if (street1 !== undefined) {
          dispatch(setServiceAddress({ street1: street1 || '' }));
        }

        if (street2 !== undefined) {
          dispatch(setServiceAddress({ street2: street2 || '' }));
        }

        if (timezone !== undefined) {
          dispatch(setServiceAddress({ timezone: timezone || '' }));
        }

        if (zipCode !== undefined) {
          dispatch(setServiceAddress({ zipCode: zipCode || '' }));
        }

        if (phone !== undefined) {
          dispatch(setContactInfoPhoneInputValue(phone || ''));
          dispatch(setCustomerLookupPhoneInputValue(phone || ''));
          dispatch(setCustomerPhone({ number: phone || '' }));
        }

        if (isAuthorizedForRepairs !== undefined) {
          dispatch(setIsAuthorizedForRepairs(isAuthorizedForRepairs || false));
        }

        if (isPreviousCustomer !== undefined) {
          dispatch(setIsPreviousCustomer(isPreviousCustomer || false));
        }

        if (
          campaignMemberId !== undefined &&
          campaignNotificationId !== undefined
        ) {
          dispatch(setCampaignMemberId(campaignMemberId || ''));
          dispatch(setCampaignNotificationId(campaignNotificationId || ''));
        }

        // set source type analytics properties
        if (affiliate !== undefined) {
          dispatch(setAffiliateSourceType(affiliate || ''));
        }

        if (brand !== undefined) {
          dispatch(setBrandSourceType(brand || ''));
        }

        if (broker !== undefined) {
          dispatch(setBrokerSourceType(broker || ''));
        }

        if (campaign !== undefined) {
          dispatch(setCampaignSourceType(campaign || ''));
        }

        if (clientReferrer !== undefined) {
          dispatch(setClientReferrer(clientReferrer || ''));
        }

        if (customField1 !== undefined) {
          dispatch(setCustomField1(customField1 || ''));
        }

        if (customField2 !== undefined) {
          dispatch(setCustomField2(customField2 || ''));
        }

        if (customField3 !== undefined) {
          dispatch(setCustomField3(customField3 || ''));
        }

        if (customField4 !== undefined) {
          dispatch(setCustomField4(customField4 || ''));
        }

        if (customField5 !== undefined) {
          dispatch(setCustomField5(customField5 || ''));
        }

        if (customField6 !== undefined) {
          dispatch(setCustomField6(customField6 || ''));
        }

        if (customField7 !== undefined) {
          dispatch(setCustomField7(customField7 || ''));
        }

        if (customField8 !== undefined) {
          dispatch(setCustomField8(customField8 || ''));
        }

        if (customField9 !== undefined) {
          dispatch(setCustomField9(customField9 || ''));
        }

        if (customField10 !== undefined) {
          dispatch(setCustomField10(customField10 || ''));
        }

        if (leadSource !== undefined) {
          dispatch(setLeadSourceSourceType(leadSource || ''));
        }

        if (subcontractor !== undefined) {
          dispatch(setSubcontractor(subcontractor || ''));
        }

        // set account tracking properties
        if (organizationId !== undefined) {
          dispatch(setAccountOrganizationId(organizationId || ''));
        }

        if (personId !== undefined) {
          dispatch(setAccountPersonId(personId || ''));
        }

        if (propertyId !== undefined) {
          dispatch(setAccountPropertyId(propertyId || ''));
        }

        if (clid !== undefined) {
          dispatch(setClidSourceType(clid || ''));
        }

        if (fbclid !== undefined) {
          dispatch(setFbclidSourceType(fbclid || ''));
        }

        if (gclid !== undefined) {
          dispatch(setGclidSourceType(gclid || ''));
        }

        if (utmCampaign !== undefined) {
          dispatch(setUtmCampaignSourceType(utmCampaign || ''));
        }

        if (utmContent !== undefined) {
          dispatch(setUtmContentSourceType(utmContent || ''));
        }

        if (utmMedium !== undefined) {
          dispatch(setUtmMediumSourceType(utmMedium || ''));
        }

        if (utmSource !== undefined) {
          dispatch(setUtmSourceSourceType(utmSource || ''));
        }

        if (utmTerm !== undefined) {
          dispatch(setUtmTermSourceType(utmTerm || ''));
        }

        if (skipToCalendar !== undefined) {
          dispatch(setPreferredServiceType(ServiceType.REPAIR));
          dispatch(
            setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE),
          );
          dispatch(
            setCarouselHistory(
              CarouselReduxReducer.SERVICE,
              [
                CarouselItemId.ServiceType,
                CarouselItemId.IssueSpecifier,
                theme?.settings.mediaUploadEnabled
                  ? CarouselItemId.MediaUpload
                  : null,
                theme?.settings.repairCommentsEnabled
                  ? CarouselItemId.RepairComments
                  : null,
                CarouselItemId.CustomerLookup,
                CarouselItemId.RepairContactInfo,
                CarouselItemId.ServiceAreaRepair,
                CarouselItemId.RepairAddress,
              ].filter(isNotNullOrUndefined),
            ),
          );

          const destinationId = schedulePreferenceEnabled
            ? CarouselItemId.SchedulePreference
            : CarouselItemId.ScheduleCalendarRepair;

          dispatch(
            setCurrentCarouselItem(CarouselReduxReducer.SERVICE, destinationId),
          );
          dispatch(setAcceleratedEntryPoint(destinationId));
        }

        // set the id only if the campaign has not expired
        if (expirationDate && new Date(expirationDate) < new Date()) {
          dispatch(
            setCurrentCarouselItem(
              CarouselReduxReducer.SERVICE,
              CarouselItemId.ScheduleMaintenanceExpired,
            ),
          );
        }
      }

      switch (type) {
        case 'se-set-theme-key':
          if (event.data.themeKey) {
            await dispatch(
              getUiConfig(event.data.themeKey, event.data.flowKey),
            );
            dispatch(postMessageThemeReady());
          }

          break;
        case 'se-show-modal':
          if (options?.flowKey) {
            // eslint-disable-next-line no-console
            reconfigureForFlow.current = true;

            await dispatch(getUiConfig(undefined, options?.flowKey));
          }

          dispatch(showServiceModal(!!options?.flowKey));

          break;
        case 'se-show-modal-repair':
          dispatch(setPreferredServiceType(ServiceType.REPAIR));
          dispatch(
            setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE),
          );

          dispatch(showServiceModal());

          break;
        case 'se-show-modal-sales':
          dispatch(setPreferredServiceType(ServiceType.SALES));
          dispatch(
            setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE),
          );
          dispatch(showServiceModal());

          break;

        case 'se-show-modal-appointments': {
          const { appointmentId, userToken } = event.data;

          try {
            if (userToken) {
              await dispatch(upgradeSession(userToken));

              wsClient?.close(false, false);
              await refetchCurrentCustomer();
            }

            if (appointmentId) {
              dispatch(setSeActionAppointmentId(appointmentId));
            }

            dispatch(showAppointmentModal());
          } catch (error) {
            // We might fall into this catch if the user clicked an se_action link
            // but the link was expired. In this case, we hide the appointment
            // appointment modal and silently fail
            dispatch(hideAppointmentModal());
            logException(error);
          }

          break;
        }

        default:
          // Do nothing
          break;
      }
    };

    const subscribeToCustomerChanged = () =>
      subscribeToMore({
        document: currentCustomerChanged,
        updateQuery: (prev, { subscriptionData: { data } }) => {
          if (!data) {
            return prev;
          }

          const { currentCustomer: newCustomer = null } = data || {};

          return {
            ...prev,
            currentCustomer: newCustomer,
          };
        },
      });

    window.addEventListener('message', handleMessageFromParent, false);

    subscribeToCustomerChanged();
    dispatch(postMessageAppReady());

    return () => {
      window.removeEventListener('message', handleMessageFromParent, false);
      window.clearTimeout(resetTimeoutIdRef.current);
    };
  }, [
    dispatch,
    refetchCurrentCustomer,
    subscribeToMore,
    theme,
    validOrigins,
    wsClient,
  ]);

  useEffect(() => {
    if (currentCustomer !== prevCurrentCustomer) {
      if (currentCustomer) {
        dispatch(setCurrentCustomer(currentCustomer));
      } else {
        dispatch(setCurrentCustomer());
      }
    }

    if (theme && prevTheme && theme !== prevTheme) {
      const {
        settings: { schedulePreferenceEnabled, thankYouRedirectUrl },
      } = theme;

      let {
        settings: { analyticsTrackingEnabled, googleAnalyticsTrackingEnabled },
      } = theme;

      if (flow) {
        analyticsTrackingEnabled = flow.analyticsTrackingEnabled;
        googleAnalyticsTrackingEnabled = flow.googleAnalyticsTrackingEnabled;
      }

      // If the theme changes, it is possible that we are missing data that is
      // required to submit the booking request (for example, if schedule
      // preference changes), so we need to make sure the entire app resets.
      if (reconfigureForFlow.current) {
        reconfigureForFlow.current = false;
        dispatch(resetServiceCarouselForFlowChange());
      } else {
        dispatch(resetServiceCarouselForCsr());
      }

      dispatch(setAnalyticsTrackingEnabled(analyticsTrackingEnabled));
      dispatch(
        setGoogleAnalyticsTrackingEnabled(googleAnalyticsTrackingEnabled),
      );
      dispatch(setSchedulePreferenceEnabled(schedulePreferenceEnabled));
      dispatch(setThankYouRedirectUrl(thankYouRedirectUrl));
    }

    if (isStatic && (isStatic !== prevIsStatic || !isServiceModalVisible)) {
      dispatch(showServiceModal());
    }
  }, [
    currentCustomer,
    dispatch,
    flow,
    isServiceModalVisible,
    isStatic,
    prevCurrentCustomer,
    prevIsStatic,
    prevTheme,
    refetchCurrentCustomer,
    theme,
    wsClient,
  ]);

  if (!theme) {
    return null;
  }

  const { assets, settings, strings, styles } = theme;

  const loadingIndicatorProps: ILoadingIndicatorProps = {
    isLoading: true,
    themeValues: {
      spinnerColor: styles.bottomForegroundActionPrimary,
    },
  };

  return (
    <ThemeProvider theme={{ ...assets, ...settings, ...styles }}>
      <StringProvider strings={strings}>
        <NormalizeGlobalStyles />
        <GlobalStyles />
        <ErrorBoundaryContainer>
          <Suspense
            fallback={<LoadingIndicatorStyled {...loadingIndicatorProps} />}
          >
            {mode === Mode.APP && <Home />}
          </Suspense>
        </ErrorBoundaryContainer>
        <SessionTokenHandler
          onTokenChange={() => {
            // If our session token changed at all, we need to restart our subscriptions
            // and refetch the currentCustomer so that subsequent requests have the
            // correct sessionToken
            wsClient?.close(false, false);
            refetchCurrentCustomer();
          }}
        />
        <ServiceModal>
          {!isViewWidgetOnly && (
            <ModalCloseButton onClick={handleServiceModalCloseClick} />
          )}
          <ErrorBoundaryContainer>
            <Suspense
              fallback={<LoadingIndicatorStyled {...loadingIndicatorProps} />}
            >
              <ServiceCarouselGroup />
            </Suspense>
          </ErrorBoundaryContainer>
        </ServiceModal>
        <AccountModal>
          <ModalCloseButton onClick={handleAccountModalClose} />
          <ErrorBoundaryContainer>
            <Suspense
              fallback={<LoadingIndicatorStyled {...loadingIndicatorProps} />}
            >
              <Account />
            </Suspense>
          </ErrorBoundaryContainer>
        </AccountModal>
        <LoginModal>
          <ModalCloseButton onClick={handleLoginModalClose} />
          <ErrorBoundaryContainer>
            <Suspense
              fallback={<LoadingIndicatorStyled {...loadingIndicatorProps} />}
            >
              <Login />
            </Suspense>
          </ErrorBoundaryContainer>
        </LoginModal>
        <AppointmentModal>
          <ModalCloseButton onClick={handleAppointmentModalClose} />
          <ErrorBoundaryContainer>
            <Suspense
              fallback={<LoadingIndicatorStyled {...loadingIndicatorProps} />}
            >
              <AppointmentCarouselGroup />
            </Suspense>
          </ErrorBoundaryContainer>
        </AppointmentModal>
      </StringProvider>
    </ThemeProvider>
  );
};
