import { CarouselNavStepName } from '@homex/se-widget-flow-types';

import { Mode, RoomKey, ServiceType } from 'typings';

export interface ISeAnalyticsEvent {
  eventAction: SeAnalyticsAction;
  eventCategory: SeAnalyticsCategory;
  properties: {
    'Accelerated Entry Point': string | null;
    'Analytics Id': string | null;
    'Analytics Key': string | null;
    'Client Name': string;
    Environment: string | null;
    'Flow Id': string | null;
    'Flow Key': string | null;
    Mode: Mode;
    'PM Campaign Member ID': string | null;
    'PM Campaign Notification ID': string | null;
    'Room Type': RoomKey | null;
    'Service Code ID': string | null;
    Subcontractor: string | null;
    'Widget Flow': ServiceType | null;
    'Widget Page': string;
    'Widget Page Title': string;
    'Widget Step': CarouselNavStepName | null;
    'Zip Code': string | null;
  };
}

export enum SeAnalyticsCategory {
  BOOKING = 'booking',
  CAROUSEL = 'carousel',
}

export enum SeAnalyticsAction {
  CHANGE = 'change',
  CREATE = 'create',
  INTERACTION = 'interaction',
}
