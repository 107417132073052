import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const washerDryerStyles = css`
  bottom: 100px;
  height: 157px;
  left: 37.3%;
  position: absolute;
  width: 75px;

  ${medium`
    height: 171px;
    left: 130px;
    width: 82px;
  `}

  ${large`
    left: 143px;
  `}
`;

export const Fan = styled.div`
  height: 36px;
  position: absolute;
  right: 4.5%;
  top: 18px;
  width: 36px;

  ${medium`
    height: 39px;
    right: 18px;
    top: 17px;
    width: 39px;
  `}

  ${large`
    right: 47px;
    top: 37px;
  `}
`;

export const Light = styled.div`
  height: 54px;
  left: 14%;
  position: absolute;
  top: 0;
  width: 48px;

  ${medium`
    height: 59px;
    left: 49px;
    width: 53px;
  `}

  ${large`
    left: 47px;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 3.4%;
  position: absolute;
  top: 86px;
  width: 14px;

  ${medium`
    height: 25px;
    left: 13px;
    top: 75px;
    width: 15px;
  `}

  ${large`
    left: 11px;
    top: 92px;
  `}
`;

export const Panel = styled.div`
  height: 65px;
  position: absolute;
  right: 21%;
  top: 69px;
  width: 43px;

  ${medium`
    height: 72px;
    right: 75px;
    top: 56px;
    width: 47px;
  `}

  ${large`
    right: 139px;
    top: 84px;
  `}
`;

export const Outlet = styled.div`
  bottom: 128px;
  height: 23px;
  position: absolute;
  right: 25.1%;
  width: 14px;

  ${medium`
    bottom: 128px;
    height: 25px;
    right: 88px;
    width: 15px;
  `}

  ${large`
    right: 152px;
  `}
`;

export const Sink = styled.div`
  bottom: 100px;
  height: 109px;
  left: 9.5%;
  position: absolute;
  width: 75px;

  ${medium`
    bottom: 101px;
    height: 120px;
    left: 34px;
    width: 83px;
  `}

  ${large`
    left: 32px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 21px;
  position: absolute;
  right: 2.5%;
  width: 50px;

  ${medium`
    height: 23px;
    right: 11px;
    width: 54px;
  `}

  ${large`
    right: 40px;
  `}
`;
