import { SET_SELECTED_SCHEDULE_PREFERENCE_DAY } from 'actions';

export function setSelectedSchedulePreferenceDay(
  selectedSchedulePreferenceDay?: number,
) {
  return {
    selectedSchedulePreferenceDay,
    type: SET_SELECTED_SCHEDULE_PREFERENCE_DAY,
  };
}
