import {
  RESET_APP,
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  SET_SELECTED_CALENDAR_DATE,
  SET_SELECTED_CALENDAR_MONTH,
} from 'actions';
import { ICalendarAction, ICalendarState } from 'typings';

const defaultState: ICalendarState = {
  selectedCalendarMonth: null,
};

export default function calendar(
  state: ICalendarState = defaultState,
  action: ICalendarAction,
): ICalendarState {
  switch (action.type) {
    case RESET_APP:
    case RESET_SERVICE_CAROUSEL_FOR_CSR:
    case RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE:
      return defaultState;
    case SET_SELECTED_CALENDAR_DATE:
      return {
        ...state,
        selectedCalendarDate: action.selectedCalendarDate,
      };
    case SET_SELECTED_CALENDAR_MONTH:
      // Allow undefined by not `null`, as `null` is valid and indicates that we
      // are looking at the first month with availability
      if (action.selectedCalendarMonth === undefined) {
        return state;
      }

      return {
        ...state,
        selectedCalendarMonth: action.selectedCalendarMonth,
      };
    default:
      return state;
  }
}
