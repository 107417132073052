import { EffectCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/macro';

import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { IState } from 'typings';

/**
 * Effects for controlling what happens if the theme setting auto_advance_enabled
 * is true. `beforeEffect` is called to set any state that should be set before
 * the transition happens. `effect` is then called after a short delay, and
 * should contain code to make the "auto-advance" transition happen.
 *
 * Note: `beforeEffect` and `effect` should be functions created with
 * `useCallback`, otherwise they will create an infinite loop of rerenders. This
 * is because internally, they are dependencies of a `useEffect` hook.
 *
 * @param shouldAutoAdvance A boolean, or a function that returns a boolean,
 * which indicates whether the user should advance
 * @param beforeEffect Hook, created with useCallback, that should set state
 * before the transition happens.
 * @param effect Hook, created with useCallback, that should make the
 * "auto-advance" transition happen
 *
 * @example
 *
 * useAutoAdvanceEffects(
 *   useCallback(() => {
 *     if (!isContinueDisabled) {
 *       setIsLoading(true);
 *     }
 *   }, [isContinueDisabled]),
 *   useCallback(() => {
 *     if (!isContinueDisabled) {
 *       setIsLoading(false);
 *       handleContinueClick();
 *     }
 *   }, [handleContinueClick, isContinueDisabled]),
 * );
 */
export const useAutoAdvanceEffects = (
  shouldAutoAdvance: boolean | (() => boolean),
  beforeEffect: EffectCallback,
  effect: EffectCallback,
) => {
  const timeoutRef = useRef(0);
  const isMountPhaseRef = useRef(true);
  const beforeEffectCleanupRef = useRef<ReturnType<EffectCallback> | null>(
    null,
  );
  const effectCleanupRef = useRef<ReturnType<EffectCallback> | null>(null);
  const { autoAdvanceEnabled, transitionDurationCarouselTrack } = useTheme();
  const dispatch = useThunkDispatch();

  const { flow } = useSelector((state: IState) => ({
    flow: state.client.flow,
  }));

  const usedAutoAdvanceEnabled = flow
    ? flow.autoAdvanceEnabled
    : autoAdvanceEnabled;

  shouldAutoAdvance =
    typeof shouldAutoAdvance === 'boolean'
      ? shouldAutoAdvance
      : !!(shouldAutoAdvance && shouldAutoAdvance());

  useEffect(() => {
    if (
      usedAutoAdvanceEnabled &&
      !isMountPhaseRef.current &&
      shouldAutoAdvance
    ) {
      const autoAdvanceDelay = parseInt(transitionDurationCarouselTrack, 10);

      if (timeoutRef.current !== 0) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = 0;
      }

      if (effectCleanupRef.current) {
        effectCleanupRef.current();
        effectCleanupRef.current = null;
      }

      if (beforeEffectCleanupRef.current) {
        beforeEffectCleanupRef.current();
        beforeEffectCleanupRef.current = null;
      }

      beforeEffectCleanupRef.current = beforeEffect();

      timeoutRef.current = window.setTimeout(
        () => {
          if (shouldAutoAdvance) {
            effectCleanupRef.current = effect();
          }
        },
        isNaN(autoAdvanceDelay) ? 600 : autoAdvanceDelay,
      );
    }

    isMountPhaseRef.current = false;
  }, [
    usedAutoAdvanceEnabled,
    beforeEffect,
    dispatch,
    effect,
    shouldAutoAdvance,
    transitionDurationCarouselTrack,
  ]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);
};
