import {
  CarouselItemId,
  FlowCarouselItemId,
} from '@homex/se-widget-flow-types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS,
  reportCarouselItemViewed,
} from 'actions';
import { CarouselReduxReducer, IState } from 'typings';

export function setCurrentCarouselItemToPrevious(
  reduxReducer: CarouselReduxReducer,
  flowPreviousItemId?: FlowCarouselItemId,
) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const { history } = getState()[reduxReducer];

    // TODO(CUSTOM): This cast is safe and was done to keep the places calling
    // this action clean. However, we really should update the full codebase to
    // work with the new `FlowCarouselItemId`.
    const previousItemId = flowPreviousItemId as CarouselItemId;

    const previousItemIndex = previousItemId
      ? history.indexOf(previousItemId)
      : history.length > 2 &&
        history[history.length - 2] === CarouselItemId.RepairAddressCorrection
      ? history.length - 3
      : history.length - 2;
    const isPreviousItemInHistory = previousItemIndex > -1;

    if (!isPreviousItemInHistory) {
      return;
    }

    const nextItemId = history[previousItemIndex];

    await dispatch({
      previousItemId: nextItemId,
      previousItemIndex,
      reduxReducer,
      type: SET_CURRENT_CAROUSEL_ITEM_TO_PREVIOUS,
    });
    await dispatch(reportCarouselItemViewed());
  };
}
