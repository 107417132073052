import { SET_SELECTED_SCHEDULE_PREFERENCE_TIME } from 'actions';
import { SchedulePreferenceTime } from 'typings';

export function setSelectedSchedulePreferenceTime(
  selectedSchedulePreferenceTime?: SchedulePreferenceTime,
) {
  return {
    selectedSchedulePreferenceTime,
    type: SET_SELECTED_SCHEDULE_PREFERENCE_TIME,
  };
}
