import { useApolloClient, useQuery } from '@apollo/client';
import {
  ContextBubbleAnimation,
  ContextBubbleBackground,
  ISelectBoxOption,
  SelectBox,
} from '@homex/se-react-components';
import { CarouselItemId } from '@homex/se-widget-flow-types';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/macro';

import {
  addIssue,
  clearIssueStack,
  setBlackoutPeriod,
  setCurrentCarouselItem,
  setIsEmergency,
  setIsIssueSpecifierDropdownTooltipVisible,
  setIsIssueSpecifierListViewTooltipVisible,
  setIsIssueSpecifierListViewVisible,
  setSelectedIssue,
  setSelectedRoomIcon,
  setSelectedRoomKey,
  setServiceCodeId,
} from 'actions';
import { AllRoomIssues } from 'components/AllRoomIssues';
import { CarouselBottomButton } from 'components/CarouselBottomButton/CarouselBottomButton';
import { Phrase } from 'components/Phrase';
import { RoomAttic } from 'components/RoomAttic';
import { RoomBasement } from 'components/RoomBasement';
import { RoomBathroom } from 'components/RoomBathroom';
import { RoomBedroom } from 'components/RoomBedroom';
import { RoomDefault } from 'components/RoomDefault';
import { RoomDefaultCustom } from 'components/RoomDefaultCustom';
import { RoomDining } from 'components/RoomDining';
import { RoomEmergency } from 'components/RoomEmergency';
import { RoomGarage } from 'components/RoomGarage';
import { RoomHallway } from 'components/RoomHallway';
import { RoomKitchen } from 'components/RoomKitchen';
import { RoomLaundry } from 'components/RoomLaundry';
import { RoomList } from 'components/RoomList';
import { RoomLiving } from 'components/RoomLiving';
import { RoomOffice } from 'components/RoomOffice';
import { RoomOutside } from 'components/RoomOutside';
import { useStringContext } from 'components/StringContext';
import { useAutoAdvanceEffects } from 'hooks/useAutoAdvanceEffects';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselReduxReducer,
  IIssue,
  IIssueResponse,
  IRoomsResponse,
  IServiceCodeResponse,
  IState,
  IssueSpecifierCarouselItem,
  RoomKey,
} from 'typings';
import { IQueryRoomsArgs } from 'typings/GraphQL';
import isIssueDetailsItemSkipped from 'utils/isIssueDetailsItemSkipped';
import isIssueImmediatelyRedirected from 'utils/isIssueImmediatelyRedirected';
import { tryGetFlowCarouselItem } from 'utils/tryGetFlowCarouselItem';

import {
  BubbleListView,
  BubbleStartHere,
  Buttons,
  Container,
  EmergencyTooltip,
  EmergencyTooltipHeader,
  EmergencyTooltipOperatingHours,
  FormGroup,
  Gradient,
  OperatingHoursButton,
  OperatingHoursChevron,
  Toggle,
  ToggleImage,
  Toolbar,
} from './styles';

const issueQuery = loader('../../queries/issue.graphql');
const roomsQuery = loader('../../queries/rooms.graphql');
const serviceCodeQuery = loader('../../queries/serviceCode.graphql');

export const CAROUSEL_ITEM_ID = CarouselItemId.IssueSpecifier;

export const CarouselItemIssueSpecifierBottom = () => {
  const { query } = useApolloClient();
  const dispatch = useThunkDispatch();
  const {
    flow,
    isIssueSpecifierDropdownTooltipVisible,
    isIssueSpecifierListViewTooltipVisible,
    isIssueSpecifierListViewVisible,
    selectedIssue,
    selectedRoomIcon,
    selectedRoomKey,
  } = useSelector((state: IState) => ({
    currentCarouselItemId: state.serviceCarousel.currentItemId,
    flow: state.client.flow,
    isIssueSpecifierDropdownTooltipVisible:
      state.userInput.isIssueSpecifierDropdownTooltipVisible,
    isIssueSpecifierListViewTooltipVisible:
      state.userInput.isIssueSpecifierListViewTooltipVisible,
    isIssueSpecifierListViewVisible:
      state.userInput.isIssueSpecifierListViewVisible,
    selectedIssue: state.userInput.selectedIssue,
    selectedRoomIcon: state.userInput.selectedRoomIcon,
    selectedRoomKey: state.userInput.selectedRoomKey,
  }));

  const flowCarouselItem = tryGetFlowCarouselItem<IssueSpecifierCarouselItem>(
    flow,
    CAROUSEL_ITEM_ID,
  );

  const flowId = flow?.id;

  useEffect(() => {
    if (
      isIssueSpecifierDropdownTooltipVisible &&
      flow &&
      flow.navSteps[0] &&
      flow.navSteps[0].carouselItems[0] &&
      flow.navSteps[0]?.carouselItems[0].id !== CAROUSEL_ITEM_ID
    ) {
      setIsIssueSpecifierDropdownTooltipVisible(false);
    }
  }, [flow, isIssueSpecifierDropdownTooltipVisible]);

  const [
    isEmergencyOperatingHoursVisible,
    setIsEmergencyOperatingHoursVisible,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const strings = useStringContext();
  const {
    buttonType,
    customDefaultRoomEnabled,
    mediaUploadEnabled,
    repairCommentsEnabled,
    roomIssueButtonsEnabled,
  } = useTheme();

  const bottomButtonFormat = flow ? flow.buttonType : buttonType;

  const usedCustomDefaultRoomEnabled = flowCarouselItem
    ? flowCarouselItem.options.customDefaultRoomEnabled
    : customDefaultRoomEnabled;

  const { data } = useQuery<IRoomsResponse, IQueryRoomsArgs>(roomsQuery, {
    variables: { flowId },
  });
  const rooms = data?.rooms || [];

  const categoryRoomKeys = [
    RoomKey.COMMERCIAL,
    RoomKey.EMERGENCY,
    RoomKey.GENERAL,
    RoomKey.PREVENTATIVE_MAINTENANCE,
    RoomKey.SALES,
  ];
  const roomKeyValues = Object.values(RoomKey);

  const categoryRooms = rooms.filter(
    ({ key }) => categoryRoomKeys.includes(key) && roomKeyValues.includes(key),
  );
  const locationRooms = rooms.filter(
    ({ key }) => !categoryRoomKeys.includes(key) && roomKeyValues.includes(key),
  );

  const roomOptions: Array<ISelectBoxOption> = [
    ...(categoryRooms.length > 0
      ? [
          {
            key: 'category',
            label: strings['ISSUE_SPECIFIER.SELECT_BOX.CATEGORY'] ?? '',
            value: categoryRooms.map(({ key, name }) => ({
              key,
              label:
                strings[`ISSUE_SPECIFIER.ROOM.${key.toUpperCase()}.NAME`] ||
                name,
              value: key,
            })),
          },
        ]
      : []),
    ...(locationRooms.length > 0
      ? [
          {
            key: 'location',
            label: strings['ISSUE_SPECIFIER.SELECT_BOX.LOCATION'] ?? '',
            value: locationRooms.map(({ key, name }) => ({
              key,
              label:
                strings[`ISSUE_SPECIFIER.ROOM.${key.toUpperCase()}.NAME`] ||
                name,
              value: key,
            })),
          },
        ]
      : []),
  ];

  const isToggleDisabled =
    !selectedRoomKey || categoryRoomKeys.includes(selectedRoomKey);

  const isToggleHidden = locationRooms.every(({ icons }) => icons.length === 0);

  const isContinueDisabled = rooms.reduce((acc, room) => {
    if (room.key === selectedRoomKey) {
      if (selectedRoomIcon && !isIssueSpecifierListViewVisible) {
        return !room.icons.find(({ key }) => key === selectedRoomIcon.key);
      } else if (selectedIssue && isIssueSpecifierListViewVisible) {
        return !room.issues.find(({ key }) => key === selectedIssue.key);
      }
    }

    return acc;
  }, true);

  const isIssueSpecifierListViewTooltipEnabled =
    isIssueSpecifierListViewTooltipVisible &&
    !isIssueSpecifierListViewVisible &&
    !isToggleDisabled;

  const toggleEmergencyOperatingHours = () => {
    setIsEmergencyOperatingHoursVisible(!isEmergencyOperatingHoursVisible);
  };

  const getRoomComponent = () => {
    const room = rooms.find(({ key }) => key === selectedRoomKey);
    const icons = room ? room.icons : [];
    const issues = room ? room.issues : [];

    let component;

    switch (selectedRoomKey) {
      case RoomKey.ATTIC:
        component = <RoomAttic icons={icons} />;

        break;
      case RoomKey.BASEMENT:
        component = <RoomBasement icons={icons} />;

        break;
      case RoomKey.BATHROOM:
        component = <RoomBathroom icons={icons} />;

        break;
      case RoomKey.BEDROOM:
        component = <RoomBedroom icons={icons} />;

        break;
      case RoomKey.DINING:
        component = <RoomDining icons={icons} />;

        break;
      case RoomKey.EMERGENCY:
        return <RoomEmergency icons={icons} />;
      case RoomKey.GARAGE:
        component = <RoomGarage icons={icons} />;

        break;
      case RoomKey.HALL:
        component = <RoomHallway icons={icons} />;

        break;
      case RoomKey.KITCHEN:
        component = <RoomKitchen icons={icons} />;

        break;
      case RoomKey.LAUNDRY:
        component = <RoomLaundry icons={icons} />;

        break;
      case RoomKey.LIVING:
        component = <RoomLiving icons={icons} />;

        break;
      case RoomKey.OFFICE:
        component = <RoomOffice icons={icons} />;

        break;
      case RoomKey.OUTSIDE:
        component = <RoomOutside icons={icons} />;

        break;
      default:
        if (usedCustomDefaultRoomEnabled && !selectedRoomKey) {
          return <RoomDefaultCustom />;
        } else {
          component = <RoomDefault />;
        }

        break;
    }

    if (isIssueSpecifierListViewVisible) {
      return <RoomList issues={issues} />;
    }

    return (
      <>
        {component}
        <Gradient />
      </>
    );
  };

  const resolveIssueId = useCallback(
    async (issueId: string | null) => {
      if (issueId) {
        setIsLoading(true);

        const { data: { issue = null } = {} } = await query<IIssueResponse>({
          query: issueQuery,
          variables: {
            id: issueId,
          },
        });

        setIsLoading(false);

        if (issue) {
          if (isIssueDetailsItemSkipped(issue)) {
            const {
              isEmergency,
              specifier: {
                details: { serviceCodeId },
              },
            } = issue;

            setIsLoading(true);

            const { data: { serviceCode = null } = {} } =
              await query<IServiceCodeResponse>({
                // We never want to use a cached version of this response so that blackoutPeriod is always fresh
                fetchPolicy: 'network-only',
                query: serviceCodeQuery,
                variables: {
                  id: serviceCodeId,
                },
              });

            setIsLoading(false);

            const blackoutPeriod =
              serviceCode?.trade.blackoutPeriod || undefined;

            let destinationItemId;

            if (flowCarouselItem) {
              destinationItemId = flowCarouselItem.options.destinations.next;
            } else {
              destinationItemId = mediaUploadEnabled
                ? CarouselItemId.MediaUpload
                : repairCommentsEnabled
                ? CarouselItemId.RepairComments
                : CarouselItemId.CustomerLookup;
            }

            dispatch(setBlackoutPeriod(blackoutPeriod));
            dispatch(setIsEmergency(isEmergency));
            dispatch(setServiceCodeId(serviceCodeId));
            dispatch(addIssue(issue));
            dispatch(
              setCurrentCarouselItem(
                CarouselReduxReducer.SERVICE,
                destinationItemId,
              ),
            );
          } else if (isIssueImmediatelyRedirected(issue)) {
            dispatch(addIssue(issue));
            resolveIssueId(issue.specifier.details.toIssueId);
          } else {
            dispatch(setBlackoutPeriod());
            dispatch(setIsEmergency(issue.isEmergency));
            dispatch(addIssue(issue));
            /**
             * Flow special case:
             *
             * Right now the `destinations` object has a `refineIssue` field
             * that is typically `ISSUE_DETAILS` to represent the navigation
             * below. However, we see that the item we navigate to is dynamic;
             * with an `issue.id` as a suffix. So for now, even flow-based
             * widget sessions will use the original navigation code.
             */
            dispatch(
              setCurrentCarouselItem(
                CarouselReduxReducer.SERVICE,
                `${CarouselItemId.IssueDetails}-${issue.id}` as CarouselItemId,
              ),
            );
          }
        }
      }
    },
    [
      dispatch,
      flowCarouselItem,
      mediaUploadEnabled,
      query,
      repairCommentsEnabled,
    ],
  );

  const handleContinueClick = useCallback(async () => {
    let isEmergency = false;
    let issue: IIssue | null = null;
    let serviceCodeId: string | null = null;

    if (isIssueSpecifierListViewVisible) {
      if (selectedIssue) {
        isEmergency = selectedIssue.isEmergency || false;
        issue = selectedIssue;
        serviceCodeId = selectedIssue.specifier.details.serviceCodeId || null;
      }
    } else if (selectedRoomIcon) {
      const isEmergencyRoom = selectedRoomKey === RoomKey.EMERGENCY;

      if (selectedRoomIcon.serviceCode) {
        isEmergency = isEmergencyRoom;
        serviceCodeId = selectedRoomIcon.serviceCode.id;
      } else if (selectedRoomIcon.issue) {
        isEmergency = selectedRoomIcon.issue.isEmergency || isEmergencyRoom;
        issue = selectedRoomIcon.issue || null;
        serviceCodeId =
          selectedRoomIcon.issue.specifier.details.serviceCodeId || null;
      }
    }

    if (
      (issue && isIssueDetailsItemSkipped(issue)) ||
      (!issue && serviceCodeId)
    ) {
      // If the issue details item can be skipped, we need to check to see whether we're in a blackout period
      // and if so, route the user to the blackout warning

      setIsLoading(true);

      const { data: { serviceCode = null } = {} } =
        await query<IServiceCodeResponse>({
          // We never want to use a cached version of this response so that blackoutPeriod is always fresh
          fetchPolicy: 'network-only',
          query: serviceCodeQuery,
          variables: {
            id: serviceCodeId,
            skip: false,
          },
        });

      setIsLoading(false);

      const blackoutPeriod = serviceCode?.trade.blackoutPeriod || undefined;

      if (serviceCodeId) {
        dispatch(setBlackoutPeriod(blackoutPeriod));
        dispatch(setIsEmergency(isEmergency));
        dispatch(setServiceCodeId(serviceCodeId));

        if (issue) {
          dispatch(addIssue(issue));
        }

        let destinationItemId;

        if (flowCarouselItem) {
          destinationItemId = flowCarouselItem.options.destinations.next;
        } else {
          destinationItemId = mediaUploadEnabled
            ? CarouselItemId.MediaUpload
            : repairCommentsEnabled
            ? CarouselItemId.RepairComments
            : CarouselItemId.CustomerLookup;
        }

        dispatch(
          setCurrentCarouselItem(
            CarouselReduxReducer.SERVICE,
            destinationItemId,
          ),
        );
      }
    } else if (issue) {
      if (isIssueImmediatelyRedirected(issue)) {
        dispatch(addIssue(issue));
        resolveIssueId(issue.specifier.details.toIssueId);
      } else {
        dispatch(setBlackoutPeriod());
        dispatch(setIsEmergency(isEmergency));
        dispatch(addIssue(issue));
        /**
         * Flow special case:
         *
         * Right now the `destinations` object has a `refineIssue` field
         * that is typically `ISSUE_DETAILS` to represent the navigation
         * below. However, we see that the item we navigate to is dynamic;
         * with an `issue.id` as a suffix. So for now, even flow-based
         * widget sessions will use the original navigation code.
         */
        dispatch(
          setCurrentCarouselItem(
            CarouselReduxReducer.SERVICE,
            `${CarouselItemId.IssueDetails}-${issue.id}` as CarouselItemId,
          ),
        );
      }
    }
  }, [
    dispatch,
    flowCarouselItem,
    isIssueSpecifierListViewVisible,
    query,
    resolveIssueId,
    selectedIssue,
    selectedRoomIcon,
    selectedRoomKey,
    mediaUploadEnabled,
    repairCommentsEnabled,
  ]);

  const handleRoomChange = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch(setSelectedRoomKey(event.currentTarget.value as RoomKey));
    dispatch(setSelectedIssue());
    dispatch(setSelectedRoomIcon());
    dispatch(setIsIssueSpecifierDropdownTooltipVisible(false));
  };

  const handleToggleClick = () => {
    dispatch(
      setIsIssueSpecifierListViewVisible(!isIssueSpecifierListViewVisible),
    );
    dispatch(setIsIssueSpecifierListViewTooltipVisible(false));
  };

  useEffect(() => {
    switch (selectedRoomKey) {
      case RoomKey.COMMERCIAL:
      case RoomKey.SALES:
      case RoomKey.PREVENTATIVE_MAINTENANCE:
      case RoomKey.GENERAL:
        dispatch(setIsIssueSpecifierListViewVisible(true));

        break;
      case RoomKey.EMERGENCY:
      default:
        dispatch(setIsIssueSpecifierListViewVisible(false));

        break;
    }
  }, [dispatch, selectedRoomKey]);

  useEffect(() => {
    dispatch(clearIssueStack());
  }, [dispatch]);

  useAutoAdvanceEffects(
    !isContinueDisabled && isIssueSpecifierListViewVisible,
    useCallback(() => {
      setIsLoading(true);
    }, []),
    useCallback(() => {
      setIsLoading(false);
      handleContinueClick();
    }, [handleContinueClick]),
  );

  return (
    <FormGroup>
      {roomIssueButtonsEnabled ||
      flowCarouselItem?.options.roomIssueButtonsEnabled ? (
        <AllRoomIssues rooms={rooms} />
      ) : (
        <Container>
          <Toolbar>
            <SelectBox
              defaultLabel=""
              label={Phrase({ id: 'ISSUE_SPECIFIER.LOCATION.LABEL' })}
              onChange={handleRoomChange}
              options={roomOptions}
              value={selectedRoomKey || ''}
            />
            {selectedRoomKey === RoomKey.EMERGENCY && (
              <EmergencyTooltip
                animation={ContextBubbleAnimation.FADE_IN}
                background={ContextBubbleBackground.EMERGENCY_HOURS}
                isClosableByX={true}
              >
                <EmergencyTooltipHeader>
                  <Phrase id="ISSUE_SPECIFIER.EMERGENCY.TOOLTIP.HEADER.HTML" />
                </EmergencyTooltipHeader>
                {isEmergencyOperatingHoursVisible && (
                  <EmergencyTooltipOperatingHours>
                    <Phrase id="ISSUE_SPECIFIER.EMERGENCY.TOOLTIP.OPERATING_HOURS.HTML" />
                  </EmergencyTooltipOperatingHours>
                )}
                <OperatingHoursButton onClick={toggleEmergencyOperatingHours}>
                  <OperatingHoursChevron
                    isOpen={isEmergencyOperatingHoursVisible}
                  />
                  <Phrase
                    id={
                      isEmergencyOperatingHoursVisible
                        ? 'ISSUE_SPECIFIER.EMERGENCY.TOOLTIP.OPERATING_HOURS.HIDE'
                        : 'ISSUE_SPECIFIER.EMERGENCY.TOOLTIP.OPERATING_HOURS.VIEW'
                    }
                  />
                </OperatingHoursButton>
              </EmergencyTooltip>
            )}
            {!isToggleHidden && (
              <Toggle
                icon={<ToggleImage />}
                isChecked={isIssueSpecifierListViewVisible}
                isDisabled={isToggleDisabled}
                onClick={handleToggleClick}
              />
            )}
            <BubbleStartHere
              isEnabled={isIssueSpecifierDropdownTooltipVisible}
              pointer={{
                horizontal: 20,
                vertical: 'top',
              }}
            >
              <Phrase id="ISSUE_SPECIFIER.START_HERE.TOOLTIP.HTML" />
            </BubbleStartHere>
            <BubbleListView
              animation={ContextBubbleAnimation.FADE_IN_OUT}
              isEnabled={isIssueSpecifierListViewTooltipEnabled}
              pointer={{
                anchor: 'right',
                horizontal: 20,
                vertical: 'top',
              }}
            >
              <Phrase id="ISSUE_SPECIFIER.LIST_VIEW.TOOLTIP.HTML" />
            </BubbleListView>
          </Toolbar>
          {getRoomComponent()}
        </Container>
      )}
      <Buttons>
        <CarouselBottomButton
          buttonFormat={bottomButtonFormat}
          carouselItemId={CAROUSEL_ITEM_ID}
          isDisabled={isContinueDisabled}
          isLoading={isLoading}
          onContinueClick={handleContinueClick}
        />
      </Buttons>
    </FormGroup>
  );
};
