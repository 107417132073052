import { SET_CONTACT_INFO_CUSTOMER_LOCATIONS } from 'actions';
import { CustomerLocation } from 'typings';

export function setContactInfoCustomerLocations(
  contactInfoCustomerLocations: Array<CustomerLocation>,
  contactInfoAllowLocationsNotInServiceZone: boolean,
) {
  return {
    contactInfoAllowLocationsNotInServiceZone,
    contactInfoCustomerLocations,
    type: SET_CONTACT_INFO_CUSTOMER_LOCATIONS,
  };
}
