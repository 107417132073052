import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  IState,
  SeAnalyticsAction,
  SeAnalyticsCategory,
  ServiceType,
} from 'typings';
import analytics from 'utils/analytics';
import { buildSeAnalyticsEvent } from 'utils/buildSeAnalyticsEvent';

export function reportCarouselItemViewed() {
  return async (
    _dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const state = getState();
    const {
      client: { analyticsTrackingEnabled },
      modal: { isServiceModalVisible },
      userInput: { serviceType },
    } = state;

    if (serviceType !== ServiceType.REPAIR || !isServiceModalVisible) {
      return;
    }

    if (analyticsTrackingEnabled) {
      analytics.send(
        'event',
        buildSeAnalyticsEvent(
          SeAnalyticsCategory.CAROUSEL,
          SeAnalyticsAction.CHANGE,
          state,
        ),
      );
    }
  };
}
