import {
  SET_ACCOUNT_ORGANIZATION_ID,
  SET_ACCOUNT_PERSON_ID,
  SET_ACCOUNT_PROPERTY_ID,
} from 'actions';
import { IAccountTrackingAction, IAccountTrackingState } from 'typings';

const defaultState: IAccountTrackingState = {
  organizationId: '',
  personId: '',
  propertyId: '',
};

export default function accountTracking(
  state: IAccountTrackingState = defaultState,
  action: IAccountTrackingAction,
): IAccountTrackingState {
  switch (action.type) {
    case SET_ACCOUNT_ORGANIZATION_ID:
      return {
        ...state,
        organizationId: action.organizationId,
      };

    case SET_ACCOUNT_PERSON_ID:
      return {
        ...state,
        personId: action.personId,
      };

    case SET_ACCOUNT_PROPERTY_ID:
      return {
        ...state,
        propertyId: action.propertyId,
      };
    default:
      return state;
  }
}
