import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import {
  CHECKIN_DEVICE_FAILURE,
  CHECKIN_DEVICE_REQUEST,
  CHECKIN_DEVICE_SUCCESS,
} from 'actions';
import { IState } from 'typings';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

interface ICheckinDeviceResult extends Action {
  deviceToken?: string;
  error?: unknown;
}

function checkinDeviceFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_DEVICES_CHECKIN_URI,
  });

  return { error, type: CHECKIN_DEVICE_FAILURE };
}

function checkinDeviceRequest() {
  return { type: CHECKIN_DEVICE_REQUEST };
}

function checkinDeviceSuccess(deviceToken: string) {
  return { deviceToken, type: CHECKIN_DEVICE_SUCCESS };
}

export function checkinDevice(
  deviceToken?: string,
): ThunkAction<Promise<ICheckinDeviceResult>, IState, null, Action> {
  return async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(checkinDeviceRequest());

    try {
      const body = { client_key: getState().client.key };
      const options = buildPostOptions({ body, token: deviceToken });
      const response = await fetch(
        `${process.env.REACT_APP_DEVICES_CHECKIN_URI}`,
        options,
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { token } = await response.json();

      return dispatch(checkinDeviceSuccess(token));
    } catch (error) {
      return dispatch(checkinDeviceFailure(error));
    }
  };
}
