import { Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import {
  REGISTER_DEVICE_FAILURE,
  REGISTER_DEVICE_REQUEST,
  REGISTER_DEVICE_SUCCESS,
} from 'actions';
import { IState } from 'typings';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

interface IRegisterDeviceResult extends Action {
  deviceToken?: string;
  error?: unknown;
}

function registerDeviceFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_DEVICES_REGISTER_URI,
  });

  return { error, type: REGISTER_DEVICE_FAILURE };
}

function registerDeviceRequest() {
  return { type: REGISTER_DEVICE_REQUEST };
}

function registerDeviceSuccess(deviceToken: string) {
  return { deviceToken, type: REGISTER_DEVICE_SUCCESS };
}

export function registerDevice(): ThunkAction<
  Promise<IRegisterDeviceResult>,
  IState,
  null,
  Action
> {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    dispatch(registerDeviceRequest());

    try {
      const { key: clientKey, platform } = getState().client;
      const body = {
        client_key: clientKey,
        type: platform,
      };
      const options = buildPostOptions({ body });
      const response = await fetch(
        `${process.env.REACT_APP_DEVICES_REGISTER_URI}`,
        options,
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { token } = await response.json();

      return dispatch(registerDeviceSuccess(token));
    } catch (error) {
      return dispatch(registerDeviceFailure(error));
    }
  };
}
