import { CarouselNavStepName } from '@homex/se-widget-flow-types';

import {
  ISeAnalyticsEvent,
  IState,
  SeAnalyticsAction,
  SeAnalyticsCategory,
} from 'typings';
import formatCarouselItemId from 'utils/formatCarouselItemId';
import getCarouselStepName from 'utils/getCarouselStepName';

export function buildSeAnalyticsEvent(
  eventCategory: SeAnalyticsCategory,
  eventAction: SeAnalyticsAction,
  state: IState,
  options?: { widgetOpened?: boolean },
): ISeAnalyticsEvent {
  const {
    client: { flow, key, mode },
    pmTracking: { campaignMemberId, campaignNotificationId },
    serviceCarousel: { acceleratedEntryPoint, currentItemId },
    sourceTrackingAnalytics: { subcontractor },
    userInput: {
      address: { zipCode },
      selectedRoomKey,
      serviceCodeId,
      serviceType,
    },
  } = state;

  const acceleratedEntryPointStepName =
    acceleratedEntryPoint &&
    getCarouselStepName(acceleratedEntryPoint, serviceType, flow);

  return {
    eventAction,
    eventCategory,
    properties: {
      'Accelerated Entry Point': acceleratedEntryPointStepName || null,
      'Analytics Id': flow?.analyticsId || null,
      'Analytics Key': flow?.key || null,
      'Client Name': key,
      Environment:
        process.env.REACT_APP_AWS_ACCOUNT_KEY || process.env.NODE_ENV,
      'Flow Id': flow?.id || null,
      'Flow Key': flow?.key || null,
      Mode: mode,
      'PM Campaign Member ID': campaignMemberId || null,
      'PM Campaign Notification ID': campaignNotificationId || null,
      'Room Type': selectedRoomKey || null,
      'Service Code ID': serviceCodeId || null,
      Subcontractor: subcontractor || null,
      'Widget Flow': serviceType || null,
      'Widget Page': currentItemId,
      'Widget Page Title': formatCarouselItemId(currentItemId),
      'Widget Step': options?.widgetOpened
        ? CarouselNavStepName.Opened
        : getCarouselStepName(currentItemId, serviceType, flow) || null,
      'Zip Code': zipCode || null,
    },
  };
}
