import { RoomKey } from 'typings';

interface IScheduleEngineMessageOptions {
  addressId?: string | null;
  affiliate?: string | null;
  brand?: string | null;
  broker?: string | null;
  campaign?: string | null;
  campaignMemberId?: string | null;
  campaignNotificationId?: string | null;
  clid?: string | null;
  clientReferrer?: string | null;
  customField1?: string | null;
  customField10?: string | null;
  customField2?: string | null;
  customField3?: string | null;
  customField4?: string | null;
  customField5?: string | null;
  customField6?: string | null;
  customField7?: string | null;
  customField8?: string | null;
  customField9?: string | null;
  email?: string | null;
  expirationDate?: string | null;
  fbclid?: string | null;
  firstName?: string | null;
  flowKey?: string | null;
  gclid?: string | null;
  isAuthorizedForRepairs?: boolean | null;
  isPreviousCustomer?: boolean | null;
  lastName?: string | null;
  leadSource?: string | null;
  organizationId?: string | null;
  personId?: string | null;
  phone?: string | null;
  propertyId?: string | null;
  roomKey?: RoomKey | null;
  rwgToken?: string | null;
  serviceCodeId?: string | null;
  skipToCalendar?: boolean | null;
  street1?: string | null;
  street2?: string | null;
  subcontractor?: string | null;
  timezone?: string | null;
  utmCampaign?: string | null;
  utmContent?: string | null;
  utmMedium?: string | null;
  utmSource?: string | null;
  utmTerm?: string | null;
  zipCode?: string | null;
}

export interface IScheduleEngineMessage {
  [key: string]: any;
  analyticsKey?: string;
  eventName?: SeEmbedEvent;
  options?: IScheduleEngineMessageOptions;
  type: ScheduleEngineMessageType;
}

export interface IScheduleEngineMessageEvent extends MessageEvent {
  readonly data: IScheduleEngineMessage;
}

export type ScheduleEngineMessageType =
  | 'se-app-ready'
  | 'se-hide-modal'
  | 'se-theme-ready'
  | 'se-show-modal'
  | 'se-show-modal-repair'
  | 'se-show-modal-sales'
  | 'se-show-modal-appointments'
  | 'se-send-client-google-analytics'
  | 'se-set-theme-key'
  | 'se-preview-ready'
  | 'se-event';

export enum SeEmbedEvent {
  /**  Should fire when the booking is completed */
  BOOKING_BOOKED = 'BookingBooked',
  /** Should fire when we have all the information of a customer (name, contact, and location) */
  BOOKING_CUSTOMER = 'BookingCustomer',
  /** Should fire after the view that asks the customer if it is an existing customer */
  BOOKING_CUSTOMER_QUESTION = 'BookingCustomerQuestion',
  /** Should fire after the upload media view */
  BOOKING_DETAILS_MEDIA = 'BookingDetailsMedia',
  /** Should fire after the details view */
  BOOKING_DETAILS_MORE = 'BookingDetailsMore',
  /** Should fire after the issue and following details have been selected */
  BOOKING_ISSUE_COMPLETED = 'BookingIssueCompleted',
  /** Should fire when the issue "selector" opens */
  BOOKING_ISSUE_STARTED = 'BookingIssueStarted',
  /** Should fire on the first widget interaction */
  BOOKING_PROCESS_STARTED = 'BookingProcessStarted',
  /** Should fire after the customer picks a day and time slot in the calendar */
  BOOKING_SCHEDULE = 'BookingSchedule',
  /** Should fire when the widget opens */
  BOOKING_STARTED = 'BookingStarted',
}
