/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import { CarouselItemId } from '@homex/se-widget-flow-types';
import { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';

import { postMessagePreviewReady } from 'actions/postMessagePreviewReady';
import { Carousel } from 'components/Carousel';
import { CarouselItem } from 'components/CarouselItem';
import { CarouselItemIssueSpecifierBottom } from 'components/CarouselItemIssueSpecifierBottom';
import { CarouselItemTop } from 'components/CarouselItemTop';
import { CarouselNav } from 'components/CarouselNav';
import { StringProvider } from 'components/StringContext';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import {
  CarouselBackgroundStyle,
  CarouselItemRefMap,
  CarouselPosition,
  CarouselReduxReducer,
  IThemeFromUiConfig,
} from 'typings';

export const Preview = () => {
  const [theme, setTheme] = useState<IThemeFromUiConfig>();
  const dispatch = useThunkDispatch();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    console.log('Widget Preview Component mounted');
  }, []);

  useEffect(() => {
    const handleMessageFromParent = async (event: any) => {
      const { options, type } = event.data;

      switch (type) {
        case 'se-load-config':
          console.log('Initial Config loaded');
          console.log(options);
          const { theme: messageTheme = null } = options.config;

          setTheme(messageTheme);

          break;

        case 'se-preview-carousel-item':
          console.log('Preview Carousel Item');

          break;
        default:
          // Do nothing
          break;
      }
    };

    window.addEventListener('message', handleMessageFromParent, false);

    if (!theme && isMounted.current) {
      dispatch(postMessagePreviewReady());
      console.log('Preview Ready Sent');
    }

    return () =>
      window.removeEventListener('message', handleMessageFromParent, false);
  }, [dispatch, theme]);

  const carouselItemRefsBottom = useRef<CarouselItemRefMap>({});
  const carouselItemRefsTop = useRef<CarouselItemRefMap>({});
  const carouselBottomRef = useRef<HTMLDivElement>(null);

  if (!theme) {
    return <div>No Theme provided</div>;
  }

  const { assets, settings, strings, styles } = theme;

  return (
    <ThemeProvider theme={{ ...assets, ...settings, ...styles }}>
      <StringProvider strings={strings}>
        <div>
          <Carousel
            backgroundStyle={CarouselBackgroundStyle.GRADIENT_EXPANDED}
            carouselItemRefs={carouselItemRefsTop}
            position={CarouselPosition.TOP}
            reduxReducer={CarouselReduxReducer.SERVICE}
          >
            <CarouselItem
              id={IssueSpec.id}
              index={0}
              key={`${IssueSpec.id}-Top`}
              reduxReducer={CarouselReduxReducer.SERVICE}
              ref={(ref) => (carouselItemRefsTop.current[IssueSpec.id] = ref)}
            >
              {IssueSpec.componentTop}
            </CarouselItem>
          </Carousel>
          <CarouselNav carouselBottomRef={carouselBottomRef} />
          <Carousel
            backgroundStyle={CarouselBackgroundStyle.SOLID}
            carouselItemRefs={carouselItemRefsBottom}
            position={CarouselPosition.BOTTOM}
            reduxReducer={CarouselReduxReducer.SERVICE}
            ref={carouselBottomRef}
          >
            <CarouselItem
              id={IssueSpec.id}
              index={0}
              key={`${IssueSpec.id}-Bottom`}
              reduxReducer={CarouselReduxReducer.SERVICE}
              ref={(ref) =>
                (carouselItemRefsBottom.current[IssueSpec.id] = ref)
              }
            >
              {IssueSpec.componentBottom}
            </CarouselItem>
          </Carousel>
        </div>
      </StringProvider>
    </ThemeProvider>
  );
};

const IssueSpec = {
  componentBottom: <CarouselItemIssueSpecifierBottom />,
  componentTop: <CarouselItemTop headerPhraseId="ISSUE_SPECIFIER.HEADER" />,
  id: CarouselItemId.IssueSpecifier,
};
