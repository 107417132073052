import {
  SET_AFFILIATE_SOURCE_TYPE,
  SET_BRAND_SOURCE_TYPE,
  SET_BROKER_SOURCE_TYPE,
  SET_CAMPAIGN_SOURCE_TYPE,
  SET_CLID_SOURCE_TYPE,
  SET_CLIENT_REFERRER,
  SET_CUSTOM_FIELD_1,
  SET_CUSTOM_FIELD_10,
  SET_CUSTOM_FIELD_2,
  SET_CUSTOM_FIELD_3,
  SET_CUSTOM_FIELD_4,
  SET_CUSTOM_FIELD_5,
  SET_CUSTOM_FIELD_6,
  SET_CUSTOM_FIELD_7,
  SET_CUSTOM_FIELD_8,
  SET_CUSTOM_FIELD_9,
  SET_FBCLID_SOURCE_TYPE,
  SET_GCLID_SOURCE_TYPE,
  SET_LEAD_SOURCE_SOURCE_TYPE,
  SET_RWG_TOKEN_SOURCE_TYPE,
  SET_SUBCONTRACTOR,
  SET_UTM_CAMPAIGN_SOURCE_TYPE,
  SET_UTM_CONTENT_SOURCE_TYPE,
  SET_UTM_MEDIUM_SOURCE_TYPE,
  SET_UTM_SOURCE_SOURCE_TYPE,
  SET_UTM_TERM_SOURCE_TYPE,
} from 'actions';
import {
  ISourceTrackingAnalyticsAction,
  ISourceTrackingAnalyticsState,
} from 'typings';

const defaultState: ISourceTrackingAnalyticsState = {
  affiliate: '',
  brand: '',
  broker: '',
  campaign: '',
  clid: '',
  clientReferrer: '',
  customField1: '',
  customField10: '',
  customField2: '',
  customField3: '',
  customField4: '',
  customField5: '',
  customField6: '',
  customField7: '',
  customField8: '',
  customField9: '',
  fbclid: '',
  gclid: '',
  leadSource: '',
  rwgToken: '',
  subcontractor: '',
  utmCampaign: '',
  utmContent: '',
  utmMedium: '',
  utmSource: '',
  utmTerm: '',
};

export default function sourceTrackingAnalytics(
  state: ISourceTrackingAnalyticsState = defaultState,
  action: ISourceTrackingAnalyticsAction,
): ISourceTrackingAnalyticsState {
  switch (action.type) {
    case SET_AFFILIATE_SOURCE_TYPE:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        affiliate: action.sourceType,
      };

    case SET_BRAND_SOURCE_TYPE:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        brand: action.sourceType,
      };

    case SET_BROKER_SOURCE_TYPE:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        broker: action.sourceType,
      };

    case SET_CAMPAIGN_SOURCE_TYPE:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        campaign: action.sourceType,
      };

    case SET_CLID_SOURCE_TYPE:
      if (!action.clid) {
        return state;
      }

      return {
        ...state,
        clid: action.clid,
      };

    case SET_CLIENT_REFERRER:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        clientReferrer: action.sourceType,
      };

    case SET_CUSTOM_FIELD_1:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField1: action.sourceType,
      };

    case SET_CUSTOM_FIELD_2:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField2: action.sourceType,
      };

    case SET_CUSTOM_FIELD_3:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField3: action.sourceType,
      };

    case SET_CUSTOM_FIELD_4:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField4: action.sourceType,
      };

    case SET_CUSTOM_FIELD_5:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField5: action.sourceType,
      };

    case SET_CUSTOM_FIELD_6:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField6: action.sourceType,
      };

    case SET_CUSTOM_FIELD_7:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField7: action.sourceType,
      };

    case SET_CUSTOM_FIELD_8:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField8: action.sourceType,
      };

    case SET_CUSTOM_FIELD_9:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField9: action.sourceType,
      };

    case SET_CUSTOM_FIELD_10:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        customField10: action.sourceType,
      };

    case SET_FBCLID_SOURCE_TYPE:
      if (!action.fbclid) {
        return state;
      }

      return {
        ...state,
        fbclid: action.fbclid,
      };

    case SET_GCLID_SOURCE_TYPE:
      if (!action.gclid) {
        return state;
      }

      return {
        ...state,
        gclid: action.gclid,
      };

    case SET_LEAD_SOURCE_SOURCE_TYPE:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        leadSource: action.sourceType,
      };

    case SET_SUBCONTRACTOR:
      if (!action.sourceType) {
        return state;
      }

      return {
        ...state,
        subcontractor: action.sourceType,
      };

    case SET_UTM_CAMPAIGN_SOURCE_TYPE:
      if (!action.utmCampaign) {
        return state;
      }

      return {
        ...state,
        utmCampaign: action.utmCampaign,
      };
    case SET_UTM_CONTENT_SOURCE_TYPE:
      if (!action.utmContent) {
        return state;
      }

      return {
        ...state,
        utmContent: action.utmContent,
      };
    case SET_UTM_MEDIUM_SOURCE_TYPE:
      if (!action.utmMedium) {
        return state;
      }

      return {
        ...state,
        utmMedium: action.utmMedium,
      };
    case SET_UTM_SOURCE_SOURCE_TYPE:
      if (!action.utmSource) {
        return state;
      }

      return {
        ...state,
        utmSource: action.utmSource,
      };
    case SET_UTM_TERM_SOURCE_TYPE:
      if (!action.utmTerm) {
        return state;
      }

      return {
        ...state,
        utmTerm: action.utmTerm,
      };
    case SET_RWG_TOKEN_SOURCE_TYPE:
      if (!action.rwgToken) {
        return state;
      }

      return {
        ...state,
        rwgToken: action.rwgToken,
      };

    default:
      return state;
  }
}
