import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  UPGRADE_SESSION_FAILURE,
  UPGRADE_SESSION_REQUEST,
  UPGRADE_SESSION_SUCCESS,
} from 'actions';
import { IState } from 'typings';
import { tryLocalStorageGetItem } from 'utils/localStorage';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

function upgradeSessionFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_SESSIONS_UPGRADE_URI,
  });

  return { error, type: UPGRADE_SESSION_FAILURE };
}

function upgradeSessionRequest() {
  return { type: UPGRADE_SESSION_REQUEST };
}

function upgradeSessionSuccess(sessionToken: string) {
  return { sessionToken, type: UPGRADE_SESSION_SUCCESS };
}

export function upgradeSession(userTokenParam?: string) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const {
      auth: { sessionToken: initialSessionToken },
    } = getState();

    const userTokenFromStorage = tryLocalStorageGetItem('userToken');

    dispatch(upgradeSessionRequest());

    try {
      const userToken = userTokenParam || userTokenFromStorage;
      const url = `${process.env.REACT_APP_SESSIONS_UPGRADE_URI}`;
      const options = buildPostOptions({
        body: { user_token: userToken },
        token: initialSessionToken,
      });
      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { token } = await response.json();
      dispatch(upgradeSessionSuccess(token));

      return { sessionToken: token };
    } catch (error) {
      dispatch(upgradeSessionFailure(error));
      throw error;
    }
  };
}
