export function isIosVersion13() {
  const match = navigator.appVersion.match(/iP(hone|od|ad) OS (\d+)/);

  if (match !== undefined && match !== null) {
    const version = parseInt(match[2] ?? '', 10);

    return version === 13;
  }

  return false;
}
