import { SET_ISSUE_OPTION_ANSWER } from 'actions';
import { IIssueOptionAnswer } from 'typings';

export function setIssueOptionAnswer(
  issueId: string,
  questionIndex: number,
  optionAnswer?: IIssueOptionAnswer,
) {
  return {
    issueId,
    optionAnswer,
    questionIndex,
    type: SET_ISSUE_OPTION_ANSWER,
  };
}
