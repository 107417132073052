import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const diningSetStyles = css`
  bottom: 100px;
  height: 130px;
  left: 50%;
  margin-left: -96px;
  position: absolute;
  width: 172px;

  ${medium`
    height: 142px;
    left: 67px;
    margin-left: 0;
    width: 188px;
  `}

  ${large`
    left: 112px;
  `}
`;

export const Light = styled.div`
  height: 52px;
  left: 50%;
  margin-left: -49px;
  position: absolute;
  top: 0;
  width: 98px;

  ${medium`
    height: 58px;
    margin-left: -54px;
    width: 108px;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 4%;
  position: absolute;
  top: 77px;
  width: 13px;

  ${medium`
    height: 25px;
    left: 17px;
    top: 64px;
    width: 15px;
  `}

  ${large`
    left: 44px;
    top: 92px;
  `}
`;

export const Outlet = styled.div`
  bottom: 130px;
  height: 23px;
  left: 4%;
  position: absolute;
  width: 13px;

  ${medium`
    bottom: 134px;
    height: 25px;
    left: 17px;
    width: 15px;
  `}

  ${large`
    left: 44px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 21px;
  position: absolute;
  right: 3.7%;
  width: 49px;

  ${medium`
    height: 23px;
    right: 11px;
    width: 54px;
  `}

  ${large`
    right: 40px;
  `}
`;

export const Thermostat = styled.div`
  height: 26px;
  position: absolute;
  right: 5.3%;
  top: 77px;
  width: 39px;

  ${medium`
    height: 28px;
    right: 16px;
    top: 64px;
    width: 43px;
  `}

  ${large`
    right: 45px;
    top: 92px;
  `}
`;
