import { FlowCarouselItemId } from '@homex/se-widget-flow-types';

import { CarouselItemBase, Flow } from 'typings';

export function tryGetFlowCarouselItem<TCarouselItem extends CarouselItemBase>(
  flow: Flow | undefined,
  carouselItemId: FlowCarouselItemId,
): TCarouselItem | undefined {
  if (!flow) {
    return undefined;
  }

  const carouselItem = flow.navSteps
    .map((ns) => ns.carouselItems)
    .flat()
    .find((ci) => ci.id === carouselItemId) as TCarouselItem | undefined;

  if (!carouselItem) {
    throw new Error(
      `Unable to find carousel item '${carouselItemId}' in flow '${flow.id}'`,
    );
  }

  return carouselItem;
}
