import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

const roofStyles = css`
  height: 78px;
  position: absolute;
  top: 0;
  width: 101px;

  ${medium`
    height: 85px;
    width: 111px;
  `}
`;

export const roofStylesLeft = css`
  ${roofStyles}

  left: 0;
`;

export const roofStylesRight = css`
  ${roofStyles}

  right: 0;
  transform: scaleX(-1);
`;

export const Fan = styled.div`
  height: 36px;
  left: 27.5%;
  position: absolute;
  top: 53px;
  width: 36px;

  ${medium`
    height: 39px;
    left: 95px;
    top: 39px;
    width: 39px;
  `}

  ${large`
    left: 114px;
    top: 37px;
  `}
`;

export const Light = styled.div`
  height: 54px;
  left: 50%;
  margin-left: -24px;
  position: absolute;
  top: 0;
  width: 48px;

  ${medium`
    height: 59px;
    margin-left: -26.5px;
    width: 53px;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  position: absolute;
  right: 10%;
  top: 92px;
  width: 14px;

  ${medium`
    height: 25px;
    right: 36px;
    top: 75px;
    width: 15px;
  `}

  ${large`
    right: 73px;
    top: 92px;
  `}
`;

export const Panel = styled.div`
  height: 66px;
  position: absolute;
  right: 26.7%;
  top: 64px;
  width: 43px;

  ${medium`
    height: 72px;
    right: 93px;
    top: 51px;
    width: 47px;
  `}

  ${large`
    right: 142px;
    top: 70px;
  `}
`;

export const Outlet = styled.div`
  height: 23px;
  position: absolute;
  right: 31%;
  top: 158px;
  width: 14px;

  ${medium`
    height: 25px;
    right: 109px;
    top: 154px;
    width: 15px;
  `}

  ${large`
    right: 158px;
    top: 175px;
  `}
`;

export const AirHandler = styled.div`
  bottom: 101px;
  height: 74px;
  left: 4%;
  position: absolute;
  width: 141px;

  ${medium`
    height: 81px;
    left: 14px;
    width: 155px;
  `}

  ${large`
    left: 33px;
  `}
`;
