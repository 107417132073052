import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { SHOW_APPOINTMENT_MODAL, hideAppointmentModal } from 'actions';
import { IState } from 'typings';
import { getAppointmentsFromCustomer } from 'utils/appointments';

export function showAppointmentModal() {
  return (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const { currentCustomer, seActionAppointmentId } = getState().userInput;

    if (currentCustomer && currentCustomer.locations) {
      const appointments = getAppointmentsFromCustomer(currentCustomer);

      if (appointments.length > 0 || seActionAppointmentId) {
        // We never want to show the appointment modal if there are no appointments
        dispatch({ type: SHOW_APPOINTMENT_MODAL });
      } else {
        dispatch(hideAppointmentModal());
      }
    }
  };
}
