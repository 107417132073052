import { ContextBubble } from '@homex/se-react-components';
import styled, { css, keyframes } from 'styled-components/macro';

import { CarouselBottomButtonArrowSvgWithProps } from 'components/CarouselBottomButtonArrowSvg';
import { large } from 'styles/breakpoints';
import { eyebrow } from 'styles/typography';
import {
  CarouselBottomButtonType,
  ISvgContainerProps,
  IWithThemeProps,
} from 'typings';

interface IButtonProps {
  buttonType: CarouselBottomButtonType;
  isLoading: boolean;
}
export interface ILabelContainerProps {
  isLoading: boolean;
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
`;

export const SvgContainer = styled.div<ISvgContainerProps>`
  display: flex;
  height: ${({ height }) => height}px;
  transform: translateX(0);

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundActionPrimary};
    transition: transform ${theme.transitionDurationPrimary} ease;
  `}

  ${({ buttonType, isLoading }) => {
    if (isLoading) {
      return `
        transform: translateX(47px);
      `;
    } else if (buttonType === CarouselBottomButtonType.BACK) {
      return `
        transform: rotate(180deg);
      `;
    }

    return '';
  }}
`;

export const Svg = styled(CarouselBottomButtonArrowSvgWithProps)`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;

  ${({ theme }: IWithThemeProps) => `
    transition: opacity ${theme.transitionDurationPrimary} ease;

    [data-svg-class='CarouselContinueButton-circle'] {
      opacity: 0;
      transition: opacity ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='CarouselContinueButton-arrow-top'],
    [data-svg-class='CarouselContinueButton-arrow-bottom'] {
      stroke-dasharray: 34;
      stroke-dashoffset: 52;
      transform-origin: 40px center;
      transition-property: stroke-dashoffset, transform;
      transition-duration: ${theme.transitionDurationPrimary};
      transition-timing-function: ease;
    }

    [data-svg-class='CarouselContinueButton-arrow-middle'] {
      opacity: 1;
      transition: opacity ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='CarouselContinueButton-arrow-stroke'] {
      stroke: ${theme.bottomForegroundActionPrimary};
      transition-property: stroke, transform;
      transition-duration: ${theme.transitionDurationPrimary};
      transition-timing-function: ease;
    }

    [data-svg-class='CarouselContinueButton-arc-light'] {
      stroke: ${theme.bottomForegroundActionPrimary};
      transition: stroke ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='CarouselContinueButton-arc-dark'] {
      stroke: ${theme.bottomForegroundActionPrimary};
      transition: stroke ${theme.transitionDurationPrimary} ease;
    }
  `}

  ${({ buttonType }) => {
    if (buttonType === CarouselBottomButtonType.CLOSE) {
      return css`
        [data-svg-class='CarouselContinueButton-arrow-top'] {
          transform: rotate(5deg);
        }
        [data-svg-class='CarouselContinueButton-arrow-bottom'] {
          transform: rotate(-5deg);
        }
        [data-svg-class='CarouselContinueButton-arrow-top'],
        [data-svg-class='CarouselContinueButton-arrow-bottom'] {
          stroke-dashoffset: 69;
        }

        [data-svg-class='CarouselContinueButton-arrow-middle'] {
          opacity: 0;
        }

        [data-svg-class='CarouselContinueButton-arrow-stroke'] {
          transform: translateX(-13px);
        }
      `;
    }

    return '';
  }}

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: ${spin};
        animation-timing-function: linear;

        [data-svg-class='CarouselContinueButton-arrow-stroke'] {
          stroke: transparent;
        }

        [data-svg-class='CarouselContinueButton-arc-light'] {
          stroke: #dfdfdf;
        }
      `;
    }

    return '';
  }}
`;

export const Label = styled.span<{ isVisible: boolean }>`
  transition-property: opacity, visibility;
  transition-timing-function: ease, step-end;

  ${({ theme }: IWithThemeProps) => css`
    color: ${theme.bottomForegroundActionPrimary};
    transition-duration: ${theme.transitionDurationPrimary};
  `}

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          transition-timing-function: ease, step-start;
          visibility: visible;
        `
      : css`
          opacity: 0;
          position: absolute;
          visibility: hidden;
        `}
`;

export const LabelContainer = styled.span<ILabelContainerProps>`
  ${eyebrow}

  padding: 21px 0 21px 11px;
  position: relative;

  ${({ theme }: IWithThemeProps) => css`
    transition: opacity ${theme.transitionDurationPrimary} ease;
  `}

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        ${Label} {
          color: transparent;
          opacity: 0;
        }
      `;
    }

    return '';
  }}
`;

export const Button = styled.button<IButtonProps>`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  outline: none;
  padding: 24px;
  position: relative;
  transition-property: opacity, transform;
  transition-timing-function: ease;

  ${({ theme }: IWithThemeProps) => css`
    transition-duration: ${theme.transitionDurationPrimary};

    [data-svg-class='CarouselContinueButton-circle'] {
      fill: ${theme.bottomForegroundActionPrimary};
    }

    &:hover,
    &:focus {
      ${Svg} {
        [data-svg-class='CarouselContinueButton-circle'] {
          opacity: 1;
        }

        [data-svg-class='CarouselContinueButton-arrow-stroke'] {
          stroke: ${theme.bottomForegroundActionSecondary};
        }
      }
    }
  `}

  ${({ isLoading, theme }) => css`
    &:disabled {
      cursor: default;

      ${Svg} {
        opacity: ${isLoading ? 1 : 0.3};

        [data-svg-class='CarouselContinueButton-circle'] {
          opacity: 0;
        }

        [data-svg-class='CarouselContinueButton-arrow-stroke'] {
          stroke: ${isLoading
            ? 'transparent'
            : theme.bottomForegroundActionPrimary};
        }
      }

      ${LabelContainer} {
        opacity: 0.3;
      }
    }
  `}

    ${({ buttonType }) => {
    if (buttonType === CarouselBottomButtonType.CLOSE) {
      return css`
        transform: translateX(16px);
      `;
    }

    return '';
  }}
`;

export const ContextBubbleStyled = styled(ContextBubble)`
  bottom: 92px;
  left: 50%;
  margin-left: -155px;
  width: 310px;
  z-index: 2;

  ${large`
    left: 61px;
  `}
`;
