import 'core-js/es/array/fill';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/array/iterator';
import 'core-js/es/object/values';
import 'core-js/es/symbol';
import 'react-app-polyfill/ie11';
import 'styles/fonts.css';

import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  checkinDevice,
  getUiConfig,
  registerDevice,
  setAffiliateSourceType,
  setAnalyticsTrackingEnabled,
  setBrandSourceType,
  setCampaignSourceType,
  setClientKey,
  setClientReferrer,
  setCustomField1,
  setCustomField10,
  setCustomField2,
  setCustomField3,
  setCustomField4,
  setCustomField5,
  setCustomField6,
  setCustomField7,
  setCustomField8,
  setCustomField9,
  setGoogleAnalyticsTrackingEnabled,
  setIsStatic,
  setLeadSourceSourceType,
  setMode,
  setPlatform,
  setRwgTokenSourceType,
  setSchedulePreferenceEnabled,
  setSelectedRoomKey,
  setSubcontractor,
  setThankYouRedirectUrl,
  setViewWidgetOnly,
  startSession,
} from 'actions';
import { App } from 'components/App';
import { Preview } from 'components/Preview';
import { IState, Mode, Platform, QueryStringKey, RoomKey } from 'typings';
import { init as initAnalytics } from 'utils/analytics';
import configureApolloClient from 'utils/configureApolloClient';
import configureCordova from 'utils/configureCordova';
import configureReduxStore from 'utils/configureReduxStore';
import { init as initLogging, logError } from 'utils/logging';

let requestCount = 0;

async function init() {
  /**
   * Create store, persistor, and get necessary state variables.
   */

  const store = configureReduxStore();
  const dispatch = store.dispatch as ThunkDispatch<IState, null, Action>;

  // Take "mode" and "platform" from the query string and add them to the store
  const params = new URL(window.location.href).searchParams;
  const affiliate = params.get(QueryStringKey.AFFILIATE) || '';
  const brand = params.get(QueryStringKey.BRAND) || '';
  const campaign = params.get(QueryStringKey.CAMPAIGN) || '';
  const clientReferrer = params.get(QueryStringKey.CLIENT_REFERRER) || '';
  const customField1 = params.get(QueryStringKey.CUSTOM_FIELD_1) || '';
  const customField2 = params.get(QueryStringKey.CUSTOM_FIELD_2) || '';
  const customField3 = params.get(QueryStringKey.CUSTOM_FIELD_3) || '';
  const customField4 = params.get(QueryStringKey.CUSTOM_FIELD_4) || '';
  const customField5 = params.get(QueryStringKey.CUSTOM_FIELD_5) || '';
  const customField6 = params.get(QueryStringKey.CUSTOM_FIELD_6) || '';
  const customField7 = params.get(QueryStringKey.CUSTOM_FIELD_7) || '';
  const customField8 = params.get(QueryStringKey.CUSTOM_FIELD_8) || '';
  const customField9 = params.get(QueryStringKey.CUSTOM_FIELD_9) || '';
  const customField10 = params.get(QueryStringKey.CUSTOM_FIELD_10) || '';
  const isStatic = params.get(QueryStringKey.STATIC) === 'true';
  const leadSource = params.get(QueryStringKey.LEAD_SOURCE) || '';
  const mode = (params.get(QueryStringKey.MODE) as Mode) || Mode.WEB;
  const platform =
    (params.get(QueryStringKey.PLATFORM) as Platform) || Platform.WEB;
  const previewMode =
    params.get(QueryStringKey.PREVIEW_MODE) !== null &&
    params.get(QueryStringKey.PREVIEW_MODE) !== 'false';
  const roomKey = params.get(QueryStringKey.ROOM_KEY) as RoomKey | undefined;
  const subcontractor = params.get(QueryStringKey.SUBCONTRACTOR) || '';
  const widgetViewMode = params.get(QueryStringKey.WIDGET_VIEW_MODE) || '';
  const rwgToken = params.get(QueryStringKey.RWG_TOKEN) || '';

  const getClientKey = () => {
    if (process.env.NODE_ENV === 'development') {
      return params.get(QueryStringKey.API_KEY);
    } else {
      const clientKeyMatch =
        window.location.href.match(/^https:\/\/(\w+)/) || [];

      return clientKeyMatch[1];
    }
  };

  dispatch(setAffiliateSourceType(affiliate));
  dispatch(setBrandSourceType(brand));
  dispatch(setCampaignSourceType(campaign));
  dispatch(setClientReferrer(clientReferrer));
  dispatch(setCustomField1(customField1));
  dispatch(setCustomField2(customField2));
  dispatch(setCustomField3(customField3));
  dispatch(setCustomField4(customField4));
  dispatch(setCustomField5(customField5));
  dispatch(setCustomField6(customField6));
  dispatch(setCustomField7(customField7));
  dispatch(setCustomField8(customField8));
  dispatch(setCustomField9(customField9));
  dispatch(setCustomField10(customField10));
  dispatch(setIsStatic(isStatic));
  dispatch(setLeadSourceSourceType(leadSource));
  dispatch(setMode(mode));
  dispatch(setPlatform(platform));
  dispatch(setSelectedRoomKey(roomKey));
  dispatch(setSubcontractor(subcontractor));
  dispatch(setViewWidgetOnly(widgetViewMode));
  dispatch(setRwgTokenSourceType(rwgToken));
  /**
   * Get the client key
   */
  const clientKey = getClientKey();

  if (!clientKey) {
    logError('ScheduleEngine: Could not determine client key');

    return;
  }

  dispatch(setClientKey(clientKey));

  const {
    auth: { deviceToken: initialDeviceToken },
  } = store.getState();

  /**
   * Get the deviceToken
   */

  let deviceToken: string | undefined;

  // If we have a stored deviceToken, do a checkin to make sure it's still good.
  if (initialDeviceToken) {
    const { deviceToken: resultDeviceToken } = await dispatch(
      checkinDevice(initialDeviceToken),
    );

    deviceToken = resultDeviceToken;
  }

  // If checkin was successful, we have a deviceToken, so we start the session.
  // If checkin was not successful, deviceToken will be undefined, so we attempt to register 3 times, ultimately
  // bailing out if none of those attempts are successful.
  if (deviceToken) {
    await dispatch(
      startSession({
        shouldVerifyExistingSession: true,
      }),
    );
  } else {
    while (!deviceToken && requestCount < 3) {
      const { deviceToken: resultDeviceToken } = await dispatch(
        registerDevice(),
      );
      deviceToken = resultDeviceToken;
      requestCount++;
    }

    if (!deviceToken) {
      logError('ScheduleEngine: Could not create a device token');

      return;
    }

    await dispatch(
      startSession({
        shouldVerifyExistingSession: false,
      }),
    );
  }

  // Initialize the analytics script
  const { sessionToken } = store.getState().auth;
  initAnalytics(clientKey, sessionToken);

  /**
   * Get the flow and theme key
   */
  const flowKey = params.get(QueryStringKey.FLOW_KEY) || undefined;
  const themeKey = params.get(QueryStringKey.THEME_KEY) || undefined;
  await dispatch(getUiConfig(themeKey, flowKey));

  const state = store.getState();

  // Make sure theme was set in state, otherwise bail out
  const clientStateTheme = state.client.theme;
  const flowState = state.client.flow;

  if (!clientStateTheme || !Object.keys(clientStateTheme).length) {
    logError('ScheduleEngine: Could not load client theme');

    return;
  }

  const {
    settings: { schedulePreferenceEnabled, thankYouRedirectUrl },
  } = clientStateTheme;

  let {
    settings: { analyticsTrackingEnabled, googleAnalyticsTrackingEnabled },
  } = clientStateTheme;

  if (flowState) {
    analyticsTrackingEnabled = flowState.analyticsTrackingEnabled;
    googleAnalyticsTrackingEnabled = flowState.googleAnalyticsTrackingEnabled;
  }

  dispatch(setAnalyticsTrackingEnabled(analyticsTrackingEnabled));
  dispatch(setGoogleAnalyticsTrackingEnabled(googleAnalyticsTrackingEnabled));
  dispatch(setSchedulePreferenceEnabled(schedulePreferenceEnabled));
  dispatch(setThankYouRedirectUrl(thankYouRedirectUrl));

  const { client, wsClient } = configureApolloClient(dispatch, store.getState);

  await configureCordova(mode, platform);

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        {previewMode ? <Preview /> : <App wsClient={wsClient} />}
      </Provider>
    </ApolloProvider>,
    document.getElementById('root') as HTMLElement,
  );
}

initLogging(init);
