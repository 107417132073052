import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  PASSCODE_LOGIN_FAILURE,
  PASSCODE_LOGIN_REQUEST,
  upgradeSession,
} from 'actions';
import { IState } from 'typings';
import { tryLocalStorageSetItem } from 'utils/localStorage';
import { logNetworkError } from 'utils/logging';
import { buildPostOptions } from 'utils/request';

function passcodeLoginFailure(error: unknown) {
  logNetworkError({
    error,
    url: process.env.REACT_APP_PASSCODE_LOGIN_URI,
  });

  return { error, type: PASSCODE_LOGIN_FAILURE };
}

function passcodeLoginRequest() {
  return { type: PASSCODE_LOGIN_REQUEST };
}

export function passcodeLogin(phoneId: string, passcode: string) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ): Promise<void> => {
    const {
      auth: { sessionToken },
      client,
    } = getState();

    dispatch(passcodeLoginRequest());

    try {
      const body = {
        client_key: client.key,
        code: passcode,
        phone_id: phoneId,
      };
      const options = buildPostOptions({ body, token: sessionToken });
      const response = await fetch(
        `${process.env.REACT_APP_PASSCODE_LOGIN_URI}`,
        options,
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const { token } = await response.json();
      tryLocalStorageSetItem('userToken', token);
      window.dispatchEvent(new Event('storage'));

      await dispatch(upgradeSession(token));
    } catch (error) {
      dispatch(passcodeLoginFailure(error));

      throw error;
    }
  };
}
