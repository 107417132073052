import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  setCurrentCarouselItemToDefault,
} from 'actions';
import { CarouselReduxReducer, IState } from 'typings';

export function resetServiceCarouselForFlowChange() {
  return (dispatch: ThunkDispatch<IState, null, Action>) => {
    // Act similarly to resetServiceCarouselForCsr except maintain session
    dispatch({ type: RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE });

    // Reset the service carousel and clear history
    dispatch(
      setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE, true),
    );
  };
}
