import { IIssue, IIssueSpecNext } from 'typings';

interface ISkippableItem extends IIssue {
  specifier: {
    details: {
      info: null;
      label: null;
      note: string | null;
      notice: null;
      options: null;
      serviceCodeId: string;
      toIssueId: null;
      warn: null;
    };
    next: Array<IIssueSpecNext> | null;
    title: string | null;
  };
}

export default function isIssueDetailsItemSkipped(
  issue: IIssue,
): issue is ISkippableItem {
  const { info, label, notice, options, serviceCodeId, toIssueId, warn } =
    issue.specifier.details;

  return !!(
    serviceCodeId &&
    !info &&
    !label &&
    !notice &&
    !options &&
    !toIssueId &&
    !warn
  );
}
