import React from 'react';

import { ICarouselNavItemProps } from 'typings';

import { Item } from './styles';

export const CarouselNavItem = React.forwardRef<
  HTMLLIElement,
  ICarouselNavItemProps
>(function CarouselNavItem(
  { currentStepIndex, index, onClick, step }: ICarouselNavItemProps,
  ref,
) {
  return (
    <Item
      currentStepIndex={currentStepIndex}
      index={index}
      onClick={(event) => {
        if (onClick && index < currentStepIndex) {
          onClick(event);
        }
      }}
      ref={ref}
      role="button"
    >
      {step}
    </Item>
  );
});
