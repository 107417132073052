import {
  ICameraOptions,
  ICaptureVideoOptions,
  ILocalFile,
  IMediaFile,
  IWindowCordova,
  IWindowNavigator,
  IWindowWithFileSystem,
  LocalFileSystemAccessType,
} from 'typings';

// This is a typed wrapper around our Cordova Plugin APIs
// The wrapper should gracefully handle incompatibilities between
// the native plugins and the plugins configured for this website
// so we don't break during the brief time we're deploying the
// website and the native apps

const cordovaApi = {
  DomFile: File,
  browser: {
    open: (
      url?: string | URL,
      target = '_system',
      features = 'location=yes',
    ) => {
      const cordova = window.cordova as IWindowCordova;
      const browser = cordova && cordova.InAppBrowser;

      if (typeof url !== 'string') {
        url = url?.toString();
      }

      return browser && browser.open(url, target, features);
    },
  },
  camera: {
    getPicture: (
      onSuccess: (data: string) => void,
      onError: (message: string) => void,
      options?: ICameraOptions,
    ): void => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const { camera } = navigator;

      return camera && camera.getPicture(onSuccess, onError, options);
    },
  },
  capture: {
    captureVideo: (
      onSuccess: (mediaFiles: Array<IMediaFile>) => void,
      onError: (err: Error) => void,
      options?: ICaptureVideoOptions,
    ): void => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const capture = navigator.device && navigator.device.capture;

      return capture && capture.captureVideo(onSuccess, onError, options);
    },
  },
  connection: {
    getType: () => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const { connection } = navigator;

      return connection && connection.type;
    },
  },
  notification: {
    alert: (
      message: any,
      callback?: () => void,
      title?: string,
      buttonTitle?: string,
    ) => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const { notification } = navigator;

      return (
        notification &&
        notification.alert(message, callback, title, buttonTitle)
      );
    },
  },
  requestFileSystem: (
    accessType: LocalFileSystemAccessType,
    size: number,
    successCallback: (fs: any) => void,
    errorCallback: (error: any) => void,
  ) => {
    const windowWithCordova = window as Window as IWindowWithFileSystem;
    const { requestFileSystem } = windowWithCordova;

    return (
      requestFileSystem &&
      requestFileSystem(accessType, size, successCallback, errorCallback)
    );
  },
  resolveLocalFileSystemURL: (
    path: string,
    successCallback: (localFile: ILocalFile) => void,
    errorCallback: (error: any) => void,
  ) => {
    const windowWithCordova = window as Window as IWindowWithFileSystem;
    const { resolveLocalFileSystemURL: resolveLocalFileSystemUrl } =
      windowWithCordova;

    return (
      resolveLocalFileSystemUrl &&
      resolveLocalFileSystemUrl(path, successCallback, errorCallback)
    );
  },
  splashscreen: {
    hide: () => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const { splashscreen } = navigator;

      return splashscreen && splashscreen.hide();
    },
    show: () => {
      const navigator = window.navigator as unknown as IWindowNavigator;
      const { splashscreen } = navigator;

      return splashscreen && splashscreen.show();
    },
  },
};

export function isNativeCameraSupported() {
  const windowWithCordova = window as Window as IWindowWithFileSystem;
  const navigator = window.navigator as unknown as IWindowNavigator;

  return !!(
    windowWithCordova.requestFileSystem &&
    windowWithCordova.resolveLocalFileSystemURL &&
    navigator.camera &&
    navigator.device &&
    navigator.device.capture
  );
}

export default cordovaApi;
