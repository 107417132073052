import { ContextBubble, Form } from '@homex/se-react-components';
import styled from 'styled-components/macro';

import { ThemedCircleButton } from 'components/ThemedCircleButton';
import { ReactComponent as Chevron } from 'components/svg/ChevronRight.svg';
import { ReactComponent as ListViewToggleSvg } from 'components/svg/ListViewToggle.svg';
import { large } from 'styles/breakpoints';
import { container, containerTall } from 'styles/layout';
import { IWithThemeProps } from 'typings';

interface IOperatingHoursChevronProps {
  isOpen: boolean;
}

export const FormGroup = styled(Form)`
  ${containerTall}
`;

export const Container = styled.div`
  ${container}

  margin-top: 25px;

  ${large`
    padding-right: 0;
  `}
`;

export const Buttons = styled.div`
  ${container}

  margin-top: 37px;

  ${large`
    margin-top: 19px;
  `}
`;

export const Toggle = styled(ThemedCircleButton)`
  margin: -9px 0 0 30px;
`;

export const ToggleImage = styled(ListViewToggleSvg)`
  display: block;
  height: 17px;
  width: 24px;
`;

export const Toolbar = styled.div`
  align-items: flex-start;
  display: flex;
  position: relative;
`;

export const Gradient = styled.div`
  background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 28px);
  height: 90px;
  margin: -90px -1px 0;
  position: relative;
`;

export const BubbleStartHere = styled(ContextBubble)`
  left: 0;
  top: 47px;
  width: 107px;
`;

export const BubbleListView = styled(ContextBubble)`
  right: -14px;
  top: 41px;
`;

export const EmergencyTooltip = styled(ContextBubble)`
  left: 0;
  top: 57px;
  width: 100%;
`;

export const EmergencyTooltipHeader = styled.p`
  margin: 0 0 21px;
`;

export const EmergencyTooltipOperatingHours = styled.p`
  line-height: 24px;
  margin: 0 0 18px 18px;
`;

export const OperatingHoursButton = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.topForegroundPrimary};
  `}
`;

const ChevronWithProps = ({
  isOpen: _isOpen,
  ...props
}: IOperatingHoursChevronProps) => {
  return <Chevron {...props} />;
};

export const OperatingHoursChevron = styled(
  ChevronWithProps,
)<IOperatingHoursChevronProps>`
  height: 21px;
  margin-right: 12px;
  transition: transform 0.4s;
  width: 11px;

  ${({ isOpen }) => `
    transform: rotate(${isOpen ? -90 : 90}deg);
  `}
`;
