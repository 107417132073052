import React from 'react';

/**
 * Customer renderer for links that add `target="_blank"`
 */
export const MarkdownLinkRenderer = ({
  children,
  href,
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a href={href} rel="noopener noreferrer" target="_parent">
    {children}
  </a>
);
