import { CarouselItemId } from '@homex/se-widget-flow-types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  GoogleAnalyticsAction,
  IGoogleAnalyticsEvent,
  IState,
  SeEmbedEvent,
  ServiceType,
} from 'typings';
import { buildGoogleAnalyticsEvent } from 'utils/buildGoogleAnalyticsEvent';
import { formatTimeSlot } from 'utils/formatTimeSlot';
import postMessageToParent from 'utils/postMessageToParent';

export function reportCarouselItemCompleted() {
  return async (
    _dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const state = getState();
    const {
      client: { flow, googleAnalyticsTrackingEnabled, validOrigins },
      modal: { isServiceModalVisible },

      userInput: { serviceType },
    } = state;

    if (serviceType !== ServiceType.REPAIR || !isServiceModalVisible) {
      return;
    }

    const gaEvents = tryGetGoogleAnalyticsEvents(state);

    if (googleAnalyticsTrackingEnabled) {
      for (const ga of gaEvents) {
        postMessageToParent(
          {
            ga,
            type: 'se-send-client-google-analytics',
          },
          validOrigins,
        );
      }
    }

    for (const ga of gaEvents) {
      // Ensure/force compatibility between SE and GA events
      if (
        !Object.values(SeEmbedEvent).includes(
          ga.action as unknown as SeEmbedEvent,
        )
      ) {
        throw new Error(
          `'${ga.action}' GoogleAnalyticsAction not found in SeEvent enum`,
        );
      }

      postMessageToParent(
        {
          analyticsKey: flow?.key,
          eventName: ga.action as unknown as SeEmbedEvent,
          type: 'se-event',
        },
        validOrigins,
      );
    }
  };
}

function tryGetGoogleAnalyticsEvents(
  state: IState,
): Array<IGoogleAnalyticsEvent> {
  const events: Array<IGoogleAnalyticsEvent> = [];

  // Get the completed carousel item minus any identifiers (ex. ISSUE_DETAILS instead of ISSUE_DETAILS-issue_cl7933caw0000ayohdkac4soh)
  const completedCarouselItemId = state.serviceCarousel.currentItemId.split(
    '-',
  )[0] as CarouselItemId;

  /**
   * BookingIssueStarted
   * Fired when the customer chooses a category or piece of equipment.
   *
   * NOTES:
   * With the introduction of flows, this event was changed from being fired
   * when a user clicks the ISSUE_SPECIFIER drop down to when the user clicks
   * continue on the ISSUE_SPECIFIER screen. For people interested in a user's
   * first interaction, a new BOOKING_PROCESS_STARTED event was added.
   *
   */
  if (completedCarouselItemId === CarouselItemId.IssueSpecifier) {
    const { selectedIssue, selectedRoomIcon } = state.userInput;
    events.push(
      buildGoogleAnalyticsEvent(
        GoogleAnalyticsAction.BOOKING_ISSUE_STARTED,
        state,
        {
          label: selectedIssue?.name ?? selectedRoomIcon?.name,
        },
      ),
    );
  }

  /**
   * BookingIssueCompleted
   * Fired when the customer completes the questions.
   *
   * NOTES:
   * Since a service code may or may not be selected on the ISSUE_SPECIFIER
   * screen we need to add a further check. In other words, if they complete
   * ISSUE_SPECIFIER and there is no service code selected, don't fire this
   * event; it should fire later when they complete an ISSUE_DETAIL screen.
   */
  const serviceCodeTerminalItems = [
    CarouselItemId.IssueSpecifier,
    CarouselItemId.IssueDetails,
  ];

  if (serviceCodeTerminalItems.includes(completedCarouselItemId)) {
    const { serviceCodeId } = state.userInput;

    if (serviceCodeId) {
      events.push(
        buildGoogleAnalyticsEvent(
          GoogleAnalyticsAction.BOOKING_ISSUE_COMPLETED,
          state,
          {
            label: serviceCodeId,
          },
        ),
      );
    }
  }

  /**
   * BookingDetailsMedia
   * Fired when the customer finishes the media uploads.
   *
   * NOTES:
   * It's possible the user utilizes the MEDIA_DETAIL screen (which follows
   * MEDIA_UPLOAD), but we'll assume a slight inaccuracy and only track the
   * main media screen.
   */
  if (completedCarouselItemId === CarouselItemId.MediaUpload) {
    const { mediaIds } = state.userInput;

    events.push(
      buildGoogleAnalyticsEvent(
        GoogleAnalyticsAction.BOOKING_DETAILS_MEDIA,
        state,
        {
          label: 'Files Uploaded',
          value: mediaIds.filter((mId) => !!mId).length,
        },
      ),
    );
  }

  /**
   * BookingDetailsMore
   * Fired when the customer provides more details.
   */
  if (completedCarouselItemId === CarouselItemId.RepairComments) {
    events.push(
      buildGoogleAnalyticsEvent(
        GoogleAnalyticsAction.BOOKING_DETAILS_MORE,
        state,
      ),
    );
  }

  /**
   * BookingCustomerQuestion
   * Fired when the customer indicates if they are an existing customer.
   */
  if (completedCarouselItemId === CarouselItemId.CustomerLookup) {
    const { isPreviousCustomer } = state.userInput;

    events.push(
      buildGoogleAnalyticsEvent(
        GoogleAnalyticsAction.BOOKING_CUSTOMER_QUESTION,
        state,
        {
          label: isPreviousCustomer ? 'YES' : 'NO',
        },
      ),
    );
  }

  /**
   * BookingCustomer
   * Fired when the customer provides their name and location information.
   *
   * NOTES:
   * There are a handful of screens that signify the end of our customer and
   * location retrieval process. Additionally, there are potential chains of
   * navigation between them. So we'll watch for an address having an id, which
   * indicates a solidified address selection.
   */
  const customerLocationTerminalItems = [
    CarouselItemId.RepairAddress,
    CarouselItemId.CustomerLocations,
    CarouselItemId.CustomerLocationsRecovered,
    CarouselItemId.RepairAddressCorrection,
    CarouselItemId.RepairAddressNotFound,
  ];

  if (customerLocationTerminalItems.includes(completedCarouselItemId)) {
    const { address, customerLookupCustomerLocations, isPreviousCustomer } =
      state.userInput;

    if (address.id) {
      let label: string | undefined;

      if (!isPreviousCustomer) {
        label = 'New Customer';
      } else {
        const existingLocation = customerLookupCustomerLocations.find(
          (cl) => cl.location.addressId === address.id,
        );
        label = `Existing Customer/${
          existingLocation ? 'Existing Location' : 'New Location'
        }`;
      }

      events.push(
        buildGoogleAnalyticsEvent(
          GoogleAnalyticsAction.BOOKING_CUSTOMER,
          state,
          {
            label,
          },
        ),
      );
    }
  }

  /**
   * BookingSchedule
   * Fired when the customer chooses a scheduling option.
   */
  const scheduleTerminalItems = [
    CarouselItemId.ScheduleCalendarRepair,
    CarouselItemId.SchedulePreference,
  ];

  if (scheduleTerminalItems.includes(completedCarouselItemId)) {
    const {
      address: { timezone = '' },
      selectedSchedulePreference,
      selectedTimeSlot,
    } = state.userInput;
    let label: string | undefined;

    if (completedCarouselItemId === CarouselItemId.ScheduleCalendarRepair) {
      label = selectedTimeSlot
        ? formatTimeSlot(selectedTimeSlot, timezone)
        : undefined;
    } else if (completedCarouselItemId === CarouselItemId.SchedulePreference) {
      label = selectedSchedulePreference;
    }

    events.push(
      buildGoogleAnalyticsEvent(GoogleAnalyticsAction.BOOKING_SCHEDULE, state, {
        label,
      }),
    );
  }

  return events;
}
