import { Transition } from 'react-transition-group';

import { IModalProps } from 'typings';

import { Container, Curtain } from './styles';

export const Modal = ({
  children,
  clearCurtain = false,
  height,
  isHeightStatic = true,
  isStatic = false,
  isVisible,
}: IModalProps) => {
  return (
    <Curtain
      clearCurtain={clearCurtain}
      isStatic={isStatic}
      isVisible={isVisible}
    >
      <Transition
        in={isVisible}
        mountOnEnter={true}
        timeout={200}
        unmountOnExit={true}
      >
        {(state) => (
          <Container
            heightValue={height}
            isHeightStatic={isHeightStatic}
            transitionStatus={state}
          >
            {children}
          </Container>
        )}
      </Transition>
    </Curtain>
  );
};
