import styled, { css } from 'styled-components/macro';

import {
  CarouselBackgroundStyle,
  CarouselPosition,
  IWithThemeProps,
} from 'typings';

interface IContainerProps {
  backgroundStyle: CarouselBackgroundStyle;
  naturalTopHeight: number;
  position: CarouselPosition;
  topHeight: number;
}

interface ITrackProps {
  columnCount: number;
  index: number;
}

export const Track = styled.div<ITrackProps>`
  align-items: start;
  display: grid;
  line-height: 0;
  margin: 0;
  min-width: 100%;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: auto;
  z-index: 1;

  ${({ columnCount }) => css`
    grid-template-columns: repeat(${columnCount}, 100%);
    grid-template-rows: 1fr;
  `}

  ${({ theme }: IWithThemeProps) => `
    transition: transform ${theme.transitionDurationCarouselTrack} ease;
  `}

  ${({ index }) => `
    transform: translateX(-${index * 100}%);
  `}
`;

export const Container = styled.div<IContainerProps>`
  min-width: 100%;
  position: relative;
  width: 100%;
  z-index: 1;

  ${({ theme }: IWithThemeProps) => `
    transition: transform ${theme.transitionDurationCarouselTrack} ease;
  `}

  ${({ naturalTopHeight, position, topHeight }) => {
    if (position === CarouselPosition.BOTTOM) {
      return `
        transform: translateY(-${naturalTopHeight - topHeight}px);
      `;
    }

    return '';
  }}

  ${({ backgroundStyle, theme, topHeight }) => {
    switch (backgroundStyle) {
      case CarouselBackgroundStyle.GRADIENT_EXPANDED:
        return `
          background: ${theme.topBackgroundColor};

          &:after, &:before {
              content: '';
              position: absolute;
              transition: opacity ${theme.transitionDurationCarouselTrack} ease;
          }

          /* Compact */
          &:after {
              top: -120px;
              right: 0;
              width: 300px;
              height: 440px;
              background-image: ${theme.topBackgroundGradient};
              opacity: ${topHeight >= 200 ? 0 : 1};
          }

          /* Expanded */
          &:before {
              bottom: 0;
              right: 0;
              width: 100%;
              height: 100%;
              background-image: ${theme.topBackgroundGradientExpanded};
              opacity: ${topHeight >= 200 ? 1 : 0};
          }

          & > ${Track} {
              min-height: 439px;
          }
        `;
      case CarouselBackgroundStyle.SOLID:
        return `
          background: ${theme.bottomBackgroundColor};
          margin-top: -1px;
        `;
      default:
        return '';
    }
  }}
`;
