import { TransitionStatus } from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

import { large } from 'styles/breakpoints';

interface IContainerProps {
  heightValue?: number;
  isHeightStatic: boolean;
  transitionStatus: TransitionStatus;
}

interface ICurtainProps {
  clearCurtain: boolean;
  isStatic: boolean;
  isVisible: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 320px;
  overflow: hidden;
  text-align: center;
  /* TODO: Remove this. Commented for now to fix #174674011 */
  /* width: 100vw; */

  ${({ theme }) => `
    background: ${theme.bottomBackgroundColor};
    transition: opacity ${theme.transitionDurationPrimary} ease,
      transform ${theme.transitionDurationPrimary} ease,
      height ${theme.transitionDurationCarouselTrack} ease;
  `}

  ${({ heightValue, transitionStatus }) => {
    const shouldAnimateIn = heightValue && transitionStatus === 'entered';

    if (shouldAnimateIn) {
      return `
        opacity: 1;
        transform: scale(1);
      `;
    } else {
      return `
        opacity: 0;
        transform: scale(0.8);
      `;
    }
  }}

  ${({ heightValue, isHeightStatic }) => {
    if (!heightValue) {
      return;
    }

    if (isHeightStatic) {
      return `
        height: ${heightValue}px;
      `;
    } else {
      return `
        min-height: ${heightValue}px;
      `;
    }
  }}

  ${large`
    border-radius: 15px;
    box-shadow: 1px 2px 24px 1px rgba(0, 0, 0, 0.4);
    margin: 35px auto;
    width: 728px;
  `}
`;

export const Curtain = styled.div<ICurtainProps>`
  /* TODO: Remove this. Commented for now to fix #174674011 */
  /* display: table; */
  -ms-overflow-style: none;
  height: 100%;
  overflow-y: auto;
  position: absolute; /* Hide scroll bars on Firefox */
  scrollbar-width: none; /* Hide scroll bars on Internet Explorer 10+ */
  width: 100%;

  &::-webkit-scrollbar {
    /* Hide scroll bars on webkit */
    display: none;
  }

  ${({ clearCurtain, isStatic, isVisible, theme }) => {
    if (isVisible) {
      return `
        background-color: ${
          clearCurtain
            ? 'rgba(0,0,0,0)'
            : isStatic
            ? theme.bottomBackgroundColor
            : theme.curtainBackgroundColor
        };
        left: 0;
        top: 0;
        transition: background-color ${theme.transitionDurationPrimary} ease,
          left ${theme.transitionDurationPrimary} step-start,
          top ${theme.transitionDurationPrimary} step-start;
      `;
    } else {
      return `
        background-color: rgba(255, 255, 255, 0);
        left: -9999px;
        top: -9999px;
        transition: background-color ${theme.transitionDurationPrimary} ease,
          left ${theme.transitionDurationPrimary} step-end,
          top ${theme.transitionDurationPrimary} step-end;
      `;
    }
  }}
`;
