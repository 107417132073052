import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as EmergencyCarbonMonoxideSvg } from 'components/svg/EmergencyCarbonMonoxide.svg';
import { ReactComponent as EmergencyElectricitySvg } from 'components/svg/EmergencyElectricity.svg';
import { ReactComponent as EmergencyHvacSvg } from 'components/svg/EmergencyHvac.svg';
import { ReactComponent as EmergencyLeakSvg } from 'components/svg/EmergencyLeak.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import { Button, ButtonRow, ButtonWrapper, imageStyles } from './styles';

export const RoomEmergency = ({ icons }: IRoomProps) => {
  const iconList = [
    {
      icon: icons.find(({ key }) => key === RoomIconKey.EMERGENCY_HVAC),
      iconSvg: <EmergencyHvacSvg css={imageStyles} />,
    },
    {
      icon: icons.find(({ key }) => key === RoomIconKey.EMERGENCY_LEAK),
      iconSvg: <EmergencyLeakSvg css={imageStyles} />,
    },
    {
      icon: icons.find(({ key }) => key === RoomIconKey.EMERGENCY_ELECTRICITY),
      iconSvg: <EmergencyElectricitySvg css={imageStyles} />,
    },
    {
      icon: icons.find(
        ({ key }) => key === RoomIconKey.EMERGENCY_CARBON_MONOXIDE,
      ),
      iconSvg: <EmergencyCarbonMonoxideSvg css={imageStyles} />,
    },
  ];

  return (
    <ButtonRow>
      {iconList.map(({ icon, iconSvg }) =>
        !icon ? null : (
          <ButtonWrapper key={icon.key}>
            <Button>
              <RoomButton hasTooltip={false} icon={icon} iconSvg={iconSvg} />
            </Button>
          </ButtonWrapper>
        ),
      )}
    </ButtonRow>
  );
};
