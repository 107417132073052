import styled from 'styled-components/macro';

import { heading } from 'styles/typography';
import { IWithThemeProps } from 'typings';

export const Header = styled.h1`
  ${heading}

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.topForegroundPrimary};
  `}
`;
