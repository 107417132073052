import { IModalState } from 'typings';

/**
 * We need to call this function AFTER hiding a modal in se-widget-ui, and BEFORE telling se-widget-embed to hide the
 * modal (i.e., hide the iframe), so that we don't accidentally hide the iframe when another modal should actually be
 * visible. See SE-2277 for more details.
 */
export default function isAnyModalVisible(modalState: IModalState): boolean {
  const {
    isAccountModalVisible,
    isAppointmentModalVisible,
    isLoginModalVisible,
    isServiceModalVisible,
  } = modalState;

  return (
    isAccountModalVisible ||
    isAppointmentModalVisible ||
    isLoginModalVisible ||
    isServiceModalVisible
  );
}
