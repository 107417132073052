import Raven from 'raven-js';

import { ILogglyData, INetworkErrorLog } from 'typings';

const tags: { [key: string]: string } = {
  'app-key': 'se-widget-ui',
  'aws-account-key': process.env.REACT_APP_AWS_ACCOUNT_KEY || '',
  'build-id': process.env.REACT_APP_SE_BUILD_ID || '',
  'node-env': process.env.NODE_ENV || '',
};

const ravenTags = {
  ...tags,
  severity: 'error',
};

export function init(appInitializer: () => void) {
  // Initialize Sentry logging
  Raven.config(process.env.REACT_APP_SENTRY_URI || '', {
    environment: process.env.REACT_APP_AWS_ACCOUNT_KEY,
    release: process.env.REACT_APP_VERSION,
    tags: ravenTags,
  }).install();
  Raven.context(appInitializer);
}

export function logError(
  message: string,
  data?: ILogglyData,
  ...args: Array<any>
) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(message, data, ...args);
  }
}

export function logException(
  error: unknown,
  data?: ILogglyData,
  ...args: Array<any>
) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error, data, ...args);
  }
}

export function logNetworkError(data: INetworkErrorLog, ...args: Array<any>) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(data, ...args);
  }
}

export function logWarning(
  message: string,
  data?: ILogglyData,
  ...args: Array<any>
) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(message, data, ...args);
  }
}
