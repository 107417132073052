import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';

import { MarkdownLinkRenderer } from 'components/MarkdownLinkRenderer';
import { StringContext } from 'components/StringContext';
import { logError } from 'utils/logging';

interface IPhraseProps {
  [templateKey: string]: string;
  id: string;
}

interface ITemplateOptions {
  [templateKey: string]: string;
}

export interface IWithStringsProps {
  strings: { [stringId: string]: string };
}

/**
 * Interpolates key values from `options` into rawString where keys appear
 * wrapped in curly braces.
 *  `interpolateString('Hello, {NAME}', { NAME: 'Frank' })`
 */
export function interpolateString(
  rawString: string,
  options: ITemplateOptions,
): string {
  const keys = Object.keys(options);

  return keys.reduce(
    (phrase, key) => phrase.replace(`{${key}}`, options[key] ?? key),
    rawString,
  );
}

/**
 * React HOC that provides `strings` to the wrapped component's props
 */
export function withStrings<TProps>(Component: React.ComponentType<TProps>) {
  function forwardRef(props: TProps, ref: React.Ref<any>) {
    return (
      <StringContext.Consumer>
        {(strings) => <Component {...props} ref={ref} strings={strings} />}
      </StringContext.Consumer>
    );
  }

  const displayName = Component.displayName || Component.name;
  forwardRef.displayName = `withStrings(${displayName})`;

  return React.forwardRef(forwardRef);
}

const ID_SUFFIX_REGEXP = /\.(TEMPLATE|HTML)$/;

/**
 * A valid `id` is any string in all captial letters, which uses underscores (_) to represent word breaks and dots (.)
 * achieve namespacing or to represent different special "tokens".
 *
 * Examples:
 *
 * *As a component:*
 * ```jsx
 * <Phrase id="ID_STRING" />
 * <Phrase id="ID_STRING" TEMPLATE_KEY="Template value." />
 * ```
 */
export const Phrase = ({ id, ...templateOptions }: IPhraseProps) => {
  return (
    <StringContext.Consumer>
      {(strings) => {
        let phrase = strings[id];

        // If we didn't find the phrase, and it has either .TEMPLATE or .HTML at
        // the end, remove that part, and attempt again to find the phrase.
        if (!phrase && ID_SUFFIX_REGEXP.test(id)) {
          id = id.replace(ID_SUFFIX_REGEXP, '');
          phrase = strings[id];
        }

        // If we still didn't find the phrase, and it still has either .TEMPLATE
        // or .HTML at the end, remove it again and attempt to find the phrase.
        // Note: This seems odd, but in reality, some strings have an ending of
        // .HTML.TEMPLATE, or .TEMPLATE.HTML, so we need to account for both
        if (!phrase && ID_SUFFIX_REGEXP.test(id)) {
          id = id.replace(ID_SUFFIX_REGEXP, '');
          phrase = strings[id];
        }

        if (!phrase) {
          logError(`Could not find "${id}" in strings.`);
        }

        return (
          <ReactMarkdown
            allowDangerousHtml={true}
            // By default, in Markdown, all new lines render a paragraph as the
            // root element. This means all of our strings would render inside a
            // <p> with its default margin. To avoid this, and to avoid the
            // semantics that come along with `<p>`, and since we know that we
            // never expect paragraphs from the server, we render all `<p>`s
            // as `<span>`s
            renderers={{ link: MarkdownLinkRenderer, paragraph: 'span' }}
          >
            {interpolateString(phrase ?? '', templateOptions)}
          </ReactMarkdown>
        );
      }}
    </StringContext.Consumer>
  );
};
