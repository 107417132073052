import { CircleButton, ICircleButtonProps } from '@homex/se-react-components';
import { withTheme } from 'styled-components/macro';

import { largeQuery } from 'styles/breakpoints';
import { IWithThemeProps } from 'typings';

interface IThemedCircleButtonProps
  extends ICircleButtonProps,
    IWithThemeProps {}

export const ThemedCircleButton = withTheme(
  ({ theme, ...props }: IThemedCircleButtonProps) => (
    <CircleButton
      {...props}
      themeValues={{
        backgroundColor: theme.bottomBackgroundColor,
        borderColor: theme.bottomForegroundActionPrimary,
        fontFamily: theme.fontFamilyPrimary,
        labelColor: theme.bottomForegroundActionPrimary,
        mediaLarge: largeQuery,
        transitionDuration: theme.transitionDurationPrimary,
      }}
    />
  ),
);
