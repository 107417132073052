import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { SHOW_ACCOUNT_MODAL, startSession } from 'actions';
import { IState } from 'typings';

export function showAccountModal() {
  return async (dispatch: ThunkDispatch<IState, null, Action>) => {
    await dispatch(startSession({ shouldVerifyExistingSession: true }));

    dispatch({ type: SHOW_ACCOUNT_MODAL });
  };
}
