import capsize from 'capsize';
import { CSSObject, css } from 'styled-components/macro';

import { large } from 'styles/breakpoints';
import { IWithThemeProps, Take } from 'typings';

// Taken from https://seek-oss.github.io/capsize/ after measuring Proxima-Nova
const fontMetricsRegular = {
  ascent: 1858,
  capHeight: 1320,
  descent: -597,
  lineGap: 0,
  unitsPerEm: 2048,
};

const fontMetricsSemibold = {
  ascent: 1860,
  capHeight: 1462,
  descent: -630,
  lineGap: 0,
  unitsPerEm: 2048,
};

const fontWeight: { [TName: string]: Take<CSSObject, 'fontWeight'> } = {
  regular: 'normal',
  semibold: 'bold',
};

export const description = css`
  text-align: left;

  ${({ theme }: IWithThemeProps) => `
    font: 14px/21px ${theme.fontFamilyPrimary};
  `}
`;

export const descriptionCapsize = css(({ theme }: IWithThemeProps) => {
  const styles: CSSObject = {
    fontFamily: theme.fontFamilyPrimary,
    textAlign: 'left',
    ...capsize({
      fontMetrics: fontMetricsRegular,
      fontSize: 14,
      leading: 21,
    }),
  };

  return styles;
});

export const error = css`
  text-align: left;

  ${({ theme }: IWithThemeProps) => `
    font: 15px/21px ${theme.fontFamilyPrimary};
  `}
`;

export const eyebrow = css`
  letter-spacing: 2px;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 13px/16px ${theme.fontFamilyPrimary};
  `}
`;

export const eyebrowThin = css`
  letter-spacing: 0.1px;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 13px/16px ${theme.fontFamilyPrimary};
  `}
`;

export const formHeadingThin = css`
  ${({ theme }: IWithThemeProps) => `
    font: 18px/22px ${theme.fontFamilyPrimary};
  `}
`;

export const heading = css`
  margin: 23px 0 54px;
  text-align: center;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.regular} 19px/23px ${theme.fontFamilyPrimary};
  `}

  ${large`
    font-size: 24px;
    line-height: 29px;
    margin: 21px 0 50px;
  `}
`;

export const headingForm = css`
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 18px/22px ${theme.fontFamilyPrimary};
  `}
`;

export const headingLower = css`
  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 18px/22px ${theme.fontFamilyPrimary};
  `}
`;

export const headingLowerCapsize = css(({ theme }: IWithThemeProps) => {
  const styles: CSSObject = {
    fontFamily: theme.fontFamilyPrimary,
    fontWeight: fontWeight.semibold,
    ...capsize({
      fontMetrics: fontMetricsSemibold,
      fontSize: 18,
      leading: 22,
    }),
  };

  return styles;
});

export const headingCalendar = css`
  letter-spacing: 3.8px;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: 16px/19px ${theme.fontFamilyPrimary};
  `}
`;

// line-height of 25px taken from textarea mock
export const input = `
  ${({ theme }: IWithThemeProps) => css`
    font: 18px/25px ${theme.fontFamilyPrimary};
  `}
`;

export const inputSmall = css`
  ${({ theme }: IWithThemeProps) => `
    font: 15px/18px ${theme.fontFamilyPrimary};
  `}
`;

export const inputLabel = css`
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 12px/21px ${theme.fontFamilyPrimary};
  `}
`;

export const legal = css`
  ${({ theme }: IWithThemeProps) => `
    font: 13px/20px ${theme.fontFamilyPrimary};
  `}
`;

export const mapEtaLabel = css`
  ${({ theme }: IWithThemeProps) => `
    font: ${fontWeight.semibold} 14px/17px ${theme.fontFamilyPrimary};
  `}
`;

export const navLabel = css`
  text-align: center;
  text-transform: uppercase;

  ${({ theme }: IWithThemeProps) => `
    font: 11px/12px ${theme.fontFamilyPrimary};
  `}
`;

export const paragraph = css`
  ${({ theme }: IWithThemeProps) => `
    font: 14px/18px ${theme.fontFamilyPrimary};
  `}
`;
