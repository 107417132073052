import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';
import { useTheme } from 'styled-components/macro';

import { ICarouselItemProps, IState } from 'typings';

import { Item } from './styles';

export const CarouselItem = forwardRef<HTMLDivElement, ICarouselItemProps>(
  function CarouselItem(
    { children, id, index, reduxReducer }: ICarouselItemProps,
    ref,
  ) {
    const { currentItemId } = useSelector((state: IState) => ({
      currentItemId: state[reduxReducer].currentItemId,
    }));
    const theme = useTheme();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === 'Enter' &&
        (event.target as Element).tagName !== 'TEXTAREA'
      ) {
        const submitButton = event.currentTarget.querySelector(
          'button[type="submit"]',
        ) as HTMLButtonElement;

        if (submitButton) {
          submitButton.click();
        }
      }

      return false;
    };

    const isVisible = currentItemId === id;
    const transitionTimeout = parseInt(
      theme.transitionDurationCarouselTrack,
      10,
    );

    return (
      <Transition
        in={isVisible}
        mountOnEnter={true}
        timeout={isNaN(transitionTimeout) ? 600 : transitionTimeout}
        unmountOnExit={true}
      >
        {(transitionStatus) => (
          <Item
            index={index}
            onKeyDown={handleKeyDown}
            ref={ref}
            transitionStatus={transitionStatus}
          >
            {children}
          </Item>
        )}
      </Transition>
    );
  },
);
