import {
  CarouselItemId,
  FlowCarouselItemId,
} from '@homex/se-widget-flow-types';
import { Action } from 'redux';

import {
  CarouselBottomButtonType,
  CarouselItemRefMap,
  IIssueStackItem,
  IOwnProps,
} from 'typings';

/**
 * CarouselGroup
 */

export interface ICarouselItemGrouping {
  componentBottom: React.ReactNode;
  componentTop: React.ReactNode;
  id: CarouselItemId;
}

export enum ItemStackPlaceholder {
  APPOINTMENT_STACK = 'APPOINTMENT_STACK',
  ISSUE_STACK = 'ISSUE_STACK',
}

export type CarouselFlowItem = ICarouselItemGrouping | ItemStackPlaceholder;

export type CarouselFlow = Array<CarouselFlowItem>;

/**
 * Carousel
 */

export enum CarouselReduxReducer {
  APPOINTMENT = 'appointmentCarousel',
  SERVICE = 'serviceCarousel',
}

export enum CarouselBackgroundStyle {
  GRADIENT_EXPANDED = 'GRADIENT_EXPANDED',
  SOLID = 'SOLID',
}

export enum CarouselPosition {
  BOTTOM = 'BOTTOM',
  TOP = 'TOP',
}

export interface ICarouselState {
  bottomHeight?: number;
  currentItemId: CarouselItemId;
  history: Array<CarouselItemId>;
  naturalTopHeight?: number;
  shouldRecalculateHeight: boolean;
  topHeight?: number;
}

export interface IAppointmentCarouselState extends ICarouselState {}

export interface IServiceCarouselState extends ICarouselState {
  acceleratedEntryPoint: CarouselItemId | null;
  navPointerX: number;
  thankYouRedirectUrl: string;
}

export interface ICarouselAction extends Action, Partial<ICarouselState> {
  heightDelta?: number;
  previousItemId?: CarouselItemId;
  previousItemIndex?: number;
  reduxReducer: CarouselReduxReducer;
  url: string;
}

export interface IAppointmentCarouselAction
  extends ICarouselAction,
    Partial<IAppointmentCarouselState> {}

export interface IServiceCarouselAction
  extends ICarouselAction,
    Partial<IServiceCarouselState> {}

export interface ICarouselProps extends IOwnProps {
  backgroundStyle: CarouselBackgroundStyle;
  carouselItemRefs: React.MutableRefObject<CarouselItemRefMap>;
  position: CarouselPosition;
  reduxReducer: CarouselReduxReducer;
}

/**
 * CarouselItemServiceArea
 */

export interface ICarouselItemServiceAreaBottomProps {
  destinationItemId?: FlowCarouselItemId;
  successPhraseId?: string;
}

/**
 * CarouselItemAddress
 */

export interface ICarouselItemAddressBottomProps {
  addressDisclaimerEnabled?: boolean;
  correctionNeededDestinationId: FlowCarouselItemId;
  matchFoundDestinationId: FlowCarouselItemId;
  matchNotFoundDestinationId: FlowCarouselItemId;
  parentCarouselItemId: FlowCarouselItemId;
}

/**
 * CarouselBackButton
 */

export interface ICarouselBackButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * CarouselContinueButton
 */

export interface ICarouselContinueButtonProps {
  buttonType?: CarouselBottomButtonType;
  closeLabel?: React.ReactNode;
  destinationItemId?: FlowCarouselItemId;
  errorMessage?: React.ReactNode;
  homeLabel?: React.ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: React.ReactNode;
  onClick?(): void;
  retryLabel?: React.ReactNode;
}

/**
 * CarouselItemIssueDetails
 */

export interface ICarouselItemIssueDetailsTopProps {
  issueStackItem: IIssueStackItem;
}

export interface ICarouselItemIssueDetailsBottomProps {
  issueStackItem: IIssueStackItem;
}
