import 'styled-components/macro';

import {
  IPillGroupOption,
  PillGroup,
  TradeIcon,
  TradeId,
} from '@homex/se-react-components';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  setSelectedIssue,
  setSelectedRoomIcon,
  setSelectedUiGrouping,
} from 'actions';
import { Accordion } from 'components/Accordion';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { IRoomListOwnProps, IState, ITrade } from 'typings';

import { pillGroupStyles, tradeIconStyles } from './styles';

interface ITradeListItem {
  count: number;
  trade: ITrade;
}

interface IUiGroupingMap {
  [uiGrouping: string]: {
    options: Array<IPillGroupOption>;
    tradeList: Array<ITradeListItem>;
  };
}

export const RoomList = ({ issues }: IRoomListOwnProps) => {
  const dispatch = useThunkDispatch();
  const { selectedIssue, selectedUiGrouping } = useSelector(
    (state: IState) => ({
      selectedIssue: state.userInput.selectedIssue,
      selectedUiGrouping: state.userInput.selectedUiGrouping,
    }),
  );

  const handleAccordionClick = (uiGrouping: string) => {
    if (selectedUiGrouping === uiGrouping) {
      dispatch(setSelectedUiGrouping());
    } else {
      dispatch(setSelectedUiGrouping(uiGrouping));
    }
  };

  const handlePillChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;
    const pillIssue = issues.find(({ id }) => id === value);

    dispatch(setSelectedRoomIcon());

    if (pillIssue && checked) {
      dispatch(setSelectedIssue(pillIssue));
    } else {
      dispatch(setSelectedIssue());
    }
  };

  const uiGroupingMap: IUiGroupingMap = issues.reduce(
    (resultUiGroupingMap: IUiGroupingMap, issue) => {
      const { uiGrouping } = issue;

      if (!resultUiGroupingMap[uiGrouping]) {
        resultUiGroupingMap[uiGrouping] = {
          options: [],
          tradeList: [{ count: 1, trade: issue.trade }],
        };
      } else {
        const tradeListItem = resultUiGroupingMap[uiGrouping]?.tradeList.find(
          ({ trade }) => {
            return trade.id === issue.trade.id;
          },
        );

        if (tradeListItem) {
          tradeListItem.count++;
        }
      }

      resultUiGroupingMap[uiGrouping]?.options.push({
        key: issue.id,
        label: issue.name,
        value: issue.id,
      });

      return resultUiGroupingMap;
    },
    {},
  );

  const uiGroupings = Object.keys(uiGroupingMap);

  const sortedUiGroupings = uiGroupings.sort((uiGroupingA, uiGroupingB) => {
    const uiGroupingLowerCaseA = uiGroupingA.toLowerCase();
    const uiGroupingLowerCaseB = uiGroupingB.toLowerCase();

    // "Other" should always be sorted to the end
    if (uiGroupingLowerCaseA === 'other') {
      return 1;
    } else if (uiGroupingLowerCaseB === 'other') {
      return -1;
    }

    // Every other uiGrouping should be sorted alphabetically
    if (uiGroupingLowerCaseA < uiGroupingLowerCaseB) {
      return -1;
    } else if (uiGroupingLowerCaseA > uiGroupingLowerCaseB) {
      return 1;
    }

    return 0;
  });

  return (
    <div role="tablist">
      {sortedUiGroupings.map((uiGrouping) => {
        const options = uiGroupingMap[uiGrouping]?.options;
        const tradeList = uiGroupingMap[uiGrouping]?.tradeList;

        const majorityTradeListItem = tradeList?.reduce(
          (resultTradeListItem, currentTradeListItem) => {
            if (resultTradeListItem) {
              if (resultTradeListItem.count > currentTradeListItem.count) {
                return resultTradeListItem;
              } else {
                return currentTradeListItem;
              }
            } else {
              return currentTradeListItem;
            }
          },
          { count: 0, trade: { id: TradeId.OTHER } },
        );

        if (uiGrouping && options) {
          return (
            <Accordion
              body={
                <PillGroup
                  css={pillGroupStyles}
                  onChange={handlePillChange}
                  options={options}
                  size="small"
                  value={[selectedIssue ? selectedIssue.id : '']}
                />
              }
              header={
                <>
                  <TradeIcon
                    css={tradeIconStyles}
                    tradeId={majorityTradeListItem?.trade.id}
                  />
                  {uiGrouping}
                </>
              }
              isCollapsed={selectedUiGrouping !== uiGrouping}
              key={uiGrouping}
              onClick={() => handleAccordionClick(uiGrouping)}
            />
          );
        }

        return null;
      })}
    </div>
  );
};
