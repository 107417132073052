import { RESET_APP, SET_VIEWPORT_SIZE } from 'actions';
import { IDeviceAction, IDeviceState } from 'typings';

const defaultState: IDeviceState = {
  viewportSize: 'small',
};

export default function device(
  state: IDeviceState = defaultState,
  action: IDeviceAction,
): IDeviceState {
  switch (action.type) {
    case SET_VIEWPORT_SIZE:
      if (!action.viewportSize) {
        return state;
      }

      return {
        ...state,
        viewportSize: action.viewportSize,
      };
    case RESET_APP:
    default:
      return state;
  }
}
