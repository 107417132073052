import { ADD_UPLOADED_MEDIA_FILE_RECORD } from 'actions';

export function addUploadedMediaFileRecord(
  mediaFileRecord: File | null,
  mediaFileRecordIndex: number,
) {
  return {
    mediaFileRecord,
    mediaFileRecordIndex,
    type: ADD_UPLOADED_MEDIA_FILE_RECORD,
  };
}
