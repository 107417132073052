import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityFanSvg } from 'components/svg/RoomElectricityFan.svg';
import { ReactComponent as RoomElectricityLightBulbSvg } from 'components/svg/RoomElectricityLightBulb.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricityPanelSvg } from 'components/svg/RoomElectricityPanel.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomSceneryWasherDryerSvg } from 'components/svg/RoomSceneryWasherDryer.svg';
import { ReactComponent as RoomSinkUtilitySvg } from 'components/svg/RoomSinkUtility.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Fan,
  Light,
  Outlet,
  Panel,
  Sink,
  Switch,
  Vent,
  washerDryerStyles,
} from './styles';

export const RoomLaundry = ({ icons }: IRoomProps) => {
  const fanIcon = icons.find(({ key }) => key === RoomIconKey.EXHAUST_FAN);
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SIMPLE);
  const panelIcon = icons.find(({ key }) => key === RoomIconKey.PANEL);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const sinkIcon = icons.find(({ key }) => key === RoomIconKey.SINK_UTILITY);
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <RoomSceneryWasherDryerSvg css={washerDryerStyles} />
      <Fan>
        <RoomButton icon={fanIcon} iconSvg={<RoomElectricityFanSvg />} />
      </Fan>
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightBulbSvg />}
        />
      </Light>
      <Panel>
        <RoomButton icon={panelIcon} iconSvg={<RoomElectricityPanelSvg />} />
      </Panel>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Sink>
        <RoomButton icon={sinkIcon} iconSvg={<RoomSinkUtilitySvg />} />
      </Sink>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
