/**
 * Useful for filtering nulls and undefineds out of lists of type TType, and
 * getting back an array of TType
 * @param item
 * @returns {boolean}
 * @example
 * const list: string[] = ['a', null, undefined].filter(isNotNullOrUndefined)
 */
export function isNotNullOrUndefined<TType>(
  item: TType | null | undefined,
): item is TType {
  return item !== null && item !== undefined;
}
