import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { startSession } from 'actions';
import { IState } from 'typings';

// Apollo defines its networkError object as the standard browser Error, which is lacking statusCode, so we override
// their types with our own here
interface INetworkError extends Error {
  statusCode?: number;
}

export default function configureApolloClient(
  dispatch: ThunkDispatch<IState, null, Action>,
  getState: () => IState,
) {
  const httpLink = new HttpLink({
    uri: ({ operationName }) =>
      `${process.env.REACT_APP_GRAPHQL_URI}?opname=${operationName}`,
  });

  const authLink = new ApolloLink((operation, forward) => {
    const setHeaders = () => {
      const {
        auth: { sessionToken },
      } = getState();

      const authorization = sessionToken
        ? { Authorization: `Bearer ${sessionToken}` }
        : {};

      operation.setContext(({ headers = {} }: Record<string, any>) => ({
        headers: {
          ...headers,
          ...authorization,
        },
      }));
    };

    setHeaders();

    return new Observable((observable) => {
      let retryCount = 0;

      const subscriber = {
        complete: () => {
          retryCount = 0;
          observable.complete();
        },
        error: (error: INetworkError) => {
          if (error) {
            if (error.statusCode === 401) {
              dispatch(
                startSession({
                  shouldVerifyExistingSession: false,
                }),
              )
                .then(setHeaders)
                .then(() => {
                  if (forward) {
                    forward(operation).subscribe(subscriber);
                  } else {
                    observable.error(error);
                  }
                });
            } else if (forward) {
              // Increase wait time by 2s for each failure, with a maximum wait time of 2m
              const waitDuration = Math.min(2000 * retryCount, 120000);

              setTimeout(() => {
                retryCount++;
                forward(operation).subscribe(subscriber);
              }, waitDuration);
            } else {
              observable.error(error);
            }
          }
        },
        next: observable.next.bind(observable),
      };

      if (forward) {
        forward(operation).subscribe(subscriber);
      }
    });
  });

  const wsClient = new SubscriptionClient(
    `${process.env.REACT_APP_SUBSCRIPTIONS_URI}`,
    {
      connectionCallback: (errors: Array<Error>) => {
        if (errors && errors.length > 0) {
          wsClient.close(false, false);
        }
      },
      connectionParams: () => {
        const {
          auth: { sessionToken },
        } = getState();

        return sessionToken ? { authorization: `Bearer ${sessionToken}` } : {};
      },
      reconnect: true,
    },
  );

  const wsLink = new WebSocketLink(wsClient);

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const mainDefinition = getMainDefinition(query);

        return (
          mainDefinition.kind === 'OperationDefinition' &&
          mainDefinition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(httpLink),
    ),
  });

  return { client, wsClient };
}
