import { Dispatch } from 'redux';

import { IState, Mode } from 'typings';
import postMessageToParent from 'utils/postMessageToParent';

export function postMessageAppReady() {
  return async (_dispatch: Dispatch, getState: () => IState) => {
    const { flow, mode, validOrigins } = getState().client;

    if (mode === Mode.WEB) {
      postMessageToParent(
        { analyticsKey: flow?.key, type: 'se-app-ready' },
        validOrigins,
      );
    }
  };
}
