import { CarouselItemId } from '@homex/se-widget-flow-types';

import {
  RESET_APP,
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  SET_ACCELERATED_ENTRY_POINT,
  SET_CAROUSEL_NAV_POINTER_X,
  SET_THANK_YOU_REDIRECT_URL,
} from 'actions';
import { carousel } from 'reducers/carousel';
import {
  CarouselReduxReducer,
  IServiceCarouselAction,
  IServiceCarouselState,
} from 'typings';

const defaultState: IServiceCarouselState = {
  acceleratedEntryPoint: null,
  currentItemId: CarouselItemId.Unknown,
  history: [],
  navPointerX: 0,
  shouldRecalculateHeight: false,
  thankYouRedirectUrl: '',
};

export default function serviceCarousel(
  state: IServiceCarouselState = defaultState,
  action: IServiceCarouselAction,
): IServiceCarouselState {
  switch (action.type) {
    case RESET_SERVICE_CAROUSEL_FOR_CSR:
    case RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE:
      return {
        ...state,
        navPointerX: 0,
      };
    case RESET_APP:
      return {
        ...defaultState,
        bottomHeight: state.bottomHeight,
        topHeight: state.topHeight,
      };
    case SET_ACCELERATED_ENTRY_POINT:
      if (action.acceleratedEntryPoint === undefined) {
        return state;
      }

      return {
        ...state,
        acceleratedEntryPoint: action.acceleratedEntryPoint,
      };
    case SET_CAROUSEL_NAV_POINTER_X:
      if (typeof action.navPointerX === 'undefined') {
        return state;
      }

      return {
        ...state,
        navPointerX: action.navPointerX,
      };
    case SET_THANK_YOU_REDIRECT_URL:
      return {
        ...state,
        thankYouRedirectUrl: action.url,
      };
    default:
      if (action.reduxReducer !== CarouselReduxReducer.SERVICE) {
        return state;
      }

      return carousel(state, action);
  }
}
