import { TradeId } from '@homex/se-react-components';
import { useSelector } from 'react-redux';

import {
  setSelectedIssue,
  setSelectedRoomIcon,
  setSelectedRoomKey,
} from 'actions';
import { ReactComponent as IssueCoolingSvg } from 'components/svg/Cooling.svg';
import { ReactComponent as IssueElectricalSvg } from 'components/svg/Electrical.svg';
import { ReactComponent as IssueGarageDoor } from 'components/svg/GarageDoors.svg';
import { ReactComponent as IssueHvacSvg } from 'components/svg/HVAC.svg';
import { ReactComponent as IssueHeatingSvg } from 'components/svg/Heating.svg';
import { ReactComponent as IssueOtherSvg } from 'components/svg/IssueOther.svg';
import { ReactComponent as IssueMaintenanceSvg } from 'components/svg/Maintenance.svg';
import { ReactComponent as IssuePestControlSvg } from 'components/svg/PestControl.svg';
import { ReactComponent as IssuePlumbingSvg } from 'components/svg/Plumbing.svg';
import { ReactComponent as IssuePlumbingAltSvg } from 'components/svg/PlumbingAlt.svg';
import { ReactComponent as IssueSalesSvg } from 'components/svg/Sales.svg';
import { ReactComponent as IssueSewersSvg } from 'components/svg/Sewers.svg';
import { ReactComponent as IssueWaterQualitySvg } from 'components/svg/WaterQuality.svg';
import { useThunkDispatch } from 'hooks/useThunkDispatch';
import { IAllRoomIssuesProps, IIssue, IRoom, IState } from 'typings';

import { Button, Container, Label } from './styles';

const UI_GROUPING_TO_ISSUE_SVG_MAP: Record<string, JSX.Element> = {
  cooling: <IssueCoolingSvg />,
  electrical: <IssueElectricalSvg />,
  garage_doors: <IssueGarageDoor />,
  heating: <IssueHeatingSvg />,
  hvac: <IssueHvacSvg />,
  maintenance: <IssueMaintenanceSvg />,
  pest_control: <IssuePestControlSvg />,
  plumbing: <IssuePlumbingSvg />,
  plumbing_alt: <IssuePlumbingAltSvg />,
  sales: <IssueSalesSvg />,
  sewers: <IssueSewersSvg />,
  water_quality: <IssueWaterQualitySvg />,
};

const TRADE_ID_TO_ISSUE_SVG_MAP: Record<string, JSX.Element> = {
  [TradeId.ELECTRICAL]: <IssueElectricalSvg />,
  [TradeId.HVAC]: <IssueHvacSvg />,
  [TradeId.PREVENTATIVE_MAINTENANCE]: <IssueMaintenanceSvg />,
  [TradeId.PLUMBING]: <IssuePlumbingSvg />,
  [TradeId.SALES]: <IssueSalesSvg />,
};

export const AllRoomIssues = ({ rooms }: IAllRoomIssuesProps) => {
  const dispatch = useThunkDispatch();

  const { selectedIssue, selectedRoomIcon, selectedRoomKey } = useSelector(
    (state: IState) => ({
      selectedIssue: state.userInput.selectedIssue,
      selectedRoomIcon: state.userInput.selectedRoomIcon,
      selectedRoomKey: state.userInput.selectedRoomKey,
    }),
  );

  const getIssueSvg = (issue: IIssue) => {
    const uiGroupingSvg =
      UI_GROUPING_TO_ISSUE_SVG_MAP[issue.uiGrouping.toLowerCase()];
    const tradeIdSvg = TRADE_ID_TO_ISSUE_SVG_MAP[issue.trade.id];
    const defaultSvg = <IssueOtherSvg />;

    // Favor icon derived from `ui_grouping` field followed `trade_id` field
    return uiGroupingSvg ?? tradeIdSvg ?? defaultSvg;
  };

  const handleIssueClick = (room: IRoom, issue: IIssue) => {
    dispatch(setSelectedRoomKey(room.key));
    dispatch(setSelectedIssue(issue));
    dispatch(setSelectedRoomIcon());
  };

  return (
    <Container>
      {rooms
        .map((room) =>
          room.issues.map((issue) => {
            return (
              <Button
                isSelected={
                  selectedRoomKey === room.key &&
                  selectedIssue?.id === issue.id &&
                  !selectedRoomIcon
                }
                key={issue.id}
                onClick={() => handleIssueClick(room, issue)}
              >
                {getIssueSvg(issue)}
                <Label>{issue.name}</Label>
              </Button>
            );
          }),
        )
        .flat()}
    </Container>
  );
};
