import styled from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';

export const Image = styled.img`
  height: 242px;
  width: 302px;

  ${medium`
    height: 264px;
    width: 330px;
  `}

  ${large`
    height: 220px;
    width: 275px;
  `}
`;
