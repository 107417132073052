import { IIssue } from 'typings';

export default function isIssueImmediatelyRedirected(issue: IIssue) {
  const { info, label, notice, options, serviceCodeId, toIssueId, warn } =
    issue.specifier.details;

  return !!(
    toIssueId &&
    !info &&
    !label &&
    !notice &&
    !options &&
    !serviceCodeId &&
    !warn
  );
}
