import { IScheduleEngineMessage } from 'typings';

/**
 * The purpose of this function is to attempt to prevent logging lots of errors to the console of the client's website
 * when we send messages across frames. Instead of sending a message to all valid origins and getting errors for any
 * that don't match, we can attempt to retrieve the parent window's URL, match that URL to any valid origins, and only
 * send messages to the origins that match. The retrieval of the parent window's URL may fail, in which case we fall
 * back to sending the message to all origins.
 */
export default function postMessageToParent(
  message: IScheduleEngineMessage,
  validOrigins: Array<string>,
) {
  // Attempt to retrieve the parent window's URL
  const documentLocationHref = document.location ? document.location.href : '';
  const parentUrl =
    window.location !== window.parent.location
      ? document.referrer
      : documentLocationHref;

  // Attempt to find the valid origin that matches the parent window's URL
  const matchingOrigins = validOrigins.filter(
    (origin) => parentUrl.indexOf(origin) !== -1,
  );

  // If we found matches, use those. Otherwise, fall back to using all validOrigins
  const targetOrigins =
    matchingOrigins.length > 0 ? matchingOrigins : validOrigins;

  targetOrigins.forEach((origin) => {
    window.parent.postMessage(message, origin);
  });
}
