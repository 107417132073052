import { CustomFormCarouselItemId } from '@homex/se-widget-flow-types';
import { Action } from 'redux';

import {
  CustomerLocation,
  IAddress,
  IAppointment,
  IBlackoutPeriod,
  ICustomer,
  IIssue,
  IPartner,
  IPhonePartial,
  IRoomIcon,
  ITimeSlot,
  NotificationPreference,
  RoomKey,
  ServiceType,
  StartTimePreference,
} from 'typings';

export interface ICustomForm {
  carouselItemId: CustomFormCarouselItemId;
  data: Record<string, unknown>;
}
export interface IIssueOptionAnswer {
  index: number;
  isEmergency: boolean;
  note: string | null;
  value: string;
}

export interface IIssueStackItem {
  issue: IIssue;
  optionAnswers: Array<IIssueOptionAnswer>;
}

export enum SchedulePreference {
  FIRST_AVAILABLE = 'FIRST_AVAILABLE',
  PREFERRED_TIME = 'PREFERRED_TIME',
}

export type SchedulePreferenceTime = Exclude<
  StartTimePreference,
  StartTimePreference.FIRST_AVAILABLE
>;

export type MediaIdsTuple = [
  string | null,
  string | null,
  string | null,
  string | null,
];

export type UploadedMediaFileRecords = [
  File | null,
  File | null,
  File | null,
  File | null,
];

export interface IUserInputState {
  address: IAddress;
  appointmentId?: string;
  blackoutPeriod?: IBlackoutPeriod;
  contactInfoAllowLocationsNotInServiceZone: boolean;
  contactInfoCustomerLocations: Array<CustomerLocation>;
  contactInfoPhoneInputValue: string;
  containsXssInput: boolean;
  currentCustomer?: ICustomer;
  customForms: Record<CustomFormCarouselItemId, Record<string, unknown>>;
  customerLocationPhone: IPhonePartial;
  customerLookupAllowLocationsNotInServiceZone: boolean;
  customerLookupCustomerLocations: Array<CustomerLocation>;
  customerLookupPhoneInputValue: string;
  customerPhone: IPhonePartial;
  email?: string;
  firstName?: string;
  isAuthorizedForRepairs: boolean;
  isDispatchFeeAccepted: boolean;
  isEmergency: boolean;
  isIssueSpecifierDropdownTooltipVisible: boolean;
  isIssueSpecifierListViewTooltipVisible: boolean;
  isIssueSpecifierListViewVisible: boolean;
  isPreviousCustomer?: boolean;
  issueStack: Array<IIssueStackItem>;
  lastName?: string;
  leadId?: string;
  leadSourceCompanionFieldValue?: string;
  leadSourceId?: string;
  loginPhone: IPhonePartial;
  mediaIds: MediaIdsTuple;
  mediaUploadSelection?: IBrowserImage;
  notificationPreference: Array<NotificationPreference>;
  preferredPartner?: IPartner;
  repairComments?: string;
  seActionAppointmentId?: string;
  selectedEnergySources: Array<string>;
  selectedEquipment: Array<string>;
  selectedIssue?: IIssue;
  selectedRoomIcon?: IRoomIcon;
  selectedRoomKey?: RoomKey;
  selectedSchedulePreference?: SchedulePreference;
  selectedSchedulePreferenceDay?: number;
  selectedSchedulePreferenceTime?: SchedulePreferenceTime;
  selectedTimeSlot?: ITimeSlot;
  selectedUiGrouping?: string;
  serviceCodeId?: string;
  serviceType?: ServiceType;
  serviceTypeRadioValue?: ServiceType;
  serviceZoneTimezone?: string;
  systemComments?: string;
  uploadedMediaFileRecords: UploadedMediaFileRecords;
}

export interface IBrowserImage {
  name: string;
  preview: string;
  type: string;
}

export interface IUserInputAction extends Action, Partial<IUserInputState> {
  appointment?: IAppointment;
  customForm?: ICustomForm;
  issue?: IIssue;
  issueId?: string;
  mediaFileRecord?: File;
  mediaFileRecordIndex?: number;
  mediaId?: string;
  mediaIdIndex?: number;
  optionAnswer?: IIssueOptionAnswer;
  questionIndex?: number;
  userInput?: Partial<IUserInputState>;
}
