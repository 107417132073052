import styled from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const Light = styled.div`
  height: 70px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: -1px;
  width: 59px;

  ${medium`
    height: 75px;
    margin-left: -32px;
    top: -12px;
    width: 63px;
  `}

  ${large`
    top: 0;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 61%;
  position: absolute;
  top: 86px;
  width: 14px;

  ${medium`
    height: 25px;
    left: 219px;
    top: 65px;
    width: 15px;
  `}

  ${large`
    left: 268px;
    top: 92px;
  `}
`;

export const Outlet = styled.div`
  height: 23px;
  left: 34%;
  position: absolute;
  top: 86px;
  width: 14px;

  ${medium`
    top: 64px;
    height: 25px;
    left: 119px;
    width: 15px;
  `}

  ${large`
    left: 160px;
    top: 92px;
  `}
`;

export const Fan = styled.div`
  height: 36px;
  left: 13.1%;
  position: absolute;
  top: 20px;
  width: 36px;

  ${medium`
    height: 39px;
    left: 47px;
    top: 18px;
    width: 39px;
  `}

  ${large`
    left: 51px;
    top: 37px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 22px;
  left: 26%;
  position: absolute;
  width: 51px;

  ${medium`
    height: 23px;
    left: 93px;
    width: 54px;
  `}

  ${large`
    left: 114px;
  `}
`;

export const Shower = styled.div`
  bottom: 100px;
  height: 146px;
  position: absolute;
  right: 0;
  width: 124px;

  ${medium`
    bottom: 96px;
    height: 164px;
    right: 0;
    width: 136px;
  `}

  ${large`
    right: 6px;
  `}
`;

export const Sink = styled.div`
  bottom: 101px;
  height: 102px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  width: 59px;

  ${medium`
    height: 112px;
    margin-left: -32px;
    width: 65px;
  `}
`;

export const Toilet = styled.div`
  bottom: 101px;
  height: 79px;
  left: 0;
  position: absolute;
  width: 78px;

  ${medium`
    height: 87px;
    width: 85px;
  `}

  ${large`
    left: 5px;
  `}
`;
