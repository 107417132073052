import {
  LOGOUT,
  REGISTER_DEVICE_SUCCESS,
  RESET_APP,
  RESET_SERVICE_CAROUSEL_FOR_CSR,
  RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE,
  START_SESSION_SUCCESS,
  UPGRADE_SESSION_SUCCESS,
} from 'actions';
import { IAuthAction, IAuthState } from 'typings';

const defaultState: IAuthState = {};

export default function auth(
  state: IAuthState = defaultState,
  action: IAuthAction,
): IAuthState {
  switch (action.type) {
    case REGISTER_DEVICE_SUCCESS:
      return {
        ...state,
        deviceToken: action.deviceToken,
      };
    case START_SESSION_SUCCESS:
      return {
        ...state,
        sessionToken: action.sessionToken,
      };

    case LOGOUT:
    case RESET_SERVICE_CAROUSEL_FOR_CSR:
      return { ...state, sessionToken: undefined };

    case RESET_APP:
      return defaultState;
    case UPGRADE_SESSION_SUCCESS:
      return {
        ...state,
        sessionToken: action.sessionToken,
      };
    case RESET_SERVICE_CAROUSEL_FOR_FLOW_CHANGE:
    default:
      return state;
  }
}
