import { SET_GOOGLE_ANALYTICS_TRACKING_ENABLED } from 'actions';

export function setGoogleAnalyticsTrackingEnabled(
  googleAnalyticsTrackingEnabled: boolean,
) {
  return {
    googleAnalyticsTrackingEnabled,
    type: SET_GOOGLE_ANALYTICS_TRACKING_ENABLED,
  };
}
