import { Dispatch } from 'redux';

import { IState } from 'typings';
import postMessageToParent from 'utils/postMessageToParent';

export function postMessagePreviewReady() {
  return async (_dispatch: Dispatch, getState: () => IState) => {
    const { validOrigins } = getState().client;

    postMessageToParent({ type: 'se-preview-ready' }, validOrigins);
  };
}
