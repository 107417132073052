import { lazy } from 'react';

/**
 * Adds `waitTime` ms before the component chunk starts downloading. Can be used
 * exactly like React.lazy, but accepts a second param to specify how long to
 * wait before the download starts
 */
export default function lazyAndWait<
  TComponentType extends React.ComponentType<any>,
>(
  factory: () => Promise<{
    default: TComponentType;
  }>,
  waitTime = 0,
) {
  return lazy(
    () =>
      new Promise<{ default: TComponentType }>((resolve) => {
        factory().then((result) => {
          setTimeout(() => resolve(result), waitTime);
        });
      }),
  );
}
