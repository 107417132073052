import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const Wall = styled.div`
  background: #d7d7d7;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 166px;

  ${medium`
    width: 183px;
  `}

  ${large`
    width: 220px;
    height: 245px;
  `}
`;

export const treeStyles = css`
  height: 178px;
  position: absolute;
  right: -49px;
  width: 162px;

  ${medium`
    height: 189px;
    top: 0;
    right: -47px;
    width: 172px;
  `}

  ${large`
    right: -2px;
    top: -2px;
  `}
`;

export const Outlet = styled.div`
  bottom: 133px;
  height: 23px;
  left: 3.2%;
  position: absolute;
  width: 13px;

  ${medium`
    height: 25px;
    left: 17px;
    width: 15px;
  `}

  ${large`
    left: 44px;
  `}
`;

export const Light = styled.div`
  height: 68px;
  left: 164px;
  position: absolute;
  top: 21px;
  width: 65px;

  ${medium`
    height: 78px;
    left: 183px;
    top: 7px;
    width: 67px;
  `}

  ${large`
    top: 10px;
    left: 220px;
  `}
`;

export const Hvac = styled.div`
  bottom: 100px;
  height: 99px;
  left: 40px;
  position: absolute;
  width: 119px;

  ${medium`
    height: 105px;
    left: 49px;
    width: 126px;
  `}

  ${large`
    left: 84px;
  `}
`;

export const Spigot = styled.div`
  bottom: 143px;
  height: 29px;
  left: 166px;
  position: absolute;
  width: 28px;

  ${medium`
    bottom: 146px;
    height: 31px;
    left: 183px;
    width: 30px;
  `}

  ${large`
    bottom: 154px;
    left: 220px;
  `}
`;
