import { Action } from 'redux';

export enum DateTimeFormat {
  DATETIME_VCAL = 'YYYYMMDDTHHmmss\\Z',
  // aligns with moment.HTML5_FMT.DATE
  DATE_COMPARISON = 'YYYY-MM-DD',
  DATE_HEADER = 'dddd, MMMM D',
  DATE_ICS_FILE_NAME = 'MMDDYY',
  DATE_NAV = 'M/D',
  MONTH_AND_DATE = 'MMMM D',
  // aligns with moment.HTML5_FMT.MONTH
  MONTH_COMPARISON = 'YYYY-MM',
  MONTH_HEADER = 'MMMM',
  TIME_END = 'h:mma',
  TIME_START = 'h:mma',
}

export interface ICalendarState {
  // Always must be in DATE_COMPARISON format
  selectedCalendarDate?: string;
  // Always must be in MONTH_COMPARISON format. When `null`, indicates the
  // timeSlotInfoForMonth query is returning the first month with availability
  selectedCalendarMonth: string | null;
}

export interface ICalendarAction extends Action, Partial<ICalendarState> {}

export enum CalendarService {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  OUTLOOK = 'OUTLOOK',
}

export interface ICalendarEvent {
  appointmentId: string;
  description: string;
  endTime: string;
  fileName: string;
  location: string;
  startTime: string;
  title: string;
}

export interface ICalendarEventDownloadButtonProps {
  calendarService: CalendarService;
  event: ICalendarEvent;
}
