import { SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE } from 'actions';

export function setIsIssueSpecifierListViewVisible(
  isIssueSpecifierListViewVisible: boolean,
) {
  return {
    isIssueSpecifierListViewVisible,
    type: SET_IS_ISSUE_SPECIFIER_LIST_VIEW_VISIBLE,
  };
}
