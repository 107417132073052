import styled from 'styled-components/macro';

import { description, eyebrowThin, inputSmall } from 'styles/typography';
import { IWithThemeProps } from 'typings';

interface IButtonProps {
  isSelected: boolean;
}

export const Button = styled.button<IButtonProps>`
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 1 0 auto;
  height: 100%;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0;

  ${({ theme }: IWithThemeProps) => `
    [data-svg-class='RoomButton-fill'] {
      fill: ${theme.bottomBackgroundColor};
      transition: fill ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='RoomButton-fill-alt'] {
      fill: ${theme.bottomForegroundPrimary};
      transition: fill ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='RoomButton-stroke'] {
      stroke: ${theme.bottomForegroundPrimary};
      transition: stroke ${theme.transitionDurationPrimary} ease;
    }

    [data-svg-class='RoomButton-stroke-alt'] {
      stroke: ${theme.bottomForegroundPrimary};
      transition: stroke ${theme.transitionDurationPrimary} ease;
    }

    &:disabled {
      cursor: default;

      [data-svg-class='RoomButton-fill'] {
        fill: ${theme.svgFillDisabled};
      }

      [data-svg-class='RoomButton-fill-alt'] {
        fill: ${theme.bottomForegroundActionSecondary};
      }

      [data-svg-class='RoomButton-stroke'] {
        stroke: ${theme.bottomForegroundActionSecondary};
      }

      [data-svg-class='RoomButton-stroke-alt'] {
        stroke: ${theme.svgFillDisabled};
      }

      [data-svg-class='RoomButton-gradient'] {
        display: none;
      }
    }
  `}

  > svg {
    height: auto;
    max-width: 100%;
  }

  ${({ isSelected, theme }) => {
    if (isSelected) {
      return `
        [data-svg-class='RoomButton-fill'] {
          fill: ${theme.roomButtonFillColor};
        }

        [data-svg-class='RoomButton-fill-alt'] {
          fill: ${theme.bottomForegroundActionSecondary};
        }

        [data-svg-class='RoomButton-stroke'] {
          stroke: ${theme.bottomForegroundActionSecondary};
        }

        [data-svg-class='RoomButton-stroke-alt'] {
          stroke: ${theme.bottomForegroundActionPrimary};
        }
      `;
    }

    return '';
  }}
`;

export const Label = styled.p`
  ${inputSmall}

  display: block;
  margin: 9px 0 0;
  text-align: center;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}
`;

export const TooltipContent = styled.div`
  margin: -15px 0 -15px -15px;
  min-width: 240px;
`;

export const TooltipHeader = styled.h3`
  align-items: center;
  display: flex;
  margin: 2px 0 0 9px;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.contextBubbleInvertForegroundColor};
    font: 400 21px/25px ${theme.fontFamilyPrimary};
  `}
`;

export const TooltipHeaderText = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

export const TooltipSubhead = styled.h4`
  ${eyebrowThin}

  margin: -1px 0 0 20px;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}
`;

export const TooltipBody = styled.p`
  ${description}

  margin: -2px 0 10px 20px;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}
`;
