import { CarouselItemId } from '@homex/se-widget-flow-types';
import moment from 'moment-timezone';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  hideAppointmentModal,
  setCarouselHistory,
  setCurrentCarouselItem,
  setServiceType,
  setServiceTypeRadioValue,
} from 'actions';
import { CarouselReduxReducer, IState, ServiceType } from 'typings';
import { getAppointmentsFromCustomer } from 'utils/appointments';
import { getDefaultServiceCarouselItemId } from 'utils/getDefaultServiceCarouselItemId';

/**
 * Resets the carousel's currentItemId to either SERVICE_TYPE, or the beginning
 * item for the allowed flow based on validServiceTypes. By default, it will
 * only reset if the existing currentItemId is not in the allowed flow. This
 * allows the user to keep their place in the carousel. If more than one
 * ServiceType exists in validServiceTypes, by default, this function will do
 * nothing.
 * @param force Defaults to false. If true, this function will reset the
 * carousel's currentItemId and disregard the previous currentItemId
 */
export function setCurrentCarouselItemToDefault(
  reduxReducer: CarouselReduxReducer,
  force = false,
) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    switch (reduxReducer) {
      case CarouselReduxReducer.APPOINTMENT: {
        const {
          userInput: { currentCustomer, seActionAppointmentId },
        } = getState();

        const appointments = getAppointmentsFromCustomer(currentCustomer);

        let nextAppointmentId = seActionAppointmentId;

        if (!nextAppointmentId && appointments.length > 0) {
          // Find closest date to the start of today and set that as defaultAppointment
          const today = moment().startOf('D');

          const nextAppointment = appointments.reduce(
            (resultAppointment, currentAppointment) => {
              const resultAppointmentDiff = moment(
                resultAppointment.serviceDate,
              ).diff(today);
              const currentAppointmentDiff = moment(
                currentAppointment.serviceDate,
              ).diff(today);

              // If both appointments are in the future get the appointment closer to today
              if (resultAppointmentDiff > 0 && currentAppointmentDiff > 0) {
                return resultAppointmentDiff < currentAppointmentDiff
                  ? resultAppointment
                  : currentAppointment;
              }
              // If both appointments are in the past get the appointment closer to today
              else if (
                resultAppointmentDiff < 0 &&
                currentAppointmentDiff < 0
              ) {
                return resultAppointmentDiff > currentAppointmentDiff
                  ? resultAppointment
                  : currentAppointment;
              } else {
                // return the appointment in the future
                return resultAppointmentDiff > currentAppointmentDiff
                  ? resultAppointment
                  : currentAppointment;
              }
            },
          );

          if (nextAppointment) {
            nextAppointmentId = nextAppointment.id;
          }
        }

        if (nextAppointmentId) {
          dispatch(
            setCurrentCarouselItem(
              reduxReducer,
              `${CarouselItemId.Appointment}-${nextAppointmentId}` as CarouselItemId,
            ),
          );
        } else {
          dispatch(hideAppointmentModal());
        }

        break;
      }

      case CarouselReduxReducer.SERVICE: {
        const {
          client: { flow, preferredServiceType, validServiceTypes },
          serviceCarousel: { currentItemId },
        } = getState();

        const [validServiceType] = validServiceTypes;
        const serviceType =
          preferredServiceType || validServiceType || ServiceType.REPAIR;
        const defaultCarouselItemId = getDefaultServiceCarouselItemId(
          validServiceTypes,
          preferredServiceType,
          flow,
        );

        // When there is a flow, service type really shouldn't matter, but
        // because some logic still relies on it, we'll safely assume REPAIR. At
        // the time of the flow implementation SALES wasn't used. There will
        // likely be an effort in the future to remove ServiceType completely.
        if (flow) {
          dispatch(setServiceType(ServiceType.REPAIR));
          dispatch(setServiceTypeRadioValue(ServiceType.REPAIR));
        }

        // We only want to set the user's service type and change their starting
        // carousel item if there is one valid service type. Otherwise, we can
        // handle 2 and 3 service types in CarouselItemSelectServiceType
        if (validServiceTypes.length === 1 || preferredServiceType) {
          dispatch(setServiceType(serviceType));
          dispatch(setServiceTypeRadioValue(serviceType));
        }

        if (force || currentItemId === CarouselItemId.Unknown) {
          await dispatch(
            setCurrentCarouselItem(reduxReducer, defaultCarouselItemId),
          );
          dispatch(setCarouselHistory(reduxReducer, [defaultCarouselItemId]));
        }

        break;
      }

      default:
        // Do nothing
        break;
    }
  };
}
