/**
 * DO NOT EDIT THIS FILE
 * These types are generated automatically. All edits will be overwritten.
 * To update these types, run `npm run graphql:codegen`.
 */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable typescript-sort-keys/interface */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The DateTime scalar type represents date time strings complying to ISO-8601. */
  DateTime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
}

export interface IAbandonLeadResult {
  __typename?: 'AbandonLeadResult';
  id: Scalars['ID'];
}

export interface IAccountTracking {
  __typename?: 'AccountTracking';
  organizationId: Scalars['String'];
  personId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
}

export interface IAccountTrackingInput {
  organizationId: Scalars['String'];
  personId: Scalars['String'];
  propertyId?: Maybe<Scalars['String']>;
}

export interface IAddress {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  zipCode: Scalars['String'];
}

export interface IAddressBookingStatusForCustomerResult {
  __typename?: 'AddressBookingStatusForCustomerResult';
  isBookingAllowed: Scalars['Boolean'];
  isExisting: Scalars['Boolean'];
  isServiceable: Scalars['Boolean'];
}

export interface IAddressInput {
  city: Scalars['String'];
  state: Scalars['String'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
}

export interface IAppointment {
  __typename?: 'Appointment';
  accountTracking?: Maybe<IAccountTracking>;
  customerNotes?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTentative: Scalars['Boolean'];
  jobStatus: Scalars['String'];
  location: ILocation;
  serviceCode: IServiceCode;
  serviceDate: Scalars['DateTime'];
  serviceZone: IServiceZone;
  sourceTracking?: Maybe<ISourceTracking>;
  technician?: Maybe<IAppointmentTechnician>;
  timeSlot: ITimeSlot;
  trade: ITrade;
}

export interface IAppointmentBooking {
  __typename?: 'AppointmentBooking';
  id: Scalars['ID'];
}

export enum AppointmentExportType {
  REQUESTED = 'REQUESTED',
  SCHEDULED = 'SCHEDULED',
}

export interface IAppointmentTechnician extends ITechnicianInterface {
  __typename?: 'AppointmentTechnician';
  avatarBannerMediaId: Scalars['String'];
  avatarMediaId: Scalars['String'];
  bio: Scalars['String'];
  currentLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  estimatedArrivalTime?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  title: Scalars['String'];
}

export interface IAvailabilityDetail {
  __typename?: 'AvailabilityDetail';
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message: Scalars['String'];
  start: Scalars['DateTime'];
  timestamp: Scalars['DateTime'];
}

export interface IAvailablePartnersForTimeSlotResult {
  __typename?: 'AvailablePartnersForTimeSlotResult';
  partners: Array<IPartner>;
  serviceCode: IServiceCode;
  subcontractor?: Maybe<IPartner>;
  timeSlot: ITimeSlot;
}

export interface IBadge {
  __typename?: 'Badge';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoFileId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface IBlackoutPeriod {
  __typename?: 'BlackoutPeriod';
  days: Scalars['Int'];
  id: Scalars['ID'];
  isNonContractOnly: Scalars['Boolean'];
  message: Scalars['String'];
  trade: ITrade;
}

export enum BookingAccess {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export interface IBookingOptionsInput {
  accountTracking?: Maybe<IAccountTrackingInput>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isOnDemand?: Maybe<Scalars['Boolean']>;
  leadId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Array<IMetadataEntry>>;
  notes?: Maybe<Array<Scalars['String']>>;
  selectedEnergySources?: Maybe<Array<Scalars['String']>>;
  selectedEquipment?: Maybe<Array<Scalars['String']>>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  sourceTracking?: Maybe<ISourceTrackingInput>;
  startTimePreference?: Maybe<StartTimePreference>;
  technicianId?: Maybe<Scalars['ID']>;
  userComments?: Maybe<Array<IUserComment>>;
}

export interface IBookingRequestResult {
  __typename?: 'BookingRequestResult';
  appointment?: Maybe<IAppointmentBooking>;
  errorCode?: Maybe<ErrorCode>;
  lead?: Maybe<ILeadBooking>;
}

export interface IBranch {
  __typename?: 'Branch';
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface IClient {
  __typename?: 'Client';
  branches: Array<IBranch>;
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface ICorrectedAddress {
  __typename?: 'CorrectedAddress';
  address: IAddress;
  isMatch: Scalars['Boolean'];
}

export interface ICorrectedAddressesResult {
  __typename?: 'CorrectedAddressesResult';
  correctedAddresses: Array<ICorrectedAddress>;
}

export interface ICorrectedPhoneNumberResult {
  __typename?: 'CorrectedPhoneNumberResult';
  isValid: Scalars['Boolean'];
  phone?: Maybe<IPhone>;
}

export interface ICustomer {
  __typename?: 'Customer';
  contact: ICustomerContact;
  id: Scalars['ID'];
  locations: Array<ILocation>;
  phone: IPhone;
}

export interface ICustomerContact {
  __typename?: 'CustomerContact';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
}

export interface ICustomerInput {
  addressId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notificationPreference: Array<NotificationPreference>;
  phoneId: Scalars['ID'];
  phoneIdForNotification?: Maybe<Scalars['ID']>;
}

export interface ICustomerLocation {
  __typename?: 'CustomerLocation';
  customer: ICustomer;
  isBookingAllowed: Scalars['Boolean'];
  isInServiceZone: Scalars['Boolean'];
  location: ILocation;
}

export interface ICustomerLocationsByPhoneResult {
  __typename?: 'CustomerLocationsByPhoneResult';
  allowLocationsNotInServiceZone: Scalars['Boolean'];
  customerLocations: Array<ICustomerLocation>;
  doNotServiceCustomer?: Maybe<Scalars['Boolean']>;
}

export enum ErrorCode {
  ERROR_ADDRESS_INVALID = 'ERROR_ADDRESS_INVALID',
  ERROR_TIMESLOT_UNAVAILABLE = 'ERROR_TIMESLOT_UNAVAILABLE',
}

export interface IIssue {
  __typename?: 'Issue';
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isEmergency: Scalars['Boolean'];
  isGeneral: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  specifier: IIssueSpec;
  trade: ITrade;
  uiGrouping: Scalars['String'];
}

export interface IIssueSpec {
  __typename?: 'IssueSpec';
  details: IIssueSpecDetail;
  next?: Maybe<Array<Maybe<IIssueSpecNext>>>;
  title?: Maybe<Scalars['String']>;
}

export interface IIssueSpecDetail {
  __typename?: 'IssueSpecDetail';
  info?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  notice?: Maybe<Scalars['String']>;
  options?: Maybe<Array<IIssueSpecOption>>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  toIssueId?: Maybe<Scalars['ID']>;
  warn?: Maybe<Scalars['String']>;
}

export interface IIssueSpecNext {
  __typename?: 'IssueSpecNext';
  details: IIssueSpecDetail;
  value: Scalars['String'];
}

export interface IIssueSpecOption {
  __typename?: 'IssueSpecOption';
  isEmergency?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  nextValue?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  toIssueId?: Maybe<Scalars['ID']>;
}

export interface ILeadAddress {
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
}

export interface ILeadBooking {
  __typename?: 'LeadBooking';
  id: Scalars['ID'];
}

export interface ILeadCustomerInput {
  address?: Maybe<ILeadAddress>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationPreference?: Maybe<Array<NotificationPreference>>;
  phoneId?: Maybe<Scalars['ID']>;
  phoneIdForNotification?: Maybe<Scalars['ID']>;
}

export interface ILeadSource {
  __typename?: 'LeadSource';
  companionFieldLabel?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface ILocality {
  __typename?: 'Locality';
  city: Scalars['String'];
  countryCode: Scalars['String'];
  state: Scalars['String'];
}

export interface ILocation {
  __typename?: 'Location';
  addressId: Scalars['ID'];
  appointments: Array<IAppointment>;
  bookingAccess: BookingAccess;
  city: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBySource: Scalars['String'];
  customers: Array<ICustomer>;
  id: Scalars['ID'];
  name: Scalars['String'];
  point: Array<Scalars['Float']>;
  state: Scalars['String'];
  status: LocationStatus;
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  type: Scalars['String'];
  zipCode: Scalars['String'];
}

export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IMedia {
  __typename?: 'Media';
  durationSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type: MediaType;
}

export enum MediaType {
  IMAGE = 'IMAGE',
  UNKNOWN = 'UNKNOWN',
  VIDEO = 'VIDEO',
}

export interface IMetadataEntry {
  key: Scalars['String'];
  ns: MetadataNamespace;
  value: Scalars['JSON'];
}

export enum MetadataNamespace {
  SERVICECHANNEL = 'SERVICECHANNEL',
  SERVICETITAN = 'SERVICETITAN',
}

export interface IMutation {
  __typename?: 'Mutation';
  abandonLead: IAbandonLeadResult;
  registerAddress?: Maybe<IRegisterAddressResult>;
  requestPhoneCall?: Maybe<Scalars['Boolean']>;
  setLeadSource?: Maybe<ISetLeadSourceResult>;
  submitBookingRequest?: Maybe<IBookingRequestResult>;
  submitLead: ISubmitLeadResult;
}

export interface IMutationAbandonLeadArgs {
  abandonmentReason?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}

export interface IMutationRegisterAddressArgs {
  address: IAddressInput;
}

export interface IMutationRequestPhoneCallArgs {
  isEmergency: Scalars['Boolean'];
}

export interface IMutationSetLeadSourceArgs {
  appointmentId: Scalars['ID'];
  companionFieldValue?: Maybe<Scalars['String']>;
  selectedFieldId: Scalars['ID'];
}

export interface IMutationSubmitBookingRequestArgs {
  customer: ICustomerInput;
  options?: Maybe<IBookingOptionsInput>;
  serviceType: ServiceType;
  startTime: Scalars['DateTime'];
}

export interface IMutationSubmitLeadArgs {
  customer?: Maybe<ILeadCustomerInput>;
  options?: Maybe<IBookingOptionsInput>;
  serviceType?: Maybe<ServiceType>;
  startTime?: Maybe<Scalars['DateTime']>;
}

export enum NotificationPreference {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export interface IOption {
  __typename?: 'Option';
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface IPartner {
  __typename?: 'Partner';
  address: IPartnerAddress;
  distanceInMeters?: Maybe<Scalars['Float']>;
  isSubcontractorMatch: Scalars['Boolean'];
  name: Scalars['String'];
  partnerId: Scalars['ID'];
}

export interface IPartnerAddress {
  __typename?: 'PartnerAddress';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  locality: Scalars['String'];
  postalCode: Scalars['String'];
  region: Scalars['String'];
}

export interface IPhone {
  __typename?: 'Phone';
  id: Scalars['ID'];
  isMobile: Scalars['Boolean'];
  number: Scalars['String'];
}

export interface IQuery {
  __typename?: 'Query';
  appointment?: Maybe<IAppointment>;
  availablePartnersForTimeslot: IAvailablePartnersForTimeSlotResult;
  badges: Array<IBadge>;
  blackoutPeriod?: Maybe<IBlackoutPeriod>;
  currentClient?: Maybe<IClient>;
  currentCustomer?: Maybe<ICustomer>;
  customer?: Maybe<ICustomer>;
  energyOptions: Array<IOption>;
  equipmentOptions: Array<IOption>;
  getAddressBookingStatusForCustomer?: Maybe<IAddressBookingStatusForCustomerResult>;
  getCorrectedAddresses?: Maybe<ICorrectedAddressesResult>;
  getCorrectedPhoneNumber?: Maybe<ICorrectedPhoneNumberResult>;
  getCustomerLocationsByPhone?: Maybe<ICustomerLocationsByPhoneResult>;
  getServiceZoneFromZipCode?: Maybe<IServiceZoneFromZipCodeResult>;
  issue?: Maybe<IIssue>;
  issues: Array<IIssue>;
  leadSources: Array<ILeadSource>;
  room?: Maybe<IRoom>;
  rooms: Array<IRoom>;
  serviceCode?: Maybe<IServiceCode>;
  serviceZone?: Maybe<IServiceZone>;
  timeSlotInfoForMonth: ITimeSlotInfoResult;
  timeSlotsForMonth: Array<ITimeSlot>;
}

export interface IQueryAppointmentArgs {
  id: Scalars['ID'];
}

export interface IQueryAvailablePartnersForTimeslotArgs {
  addressId: Scalars['String'];
  badgeIds?: Maybe<Array<Scalars['ID']>>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  serviceCodeId: Scalars['ID'];
  serviceType: ServiceType;
  startTime: Scalars['DateTime'];
  subcontractor?: Maybe<Scalars['String']>;
}

export interface IQueryBlackoutPeriodArgs {
  tradeId: Scalars['ID'];
}

export interface IQueryCustomerArgs {
  id: Scalars['String'];
}

export interface IQueryGetAddressBookingStatusForCustomerArgs {
  address: IAddressInput;
  enterpriseVersion?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  serviceType: ServiceType;
}

export interface IQueryGetCorrectedAddressesArgs {
  address: IAddressInput;
}

export interface IQueryGetCorrectedPhoneNumberArgs {
  countryCode?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
}

export interface IQueryGetCustomerLocationsByPhoneArgs {
  phoneNumber: Scalars['String'];
  serviceType: ServiceType;
}

export interface IQueryGetServiceZoneFromZipCodeArgs {
  enterpriseVersion?: Maybe<Scalars['String']>;
  serviceType?: Maybe<ServiceType>;
  zipCode: Scalars['String'];
}

export interface IQueryIssueArgs {
  id: Scalars['ID'];
}

export interface IQueryIssuesArgs {
  flowId?: Maybe<Scalars['ID']>;
}

export interface IQueryLeadSourcesArgs {
  serviceType: ServiceType;
}

export interface IQueryRoomArgs {
  id?: Maybe<Scalars['ID']>;
}

export interface IQueryRoomsArgs {
  flowId?: Maybe<Scalars['ID']>;
}

export interface IQueryServiceCodeArgs {
  id: Scalars['ID'];
}

export interface IQueryServiceZoneArgs {
  id: Scalars['ID'];
}

export interface IQueryTimeSlotInfoForMonthArgs {
  addressId?: Maybe<Scalars['String']>;
  campaignMemberId?: Maybe<Scalars['ID']>;
  enterpriseVersion?: Maybe<Scalars['String']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  phoneId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  serviceType: ServiceType;
  year?: Maybe<Scalars['Int']>;
}

export interface IQueryTimeSlotsForMonthArgs {
  addressId?: Maybe<Scalars['String']>;
  campaignMemberId?: Maybe<Scalars['ID']>;
  enterpriseVersion?: Maybe<Scalars['String']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  phoneId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  serviceType: ServiceType;
  year?: Maybe<Scalars['Int']>;
}

export interface IRegisterAddressResult {
  __typename?: 'RegisterAddressResult';
  address?: Maybe<IAddress>;
  errorCode?: Maybe<ErrorCode>;
  errorMessage?: Maybe<Scalars['String']>;
}

export interface IRoom {
  __typename?: 'Room';
  icons: Array<IRoomIcon>;
  id: Scalars['ID'];
  issues: Array<IIssue>;
  key: Scalars['String'];
  name: Scalars['String'];
}

export interface IRoomIcon {
  __typename?: 'RoomIcon';
  commonProblems?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issue?: Maybe<IIssue>;
  key: Scalars['String'];
  name: Scalars['String'];
  serviceCode?: Maybe<IServiceCode>;
}

export interface IServiceCode {
  __typename?: 'ServiceCode';
  description?: Maybe<Scalars['String']>;
  icon: IServiceCodeIcon;
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
  trade: ITrade;
}

export interface IServiceCodeIcon {
  __typename?: 'ServiceCodeIcon';
  key: Scalars['String'];
}

export enum ServiceType {
  MAINTENANCE = 'MAINTENANCE',
  REPAIR = 'REPAIR',
  SALES = 'SALES',
}

export interface IServiceZone {
  __typename?: 'ServiceZone';
  id: Scalars['ID'];
  name: Scalars['String'];
  timezone: Scalars['String'];
}

export interface IServiceZoneFromZipCodeResult {
  __typename?: 'ServiceZoneFromZipCodeResult';
  isInServiceZone: Scalars['Boolean'];
  locality?: Maybe<ILocality>;
  serviceZoneTimezone?: Maybe<Scalars['String']>;
}

export interface ISetLeadSourceResult {
  __typename?: 'SetLeadSourceResult';
  leadSource: ILeadSource;
}

export interface ISourceTracking {
  __typename?: 'SourceTracking';
  affiliate?: Maybe<Scalars['String']>;
  bookingSource?: Maybe<Scalars['String']>;
  bookingSourceUrl?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  broker?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  clid?: Maybe<Scalars['String']>;
  clientReferrer?: Maybe<Scalars['String']>;
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  customField4?: Maybe<Scalars['String']>;
  customField5?: Maybe<Scalars['String']>;
  customField6?: Maybe<Scalars['String']>;
  customField7?: Maybe<Scalars['String']>;
  customField8?: Maybe<Scalars['String']>;
  customField9?: Maybe<Scalars['String']>;
  customField10?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  leadSource?: Maybe<Scalars['String']>;
  marketingSource?: Maybe<Scalars['String']>;
  preferredPartner?: Maybe<Scalars['String']>;
  rwgToken?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
}

export interface ISourceTrackingInput {
  affiliate?: Maybe<Scalars['String']>;
  bookingSource?: Maybe<Scalars['String']>;
  bookingSourceUrl?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  broker?: Maybe<Scalars['String']>;
  campaign?: Maybe<Scalars['String']>;
  clid?: Maybe<Scalars['String']>;
  clientReferrer?: Maybe<Scalars['String']>;
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  customField4?: Maybe<Scalars['String']>;
  customField5?: Maybe<Scalars['String']>;
  customField6?: Maybe<Scalars['String']>;
  customField7?: Maybe<Scalars['String']>;
  customField8?: Maybe<Scalars['String']>;
  customField9?: Maybe<Scalars['String']>;
  customField10?: Maybe<Scalars['String']>;
  fbclid?: Maybe<Scalars['String']>;
  flowSource?: Maybe<Scalars['String']>;
  gclid?: Maybe<Scalars['String']>;
  leadSource?: Maybe<Scalars['String']>;
  preferredPartner?: Maybe<Scalars['String']>;
  rwgToken?: Maybe<Scalars['String']>;
  subcontractor?: Maybe<Scalars['String']>;
  utmCampaign?: Maybe<Scalars['String']>;
  utmContent?: Maybe<Scalars['String']>;
  utmMedium?: Maybe<Scalars['String']>;
  utmSource?: Maybe<Scalars['String']>;
  utmTerm?: Maybe<Scalars['String']>;
}

export enum StartTimePreference {
  AFTERNOON = 'AFTERNOON',
  FIRST_AVAILABLE = 'FIRST_AVAILABLE',
  MORNING = 'MORNING',
}

export interface ISubmitLeadResult {
  __typename?: 'SubmitLeadResult';
  errorCode?: Maybe<ErrorCode>;
  lead?: Maybe<ILeadBooking>;
}

export interface ISubscription {
  __typename?: 'Subscription';
  currentCustomerChanged?: Maybe<ICustomer>;
}

export interface ITechnician extends ITechnicianInterface {
  __typename?: 'Technician';
  avatarBannerMediaId: Scalars['String'];
  avatarMediaId: Scalars['String'];
  bio: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  title: Scalars['String'];
}

export interface ITechnicianInterface {
  avatarBannerMediaId: Scalars['String'];
  avatarMediaId: Scalars['String'];
  bio: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  title: Scalars['String'];
}

export interface ITimeSlot {
  __typename?: 'TimeSlot';
  contact?: Maybe<ITechnician>;
  durationMinutes: Scalars['Int'];
  endTime: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
}

export interface ITimeSlotInfoResult {
  __typename?: 'TimeSlotInfoResult';
  appointmentExportType: AppointmentExportType;
  availabilityDetails: Array<IAvailabilityDetail>;
  isPmCampaign: Scalars['Boolean'];
  isUnderContract: Scalars['Boolean'];
  serviceCode: IServiceCode;
  timeSlots: Array<ITimeSlot>;
}

export interface ITrade {
  __typename?: 'Trade';
  blackoutPeriod?: Maybe<IBlackoutPeriod>;
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface IUserComment {
  name: Scalars['String'];
  value: Scalars['String'];
}

export type IAbandonLeadMutationVariables = Exact<{
  id: Scalars['ID'];
  abandonmentReason?: Maybe<Scalars['String']>;
}>;

export type IAbandonLeadMutation = {
  __typename?: 'Mutation';
  abandonLead: { __typename?: 'AbandonLeadResult'; id: string };
};

export type IAppointmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IAppointmentQuery = {
  __typename?: 'Query';
  appointment?: Maybe<{
    __typename?: 'Appointment';
    id: string;
    isTentative: boolean;
    jobStatus: string;
    serviceDate: string;
    location: {
      __typename?: 'Location';
      city: string;
      point: Array<number>;
      state: string;
      street1: string;
      street2?: Maybe<string>;
      timezone: string;
      zipCode: string;
    };
    serviceCode: {
      __typename?: 'ServiceCode';
      name: string;
      icon: { __typename?: 'ServiceCodeIcon'; key: string };
    };
    serviceZone: { __typename?: 'ServiceZone'; timezone: string };
    technician?: Maybe<{
      __typename?: 'AppointmentTechnician';
      avatarBannerMediaId: string;
      avatarMediaId: string;
      bio: string;
      currentLocation?: Maybe<Array<Maybe<number>>>;
      estimatedArrivalTime?: Maybe<string>;
      firstName: string;
      fullName: string;
      lastName: string;
      title: string;
    }>;
    timeSlot: {
      __typename?: 'TimeSlot';
      endTime: string;
      label?: Maybe<string>;
      startTime: string;
    };
  }>;
};

export type IAppointmentTimeSlotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IAppointmentTimeSlotQuery = {
  __typename?: 'Query';
  appointment?: Maybe<{
    __typename?: 'Appointment';
    timeSlot: {
      __typename?: 'TimeSlot';
      endTime: string;
      label?: Maybe<string>;
      startTime: string;
    };
  }>;
};

export type IAvailablePartnersForTimeslotQueryVariables = Exact<{
  startTime: Scalars['DateTime'];
  addressId: Scalars['String'];
  serviceType: ServiceType;
  isEmergency?: Maybe<Scalars['Boolean']>;
  serviceCodeId: Scalars['ID'];
  subcontractor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;

export type IAvailablePartnersForTimeslotQuery = {
  __typename?: 'Query';
  availablePartnersForTimeslot: {
    __typename?: 'AvailablePartnersForTimeSlotResult';
    partners: Array<{
      __typename?: 'Partner';
      distanceInMeters?: Maybe<number>;
      isSubcontractorMatch: boolean;
      name: string;
      partnerId: string;
      address: {
        __typename?: 'PartnerAddress';
        addressLine1: string;
        addressLine2?: Maybe<string>;
        countryCode: string;
        locality: string;
        postalCode: string;
        region: string;
      };
    }>;
    subcontractor?: Maybe<{ __typename?: 'Partner'; name: string }>;
  };
};

export type ICurrentCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type ICurrentCustomerQuery = {
  __typename?: 'Query';
  currentCustomer?: Maybe<{
    __typename?: 'Customer';
    id: string;
    contact: {
      __typename?: 'CustomerContact';
      email: string;
      firstName: string;
      lastName: string;
      name: string;
    };
    locations: Array<{
      __typename?: 'Location';
      addressId: string;
      city: string;
      id: string;
      name: string;
      state: string;
      street1: string;
      street2?: Maybe<string>;
      timezone: string;
      zipCode: string;
      appointments: Array<{
        __typename?: 'Appointment';
        id: string;
        isTentative: boolean;
        jobStatus: string;
        serviceDate: string;
        location: {
          __typename?: 'Location';
          city: string;
          point: Array<number>;
          state: string;
          street1: string;
          street2?: Maybe<string>;
          timezone: string;
          zipCode: string;
        };
        serviceCode: {
          __typename?: 'ServiceCode';
          name: string;
          icon: { __typename?: 'ServiceCodeIcon'; key: string };
        };
        serviceZone: { __typename?: 'ServiceZone'; timezone: string };
        technician?: Maybe<{
          __typename?: 'AppointmentTechnician';
          avatarBannerMediaId: string;
          avatarMediaId: string;
          bio: string;
          currentLocation?: Maybe<Array<Maybe<number>>>;
          estimatedArrivalTime?: Maybe<string>;
          firstName: string;
          fullName: string;
          lastName: string;
          title: string;
        }>;
      }>;
    }>;
    phone: {
      __typename?: 'Phone';
      id: string;
      isMobile: boolean;
      number: string;
    };
  }>;
};

export type ICurrentCustomerChangedSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type ICurrentCustomerChangedSubscription = {
  __typename?: 'Subscription';
  currentCustomerChanged?: Maybe<{
    __typename?: 'Customer';
    id: string;
    contact: {
      __typename?: 'CustomerContact';
      email: string;
      firstName: string;
      lastName: string;
      name: string;
    };
    locations: Array<{
      __typename?: 'Location';
      addressId: string;
      city: string;
      id: string;
      name: string;
      state: string;
      street1: string;
      street2?: Maybe<string>;
      timezone: string;
      zipCode: string;
      appointments: Array<{
        __typename?: 'Appointment';
        id: string;
        isTentative: boolean;
        jobStatus: string;
        serviceDate: string;
        location: {
          __typename?: 'Location';
          city: string;
          point: Array<number>;
          state: string;
          street1: string;
          street2?: Maybe<string>;
          timezone: string;
          zipCode: string;
        };
        serviceCode: {
          __typename?: 'ServiceCode';
          name: string;
          icon: { __typename?: 'ServiceCodeIcon'; key: string };
        };
        serviceZone: { __typename?: 'ServiceZone'; timezone: string };
        technician?: Maybe<{
          __typename?: 'AppointmentTechnician';
          avatarBannerMediaId: string;
          avatarMediaId: string;
          bio: string;
          currentLocation?: Maybe<Array<Maybe<number>>>;
          estimatedArrivalTime?: Maybe<string>;
          firstName: string;
          fullName: string;
          lastName: string;
          title: string;
        }>;
      }>;
    }>;
    phone: {
      __typename?: 'Phone';
      id: string;
      isMobile: boolean;
      number: string;
    };
  }>;
};

export type ICurrentCustomerFieldsFragment = {
  __typename?: 'Customer';
  id: string;
  contact: {
    __typename?: 'CustomerContact';
    email: string;
    firstName: string;
    lastName: string;
    name: string;
  };
  locations: Array<{
    __typename?: 'Location';
    addressId: string;
    city: string;
    id: string;
    name: string;
    state: string;
    street1: string;
    street2?: Maybe<string>;
    timezone: string;
    zipCode: string;
    appointments: Array<{
      __typename?: 'Appointment';
      id: string;
      isTentative: boolean;
      jobStatus: string;
      serviceDate: string;
      location: {
        __typename?: 'Location';
        city: string;
        point: Array<number>;
        state: string;
        street1: string;
        street2?: Maybe<string>;
        timezone: string;
        zipCode: string;
      };
      serviceCode: {
        __typename?: 'ServiceCode';
        name: string;
        icon: { __typename?: 'ServiceCodeIcon'; key: string };
      };
      serviceZone: { __typename?: 'ServiceZone'; timezone: string };
      technician?: Maybe<{
        __typename?: 'AppointmentTechnician';
        avatarBannerMediaId: string;
        avatarMediaId: string;
        bio: string;
        currentLocation?: Maybe<Array<Maybe<number>>>;
        estimatedArrivalTime?: Maybe<string>;
        firstName: string;
        fullName: string;
        lastName: string;
        title: string;
      }>;
    }>;
  }>;
  phone: {
    __typename?: 'Phone';
    id: string;
    isMobile: boolean;
    number: string;
  };
};

export type IEnergyOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type IEnergyOptionsQuery = {
  __typename?: 'Query';
  energyOptions: Array<{ __typename?: 'Option'; id: string; name: string }>;
};

export type IEquipmentOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type IEquipmentOptionsQuery = {
  __typename?: 'Query';
  equipmentOptions: Array<{ __typename?: 'Option'; id: string; name: string }>;
};

export type IGetAddressBookingStatusForCustomerQueryVariables = Exact<{
  address: IAddressInput;
  phoneNumber: Scalars['String'];
  serviceType: ServiceType;
  enterpriseVersion?: Maybe<Scalars['String']>;
}>;

export type IGetAddressBookingStatusForCustomerQuery = {
  __typename?: 'Query';
  getAddressBookingStatusForCustomer?: Maybe<{
    __typename?: 'AddressBookingStatusForCustomerResult';
    isBookingAllowed: boolean;
    isExisting: boolean;
    isServiceable: boolean;
  }>;
};

export type IGetCorrectedAddressesQueryVariables = Exact<{
  address: IAddressInput;
}>;

export type IGetCorrectedAddressesQuery = {
  __typename?: 'Query';
  getCorrectedAddresses?: Maybe<{
    __typename?: 'CorrectedAddressesResult';
    correctedAddresses: Array<{
      __typename?: 'CorrectedAddress';
      isMatch: boolean;
      address: {
        __typename?: 'Address';
        city: string;
        id: string;
        state: string;
        street1: string;
        street2?: Maybe<string>;
        timezone: string;
        zipCode: string;
      };
    }>;
  }>;
};

export type IGetCorrectedPhoneNumberQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
}>;

export type IGetCorrectedPhoneNumberQuery = {
  __typename?: 'Query';
  getCorrectedPhoneNumber?: Maybe<{
    __typename?: 'CorrectedPhoneNumberResult';
    isValid: boolean;
    phone?: Maybe<{
      __typename?: 'Phone';
      id: string;
      isMobile: boolean;
      number: string;
    }>;
  }>;
};

export type IGetCustomerLocationsByPhoneQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
  serviceType: ServiceType;
}>;

export type IGetCustomerLocationsByPhoneQuery = {
  __typename?: 'Query';
  getCustomerLocationsByPhone?: Maybe<{
    __typename?: 'CustomerLocationsByPhoneResult';
    allowLocationsNotInServiceZone: boolean;
    doNotServiceCustomer?: Maybe<boolean>;
    customerLocations: Array<{
      __typename?: 'CustomerLocation';
      isBookingAllowed: boolean;
      isInServiceZone: boolean;
      customer: {
        __typename?: 'Customer';
        contact: {
          __typename?: 'CustomerContact';
          firstName: string;
          lastName: string;
        };
        phone: {
          __typename?: 'Phone';
          id: string;
          isMobile: boolean;
          number: string;
        };
      };
      location: {
        __typename?: 'Location';
        addressId: string;
        city: string;
        id: string;
        name: string;
        state: string;
        street1: string;
        street2?: Maybe<string>;
        timezone: string;
        zipCode: string;
      };
    }>;
  }>;
};

export type IGetServiceZoneFromZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String'];
  serviceType?: Maybe<ServiceType>;
  enterpriseVersion?: Maybe<Scalars['String']>;
}>;

export type IGetServiceZoneFromZipCodeQuery = {
  __typename?: 'Query';
  getServiceZoneFromZipCode?: Maybe<{
    __typename?: 'ServiceZoneFromZipCodeResult';
    isInServiceZone: boolean;
    serviceZoneTimezone?: Maybe<string>;
    locality?: Maybe<{
      __typename?: 'Locality';
      city: string;
      countryCode: string;
      state: string;
    }>;
  }>;
};

export type IIssueTradeFieldsFragment = {
  __typename?: 'Trade';
  id: string;
  name: string;
};

export type IIssueSpecDetailFieldsFragment = {
  __typename?: 'IssueSpecDetail';
  info?: Maybe<string>;
  label?: Maybe<string>;
  note?: Maybe<string>;
  notice?: Maybe<string>;
  serviceCodeId?: Maybe<string>;
  toIssueId?: Maybe<string>;
  warn?: Maybe<string>;
  options?: Maybe<
    Array<{
      __typename?: 'IssueSpecOption';
      isEmergency?: Maybe<boolean>;
      label?: Maybe<string>;
      nextValue?: Maybe<string>;
      note?: Maybe<string>;
      serviceCodeId?: Maybe<string>;
      toIssueId?: Maybe<string>;
    }>
  >;
};

export type IIssueFieldsFragment = {
  __typename?: 'Issue';
  id: string;
  isActive: boolean;
  isEmergency: boolean;
  isGeneral: boolean;
  key: string;
  name: string;
  specifier: {
    __typename?: 'IssueSpec';
    title?: Maybe<string>;
    details: {
      __typename?: 'IssueSpecDetail';
      info?: Maybe<string>;
      label?: Maybe<string>;
      note?: Maybe<string>;
      notice?: Maybe<string>;
      serviceCodeId?: Maybe<string>;
      toIssueId?: Maybe<string>;
      warn?: Maybe<string>;
      options?: Maybe<
        Array<{
          __typename?: 'IssueSpecOption';
          isEmergency?: Maybe<boolean>;
          label?: Maybe<string>;
          nextValue?: Maybe<string>;
          note?: Maybe<string>;
          serviceCodeId?: Maybe<string>;
          toIssueId?: Maybe<string>;
        }>
      >;
    };
    next?: Maybe<
      Array<
        Maybe<{
          __typename?: 'IssueSpecNext';
          value: string;
          details: {
            __typename?: 'IssueSpecDetail';
            info?: Maybe<string>;
            label?: Maybe<string>;
            note?: Maybe<string>;
            notice?: Maybe<string>;
            serviceCodeId?: Maybe<string>;
            toIssueId?: Maybe<string>;
            warn?: Maybe<string>;
            options?: Maybe<
              Array<{
                __typename?: 'IssueSpecOption';
                isEmergency?: Maybe<boolean>;
                label?: Maybe<string>;
                nextValue?: Maybe<string>;
                note?: Maybe<string>;
                serviceCodeId?: Maybe<string>;
                toIssueId?: Maybe<string>;
              }>
            >;
          };
        }>
      >
    >;
  };
  trade: { __typename?: 'Trade'; id: string; name: string };
};

export type IIssueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IIssueQuery = {
  __typename?: 'Query';
  issue?: Maybe<{
    __typename?: 'Issue';
    id: string;
    isActive: boolean;
    isEmergency: boolean;
    isGeneral: boolean;
    key: string;
    name: string;
    specifier: {
      __typename?: 'IssueSpec';
      title?: Maybe<string>;
      details: {
        __typename?: 'IssueSpecDetail';
        info?: Maybe<string>;
        label?: Maybe<string>;
        note?: Maybe<string>;
        notice?: Maybe<string>;
        serviceCodeId?: Maybe<string>;
        toIssueId?: Maybe<string>;
        warn?: Maybe<string>;
        options?: Maybe<
          Array<{
            __typename?: 'IssueSpecOption';
            isEmergency?: Maybe<boolean>;
            label?: Maybe<string>;
            nextValue?: Maybe<string>;
            note?: Maybe<string>;
            serviceCodeId?: Maybe<string>;
            toIssueId?: Maybe<string>;
          }>
        >;
      };
      next?: Maybe<
        Array<
          Maybe<{
            __typename?: 'IssueSpecNext';
            value: string;
            details: {
              __typename?: 'IssueSpecDetail';
              info?: Maybe<string>;
              label?: Maybe<string>;
              note?: Maybe<string>;
              notice?: Maybe<string>;
              serviceCodeId?: Maybe<string>;
              toIssueId?: Maybe<string>;
              warn?: Maybe<string>;
              options?: Maybe<
                Array<{
                  __typename?: 'IssueSpecOption';
                  isEmergency?: Maybe<boolean>;
                  label?: Maybe<string>;
                  nextValue?: Maybe<string>;
                  note?: Maybe<string>;
                  serviceCodeId?: Maybe<string>;
                  toIssueId?: Maybe<string>;
                }>
              >;
            };
          }>
        >
      >;
    };
    trade: { __typename?: 'Trade'; id: string; name: string };
  }>;
};

export type ILeadSourcesQueryVariables = Exact<{
  serviceType: ServiceType;
}>;

export type ILeadSourcesQuery = {
  __typename?: 'Query';
  leadSources: Array<{
    __typename?: 'LeadSource';
    id: string;
    name: string;
    companionFieldLabel?: Maybe<string>;
  }>;
};

export type IRegisterAddressMutationVariables = Exact<{
  address: IAddressInput;
}>;

export type IRegisterAddressMutation = {
  __typename?: 'Mutation';
  registerAddress?: Maybe<{
    __typename?: 'RegisterAddressResult';
    errorCode?: Maybe<ErrorCode>;
    address?: Maybe<{
      __typename?: 'Address';
      id: string;
      street1: string;
      street2?: Maybe<string>;
      city: string;
      state: string;
      zipCode: string;
      timezone: string;
    }>;
  }>;
};

export type IRoomsTradeFieldsFragment = {
  __typename?: 'Trade';
  id: string;
  name: string;
};

export type IRoomIssueFieldsFragment = {
  __typename?: 'Issue';
  id: string;
  isActive: boolean;
  isEmergency: boolean;
  isGeneral: boolean;
  key: string;
  name: string;
  uiGrouping: string;
  specifier: {
    __typename?: 'IssueSpec';
    title?: Maybe<string>;
    details: {
      __typename?: 'IssueSpecDetail';
      info?: Maybe<string>;
      label?: Maybe<string>;
      note?: Maybe<string>;
      notice?: Maybe<string>;
      serviceCodeId?: Maybe<string>;
      toIssueId?: Maybe<string>;
      warn?: Maybe<string>;
      options?: Maybe<
        Array<{
          __typename?: 'IssueSpecOption';
          isEmergency?: Maybe<boolean>;
          label?: Maybe<string>;
          nextValue?: Maybe<string>;
          note?: Maybe<string>;
          serviceCodeId?: Maybe<string>;
          toIssueId?: Maybe<string>;
        }>
      >;
    };
    next?: Maybe<
      Array<
        Maybe<{
          __typename?: 'IssueSpecNext';
          value: string;
          details: {
            __typename?: 'IssueSpecDetail';
            info?: Maybe<string>;
            label?: Maybe<string>;
            note?: Maybe<string>;
            notice?: Maybe<string>;
            serviceCodeId?: Maybe<string>;
            toIssueId?: Maybe<string>;
            warn?: Maybe<string>;
            options?: Maybe<
              Array<{
                __typename?: 'IssueSpecOption';
                isEmergency?: Maybe<boolean>;
                label?: Maybe<string>;
                nextValue?: Maybe<string>;
                note?: Maybe<string>;
                serviceCodeId?: Maybe<string>;
                toIssueId?: Maybe<string>;
              }>
            >;
          };
        }>
      >
    >;
  };
  trade: { __typename?: 'Trade'; id: string; name: string };
};

export type IRoomsQueryVariables = Exact<{
  flowId?: Maybe<Scalars['ID']>;
}>;

export type IRoomsQuery = {
  __typename?: 'Query';
  rooms: Array<{
    __typename?: 'Room';
    id: string;
    key: string;
    name: string;
    icons: Array<{
      __typename?: 'RoomIcon';
      commonProblems?: Maybe<string>;
      id: string;
      key: string;
      name: string;
      issue?: Maybe<{
        __typename?: 'Issue';
        id: string;
        isActive: boolean;
        isEmergency: boolean;
        isGeneral: boolean;
        key: string;
        name: string;
        uiGrouping: string;
        specifier: {
          __typename?: 'IssueSpec';
          title?: Maybe<string>;
          details: {
            __typename?: 'IssueSpecDetail';
            info?: Maybe<string>;
            label?: Maybe<string>;
            note?: Maybe<string>;
            notice?: Maybe<string>;
            serviceCodeId?: Maybe<string>;
            toIssueId?: Maybe<string>;
            warn?: Maybe<string>;
            options?: Maybe<
              Array<{
                __typename?: 'IssueSpecOption';
                isEmergency?: Maybe<boolean>;
                label?: Maybe<string>;
                nextValue?: Maybe<string>;
                note?: Maybe<string>;
                serviceCodeId?: Maybe<string>;
                toIssueId?: Maybe<string>;
              }>
            >;
          };
          next?: Maybe<
            Array<
              Maybe<{
                __typename?: 'IssueSpecNext';
                value: string;
                details: {
                  __typename?: 'IssueSpecDetail';
                  info?: Maybe<string>;
                  label?: Maybe<string>;
                  note?: Maybe<string>;
                  notice?: Maybe<string>;
                  serviceCodeId?: Maybe<string>;
                  toIssueId?: Maybe<string>;
                  warn?: Maybe<string>;
                  options?: Maybe<
                    Array<{
                      __typename?: 'IssueSpecOption';
                      isEmergency?: Maybe<boolean>;
                      label?: Maybe<string>;
                      nextValue?: Maybe<string>;
                      note?: Maybe<string>;
                      serviceCodeId?: Maybe<string>;
                      toIssueId?: Maybe<string>;
                    }>
                  >;
                };
              }>
            >
          >;
        };
        trade: { __typename?: 'Trade'; id: string; name: string };
      }>;
      serviceCode?: Maybe<{
        __typename?: 'ServiceCode';
        description?: Maybe<string>;
        id: string;
        key: string;
        name: string;
        trade: { __typename?: 'Trade'; id: string; name: string };
      }>;
    }>;
    issues: Array<{
      __typename?: 'Issue';
      id: string;
      isActive: boolean;
      isEmergency: boolean;
      isGeneral: boolean;
      key: string;
      name: string;
      uiGrouping: string;
      specifier: {
        __typename?: 'IssueSpec';
        title?: Maybe<string>;
        details: {
          __typename?: 'IssueSpecDetail';
          info?: Maybe<string>;
          label?: Maybe<string>;
          note?: Maybe<string>;
          notice?: Maybe<string>;
          serviceCodeId?: Maybe<string>;
          toIssueId?: Maybe<string>;
          warn?: Maybe<string>;
          options?: Maybe<
            Array<{
              __typename?: 'IssueSpecOption';
              isEmergency?: Maybe<boolean>;
              label?: Maybe<string>;
              nextValue?: Maybe<string>;
              note?: Maybe<string>;
              serviceCodeId?: Maybe<string>;
              toIssueId?: Maybe<string>;
            }>
          >;
        };
        next?: Maybe<
          Array<
            Maybe<{
              __typename?: 'IssueSpecNext';
              value: string;
              details: {
                __typename?: 'IssueSpecDetail';
                info?: Maybe<string>;
                label?: Maybe<string>;
                note?: Maybe<string>;
                notice?: Maybe<string>;
                serviceCodeId?: Maybe<string>;
                toIssueId?: Maybe<string>;
                warn?: Maybe<string>;
                options?: Maybe<
                  Array<{
                    __typename?: 'IssueSpecOption';
                    isEmergency?: Maybe<boolean>;
                    label?: Maybe<string>;
                    nextValue?: Maybe<string>;
                    note?: Maybe<string>;
                    serviceCodeId?: Maybe<string>;
                    toIssueId?: Maybe<string>;
                  }>
                >;
              };
            }>
          >
        >;
      };
      trade: { __typename?: 'Trade'; id: string; name: string };
    }>;
  }>;
};

export type IServiceCodeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IServiceCodeQuery = {
  __typename?: 'Query';
  serviceCode?: Maybe<{
    __typename?: 'ServiceCode';
    description?: Maybe<string>;
    id: string;
    key: string;
    name: string;
    trade: {
      __typename?: 'Trade';
      id: string;
      name: string;
      blackoutPeriod?: Maybe<{
        __typename?: 'BlackoutPeriod';
        days: number;
        id: string;
        isNonContractOnly: boolean;
        message: string;
      }>;
    };
    icon: { __typename?: 'ServiceCodeIcon'; key: string };
  }>;
};

export type IServiceCodeTradeFieldsFragment = {
  __typename?: 'Trade';
  id: string;
  name: string;
  blackoutPeriod?: Maybe<{
    __typename?: 'BlackoutPeriod';
    days: number;
    id: string;
    isNonContractOnly: boolean;
    message: string;
  }>;
};

export type IServiceCodeFieldsFragment = {
  __typename?: 'ServiceCode';
  description?: Maybe<string>;
  id: string;
  key: string;
  name: string;
  trade: {
    __typename?: 'Trade';
    id: string;
    name: string;
    blackoutPeriod?: Maybe<{
      __typename?: 'BlackoutPeriod';
      days: number;
      id: string;
      isNonContractOnly: boolean;
      message: string;
    }>;
  };
  icon: { __typename?: 'ServiceCodeIcon'; key: string };
};

export type ISetLeadSourceMutationVariables = Exact<{
  appointmentId: Scalars['ID'];
  selectedFieldId: Scalars['ID'];
  companionFieldValue?: Maybe<Scalars['String']>;
}>;

export type ISetLeadSourceMutation = {
  __typename?: 'Mutation';
  setLeadSource?: Maybe<{
    __typename?: 'SetLeadSourceResult';
    leadSource: { __typename?: 'LeadSource'; id: string };
  }>;
};

export type ISubmitBookingRequestMutationVariables = Exact<{
  customer: ICustomerInput;
  options: IBookingOptionsInput;
  startTime: Scalars['DateTime'];
  serviceType: ServiceType;
}>;

export type ISubmitBookingRequestMutation = {
  __typename?: 'Mutation';
  submitBookingRequest?: Maybe<{
    __typename?: 'BookingRequestResult';
    errorCode?: Maybe<ErrorCode>;
    appointment?: Maybe<{ __typename?: 'AppointmentBooking'; id: string }>;
    lead?: Maybe<{ __typename?: 'LeadBooking'; id: string }>;
  }>;
};

export type ISubmitLeadMutationVariables = Exact<{
  customer?: Maybe<ILeadCustomerInput>;
  options?: Maybe<IBookingOptionsInput>;
  startTime?: Maybe<Scalars['DateTime']>;
  serviceType?: Maybe<ServiceType>;
}>;

export type ISubmitLeadMutation = {
  __typename?: 'Mutation';
  submitLead: {
    __typename?: 'SubmitLeadResult';
    errorCode?: Maybe<ErrorCode>;
    lead?: Maybe<{ __typename?: 'LeadBooking'; id: string }>;
  };
};

export type ITimeSlotInfoForMonthQueryVariables = Exact<{
  addressId?: Maybe<Scalars['String']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  phoneId?: Maybe<Scalars['String']>;
  serviceCodeId?: Maybe<Scalars['ID']>;
  serviceType: ServiceType;
  year?: Maybe<Scalars['Int']>;
  postalCode?: Maybe<Scalars['String']>;
  campaignMemberId?: Maybe<Scalars['ID']>;
  enterpriseVersion?: Maybe<Scalars['String']>;
}>;

export type ITimeSlotInfoForMonthQuery = {
  __typename?: 'Query';
  timeSlotInfoForMonth: {
    __typename?: 'TimeSlotInfoResult';
    isPmCampaign: boolean;
    availabilityDetails: Array<{
      __typename?: 'AvailabilityDetail';
      end?: Maybe<string>;
      id: string;
      message: string;
      start: string;
    }>;
    timeSlots: Array<{
      __typename?: 'TimeSlot';
      endTime: string;
      label?: Maybe<string>;
      startTime: string;
      contact?: Maybe<{
        __typename?: 'Technician';
        avatarMediaId: string;
        firstName: string;
        id: string;
        lastName: string;
        title: string;
      }>;
    }>;
  };
};
