export enum QueryStringKey {
  AFFILIATE = 'affiliate',
  API_KEY = 'api_key',
  BRAND = 'brand',
  CAMPAIGN = 'campaign',
  CLIENT_REFERRER = 'client_referrer',
  CUSTOM_FIELD_1 = 'custom_field_1',
  CUSTOM_FIELD_10 = 'custom_field_10',
  CUSTOM_FIELD_2 = 'custom_field_2',
  CUSTOM_FIELD_3 = 'custom_field_3',
  CUSTOM_FIELD_4 = 'custom_field_4',
  CUSTOM_FIELD_5 = 'custom_field_5',
  CUSTOM_FIELD_6 = 'custom_field_6',
  CUSTOM_FIELD_7 = 'custom_field_7',
  CUSTOM_FIELD_8 = 'custom_field_8',
  CUSTOM_FIELD_9 = 'custom_field_9',
  FLOW_KEY = 'flow_key',
  LEAD_SOURCE = 'lead_source',
  MODE = 'mode',
  PLATFORM = 'platform',
  PREVIEW_MODE = 'preview_mode',
  RESPONSIVE_DISABLED = 'responsive_disabled',
  ROOM_KEY = 'room_key',
  RWG_TOKEN = 'rwg_token',
  STATIC = 'static',
  SUBCONTRACTOR = 'subcontractor',
  THEME_KEY = 'theme_key',
  WIDGET_VIEW_MODE = 'widget_view_mode',
}
