// TODO(CUSTOM): Is the dimension/analytics key being submitted correctly?
export interface IGoogleAnalyticsEvent {
  action: GoogleAnalyticsAction;
  analyticsKey?: string;
  category: GoogleAnalyticsCategory;
  gaAnalyticsDimension?: string;
  label?: string;
  value?: number;
}

/**
 * Possible Google Analytics actions that can be reported
 */
export enum GoogleAnalyticsAction {
  /**  Should fire when the booking is completed */
  BOOKING_BOOKED = 'BookingBooked',
  /** Should fire when we have all the information of a customer (name, contact, and location) */
  BOOKING_CUSTOMER = 'BookingCustomer',
  /** Should fire after the view that asks the customer if it is an existing customer */
  BOOKING_CUSTOMER_QUESTION = 'BookingCustomerQuestion',
  /** Should fire after the upload media view */
  BOOKING_DETAILS_MEDIA = 'BookingDetailsMedia',
  /** Should fire after the details view */
  BOOKING_DETAILS_MORE = 'BookingDetailsMore',
  /** Should fire after the issue and following details have been selected */
  BOOKING_ISSUE_COMPLETED = 'BookingIssueCompleted',
  /** Should fire when the issue "selector" opens */
  BOOKING_ISSUE_STARTED = 'BookingIssueStarted',
  /** Should fire on the first widget interaction */
  BOOKING_PROCESS_STARTED = 'BookingProcessStarted',
  /** Should fire after the customer picks a day and time slot in the calendar */
  BOOKING_SCHEDULE = 'BookingSchedule',
  /** Should fire when the widget opens */
  BOOKING_STARTED = 'BookingStarted',
}

export enum GoogleAnalyticsCategory {
  SCHEDULE_ENGINE_BOOKING = 'Schedule Engine - Booking',
}
