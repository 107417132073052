import {
  ENTERED,
  ENTERING,
  TransitionStatus,
} from 'react-transition-group/Transition';
import styled from 'styled-components/macro';

import { large } from 'styles/breakpoints';
import { IWithThemeProps } from 'typings';

interface IItemProps {
  index: number;
  transitionStatus: TransitionStatus;
}

export const Item = styled.div<IItemProps>`
  background: transparent;
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  padding: 0 9px;
  position: relative;
  vertical-align: top;
  white-space: normal;
  width: 100%;

  ${({ index }) => `
    grid-column-start: ${index + 1};
  `}

  ${({ theme }: IWithThemeProps) => {
    const halfDuration =
      parseInt(theme.transitionDurationCarouselTrack, 10) / 2;

    return `
      transition: opacity ${halfDuration}ms ease;
    `;
  }}

  ${({ theme, transitionStatus }) => {
    const fadeDuration =
      parseInt(theme.transitionDurationCarouselTrack, 10) / 5;

    if ([ENTERED, ENTERING].includes(transitionStatus)) {
      return `
        opacity: 1;
        transition-delay: ${fadeDuration}ms;
      `;
    } else {
      return `
        opacity: 0;
        transition-delay: 0s;
      `;
    }
  }}

  ${large`
    padding: 0 17px;
  `}
`;
