import styled from 'styled-components/macro';

import {
  Button,
  ILabelContainerProps,
  LabelContainer,
} from 'components/CarouselBottomButtonArrowSvg';

interface IBiDirectionLabelContainerProps extends ILabelContainerProps {
  isBack?: boolean;
}

export const BiDirecitonButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 96px;
  justify-content: center;
`;

export const BiDirectionLabelContainer = styled(
  LabelContainer,
)<IBiDirectionLabelContainerProps>`
  padding: ${({ isBack }) =>
    isBack ? `21px 0 21px 11px` : `21px 11px 21px 0`};
`;

export const BiDirectionButton = styled(Button)`
  width: 180px;
`;
