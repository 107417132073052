import { CarouselItemId } from '@homex/se-widget-flow-types';

import { SET_CAROUSEL_HISTORY } from 'actions';
import { CarouselReduxReducer } from 'typings';

export function setCarouselHistory(
  reduxReducer: CarouselReduxReducer,
  history: Array<CarouselItemId>,
) {
  return { history, reduxReducer, type: SET_CAROUSEL_HISTORY };
}
