import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { usePrevious } from 'hooks/usePrevious';
import { ISessionTokenHandlerProps, IState } from 'typings';

export const SessionTokenHandler = ({
  onTokenChange,
}: ISessionTokenHandlerProps) => {
  const sessionToken = useSelector((state: IState) => state.auth.sessionToken);

  const prevSessionToken = usePrevious(sessionToken);
  useEffect(() => {
    if (sessionToken !== prevSessionToken) {
      onTokenChange();
    }
  }, [sessionToken, prevSessionToken, onTokenChange]);

  return null;
};
