import { CarouselItemId } from '@homex/se-widget-flow-types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { HIDE_SERVICE_MODAL, setCurrentCarouselItemToDefault } from 'actions';
import { CarouselReduxReducer, IState, Mode } from 'typings';
import isAnyModalVisible from 'utils/isAnyModalVisible';
import postMessageToParent from 'utils/postMessageToParent';

export function hideServiceModal() {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    const {
      serviceCarousel: { currentItemId },
    } = getState();

    dispatch({ type: HIDE_SERVICE_MODAL });

    // TODO: In the future, we may want to wait for a `transitionend` event
    // from the modal or curtain to tell us that the modal is hidden, which
    // would fallback to a timeout. Using a timeout could result in the
    // modal disappearing before the user sees the full transition.
    setTimeout(() => {
      const { client, modal } = getState();
      const { flow, mode, validOrigins } = client;

      if (mode === Mode.WEB && !isAnyModalVisible(modal)) {
        // Tell the parent window that the modal is now hidden
        postMessageToParent(
          { analyticsKey: flow?.key, type: 'se-hide-modal' },
          validOrigins,
        );
      }

      // Clear out any session-specific user state
      // TODO: Account for final items on MAINTENANCE flows when those are created
      if (
        currentItemId === CarouselItemId.ConfirmFinishSales ||
        currentItemId === CarouselItemId.ConfirmFinishRepair
      ) {
        dispatch(
          setCurrentCarouselItemToDefault(CarouselReduxReducer.SERVICE, true),
        );
      }
    }, 250);
  };
}
