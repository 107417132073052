import {
  CarouselItemId,
  CarouselNavStepName,
} from '@homex/se-widget-flow-types';

import { Flow, ServiceType } from 'typings';

function getCurrentSalesStep(
  currentItemId: CarouselItemId,
): CarouselNavStepName {
  switch (currentItemId) {
    case CarouselItemId.ServiceAreaSales:
    case CarouselItemId.SalesAddress:
    case CarouselItemId.SalesAddressCorrection:
    case CarouselItemId.SalesAddressNotFound:
      return CarouselNavStepName.Address;
    case CarouselItemId.ContactInfo:
      return CarouselNavStepName.Contact;
    case CarouselItemId.ScheduleEmergency:
    case CarouselItemId.SchedulePreferenceSales:
      return CarouselNavStepName.Schedule;
    case CarouselItemId.SystemEquipment:
    case CarouselItemId.SystemEnergy:
    case CarouselItemId.SystemComments:
      return CarouselNavStepName.System;
    case CarouselItemId.SalesSummary:
    case CarouselItemId.ConfirmFinishSales:
      return CarouselNavStepName.Confirm;
    default:
      return CarouselNavStepName.Address;
  }
}

function getCurrentRepairStep(
  currentItemId: CarouselItemId,
): CarouselNavStepName {
  switch (currentItemId) {
    case CarouselItemId.IssueSpecifier:
      return CarouselNavStepName.Issue;
    case CarouselItemId.MediaUpload:
    case CarouselItemId.MediaDetail:
    case CarouselItemId.RepairComments:
      return CarouselNavStepName.Details;
    case CarouselItemId.CustomerLookup:
    case CarouselItemId.CustomerLocations:
    case CarouselItemId.CustomerLocationsRecovered:
    case CarouselItemId.ServiceAreaRepair:
    case CarouselItemId.RepairAddress:
    case CarouselItemId.RepairAddressCorrection:
    case CarouselItemId.RepairAddressNotFound:
    case CarouselItemId.RepairContactInfo:
      return CarouselNavStepName.Customer;
    case CarouselItemId.ScheduleCalendarRepair:
    case CarouselItemId.SchedulePreference:
    case CarouselItemId.AvailablePartners:
      return CarouselNavStepName.Schedule;
    case CarouselItemId.RepairSummary:
      return CarouselNavStepName.Confirm;
    case CarouselItemId.ConfirmFinishRepair:
      return CarouselNavStepName.Booked;

    default: {
      if (currentItemId.indexOf(CarouselItemId.IssueDetails) === 0) {
        return CarouselNavStepName.Details;
      }

      return CarouselNavStepName.Opened;
    }
  }
}

export default function getCarouselStepName(
  currentItemId: CarouselItemId,
  serviceType?: ServiceType,
  flow?: Flow,
) {
  if (flow) {
    const navStep = flow.navSteps.find(
      (ns) => !!ns.carouselItems.find((ci) => currentItemId.startsWith(ci.id)),
    );

    return navStep?.name;
  }

  switch (serviceType) {
    case ServiceType.SALES:
      return getCurrentSalesStep(currentItemId);
    case ServiceType.REPAIR:
      return getCurrentRepairStep(currentItemId);
    case ServiceType.MAINTENANCE:
      // TODO: Still need to implement flow
      return;
    default:
      return;
  }
}
