import 'styled-components/macro';

import { ReactComponent as RoomSceneryDoorSvg } from 'components/svg/RoomSceneryDoor.svg';
import { ReactComponent as RoomSceneryEndTableSvg } from 'components/svg/RoomSceneryEndTable.svg';
import { ReactComponent as RoomSceneryWindowSvg } from 'components/svg/RoomSceneryWindow.svg';

import {
  Container,
  doorStyles,
  endTableStyles,
  windowStylesLeft,
  windowStylesRight,
} from './styles';

export const RoomDefault = () => {
  return (
    <Container>
      <RoomSceneryWindowSvg css={windowStylesLeft} />
      <RoomSceneryDoorSvg css={doorStyles} />
      <RoomSceneryEndTableSvg css={endTableStyles} />
      <RoomSceneryWindowSvg css={windowStylesRight} />
    </Container>
  );
};
