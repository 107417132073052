import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityLightOutdoorSvg } from 'components/svg/RoomElectricityLightOutdoor.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomHvacOutdoorUnitSvg } from 'components/svg/RoomHvacOutdoorUnit.svg';
import { ReactComponent as RoomSceneryTreeSvg } from 'components/svg/RoomSceneryTree.svg';
import { ReactComponent as RoomSpigotSvg } from 'components/svg/RoomSpigot.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  Hvac,
  Light,
  Outlet,
  Spigot,
  Wall,
  treeStyles,
} from './styles';

export const RoomOutside = ({ icons }: IRoomProps) => {
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_EXTERIOR);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const hvacIcon = icons.find(({ key }) => key === RoomIconKey.HVAC_UNIT);
  const spigotIcon = icons.find(({ key }) => key === RoomIconKey.SPIGOT);

  return (
    <Container>
      <Wall />
      <RoomSceneryTreeSvg css={treeStyles} />
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightOutdoorSvg />}
        />
      </Light>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Hvac>
        <RoomButton icon={hvacIcon} iconSvg={<RoomHvacOutdoorUnitSvg />} />
      </Hvac>
      <Spigot>
        <RoomButton icon={spigotIcon} iconSvg={<RoomSpigotSvg />} />
      </Spigot>
    </Container>
  );
};
