export enum LoginDisplayState {
  DEFAULT = 'DEFAULT',
  PASSCODE = 'PASSCODE',
  SHARED = 'SHARED',
}

export type LoginResponse =
  | ILoginSuccessfulResponse
  | ILoginFailureResponse
  | Record<string, never>;

export interface ILoginSuccessfulResponse {
  phone_id: string;
  type?: 'sms' | 'voice';
}

export interface ILoginFailureResponse {
  phone_id: string;
  suggested_phones: Array<ISuggestedPhone>;
}

export interface ISuggestedPhone {
  last_four: string;
  phone_id: string;
}
