import 'styled-components/macro';

import { RoomButton } from 'components/RoomButton';
import { ReactComponent as RoomElectricityLightBulbSvg } from 'components/svg/RoomElectricityLightBulb.svg';
import { ReactComponent as RoomElectricityOutletSvg } from 'components/svg/RoomElectricityOutlet.svg';
import { ReactComponent as RoomElectricityPanelSvg } from 'components/svg/RoomElectricityPanel.svg';
import { ReactComponent as RoomElectricitySwitchSvg } from 'components/svg/RoomElectricitySwitch.svg';
import { ReactComponent as RoomHotWaterHeaterSvg } from 'components/svg/RoomHotWaterHeater.svg';
import { ReactComponent as RoomSceneryCarSvg } from 'components/svg/RoomSceneryCar.svg';
import { ReactComponent as RoomSinkUtilitySvg } from 'components/svg/RoomSinkUtility.svg';
import { ReactComponent as RoomVentSvg } from 'components/svg/RoomVent.svg';
import { IRoomProps, RoomIconKey } from 'typings';

import {
  Container,
  HotWaterHeater,
  Light,
  Outlet,
  Panel,
  Sink,
  Switch,
  Vent,
  carStyles,
} from './styles';

export const RoomGarage = ({ icons }: IRoomProps) => {
  const lightIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SIMPLE);
  const switchIcon = icons.find(({ key }) => key === RoomIconKey.LIGHT_SWITCH);
  const panelIcon = icons.find(({ key }) => key === RoomIconKey.PANEL);
  const outletIcon = icons.find(({ key }) => key === RoomIconKey.OUTLET);
  const sinkIcon = icons.find(({ key }) => key === RoomIconKey.SINK_UTILITY);
  const hotWaterHeaterIcon = icons.find(
    ({ key }) => key === RoomIconKey.HW_HEATER,
  );
  const ventIcon = icons.find(({ key }) => key === RoomIconKey.REGISTER);

  return (
    <Container>
      <RoomSceneryCarSvg css={carStyles} />
      <Light>
        <RoomButton
          icon={lightIcon}
          iconSvg={<RoomElectricityLightBulbSvg />}
        />
      </Light>
      <Switch>
        <RoomButton icon={switchIcon} iconSvg={<RoomElectricitySwitchSvg />} />
      </Switch>
      <Panel>
        <RoomButton icon={panelIcon} iconSvg={<RoomElectricityPanelSvg />} />
      </Panel>
      <Outlet>
        <RoomButton icon={outletIcon} iconSvg={<RoomElectricityOutletSvg />} />
      </Outlet>
      <Sink>
        <RoomButton icon={sinkIcon} iconSvg={<RoomSinkUtilitySvg />} />
      </Sink>
      <HotWaterHeater>
        <RoomButton
          icon={hotWaterHeaterIcon}
          iconSvg={<RoomHotWaterHeaterSvg />}
        />
      </HotWaterHeater>
      <Vent>
        <RoomButton icon={ventIcon} iconSvg={<RoomVentSvg />} />
      </Vent>
    </Container>
  );
};
