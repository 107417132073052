import { SET_CAROUSEL_NATURAL_TOP_HEIGHT } from 'actions';
import { CarouselReduxReducer } from 'typings';

export function setCarouselNaturalTopHeight(
  reduxReducer: CarouselReduxReducer,
  naturalTopHeight: number,
) {
  return {
    naturalTopHeight,
    reduxReducer,
    type: SET_CAROUSEL_NATURAL_TOP_HEIGHT,
  };
}
