import { CarouselItemId } from '@homex/se-widget-flow-types';

import { RESET_APP } from 'actions';
import { carousel } from 'reducers/carousel';
import {
  CarouselReduxReducer,
  IAppointmentCarouselAction,
  IAppointmentCarouselState,
} from 'typings';

const defaultState: IAppointmentCarouselState = {
  currentItemId: CarouselItemId.Appointment,
  history: [],
  shouldRecalculateHeight: false,
};

export default function appointmentCarousel(
  state: IAppointmentCarouselState = defaultState,
  action: IAppointmentCarouselAction,
): IAppointmentCarouselState {
  switch (action.type) {
    case RESET_APP:
      return {
        ...defaultState,
        bottomHeight: state.bottomHeight,
        topHeight: state.topHeight,
      };
    default:
      if (action.reduxReducer !== CarouselReduxReducer.APPOINTMENT) {
        return state;
      }

      return carousel(state, action);
  }
}
