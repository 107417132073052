import { CarouselItemId, NavButtonType } from '@homex/se-widget-flow-types';
import { useSelector } from 'react-redux';

import { CarouselBiDirectionButton } from 'components/CarouselBiDirectionButton';
import { CarouselContinueButton } from 'components/CarouselContinueButton';
import { ICarouselButtonBottomProps, IState } from 'typings';

export const CarouselBottomButton = ({
  buttonType,
  backButtonLabel,
  closeLabel,
  carouselItemId,
  destinationItemId,
  errorMessage,
  homeLabel,
  isDisabled,
  isLoading,
  continueButtonLabel,
  onBackClick,
  onContinueClick = () => {
    return;
  },
  buttonFormat,
  retryLabel,
}: ICarouselButtonBottomProps) => {
  const { startingCarouselItemId } = useSelector((state: IState) => ({
    startingCarouselItemId: state.client.flow?.defaultCarouselItemId,
  }));

  const formatToRender =
    (startingCarouselItemId && carouselItemId !== startingCarouselItemId) ||
    (!startingCarouselItemId &&
      carouselItemId !== CarouselItemId.IssueSpecifier)
      ? buttonFormat
      : NavButtonType.Default;

  switch (formatToRender) {
    case NavButtonType.BackButtonOnBottom:
      return (
        <CarouselBiDirectionButton
          backButtonLabel={backButtonLabel}
          buttonType={buttonType}
          closeLabel={closeLabel}
          continueButtonLabel={continueButtonLabel}
          destinationItemId={destinationItemId}
          errorMessage={errorMessage}
          homeLabel={homeLabel}
          isDisabled={isDisabled}
          isLoading={isLoading}
          onBackClick={onBackClick}
          onContinueClick={onContinueClick}
          retryLabel={retryLabel}
        />
      );

    case NavButtonType.Default:
    default:
      return (
        <CarouselContinueButton
          buttonType={buttonType}
          closeLabel={closeLabel}
          destinationItemId={destinationItemId}
          errorMessage={errorMessage}
          homeLabel={homeLabel}
          isDisabled={isDisabled}
          isLoading={isLoading}
          label={continueButtonLabel}
          onClick={onContinueClick}
          retryLabel={retryLabel}
        />
      );
  }
};
