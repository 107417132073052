import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const deskStyles = css`
  bottom: 100px;
  height: 111px;
  left: 50%;
  margin-left: -64px;
  position: absolute;
  width: 128px;

  ${medium`
    height: 124px;
    margin-left: -72px;
    width: 143px;
  `}
`;

export const Light = styled.div`
  height: 70px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
  top: -1px;
  width: 59px;

  ${medium`
    height: 75px;
    margin-left: -32px;
    top: -12px;
    width: 63px;
  `}

  ${large`
    top: 0;
  `}
`;

export const Switch = styled.div`
  height: 23px;
  left: 4%;
  position: absolute;
  top: 77px;
  width: 13px;

  ${medium`
    height: 25px;
    left: 17px;
    top: 64px;
    width: 15px;
  `}

  ${large`
    left: 44px;
    top: 92px;
  `}
`;

export const Outlet = styled.div`
  bottom: 130px;
  height: 23px;
  left: 4%;
  position: absolute;
  width: 13px;

  ${medium`
    bottom: 134px;
    height: 25px;
    left: 17px;
    width: 15px;
  `}

  ${large`
    left: 44px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 21px;
  position: absolute;
  right: 3.7%;
  width: 49px;

  ${medium`
    height: 23px;
    right: 11px;
    width: 54px;
  `}

  ${large`
    right: 40px;
  `}
`;

export const Thermostat = styled.div`
  height: 26px;
  position: absolute;
  right: 5.3%;
  top: 77px;
  width: 39px;

  ${medium`
    height: 28px;
    right: 16px;
    top: 64px;
    width: 43px;
  `}

  ${large`
    right: 45px;
    top: 92px;
  `}
`;
