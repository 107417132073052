import styled from 'styled-components/macro';

import chevron from 'assets/selectbox-chevron.svg';
import { formHeadingThin } from 'styles/typography';
import { IWithThemeProps } from 'typings';

interface ITitleTextProps {
  isCollapsed: boolean;
}

interface IContentsProps {
  isCollapsed: boolean;
}

export const Container = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const TitleText = styled.h3<ITitleTextProps>`
  ${formHeadingThin}

  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  position: relative;
  user-select: none;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.bottomForegroundPrimary};
  `}

  &:before {
    background: url(${chevron}) right center no-repeat;
    content: '';
    height: 100%;
    position: absolute;
    right: 12px;
    top: 0;
    width: 21px;

    ${({ isCollapsed }) => {
      return `
        transform: rotate(${isCollapsed ? '0' : '180deg'});
      `;
    }}
  }
`;

export const Title = styled.div`
  &:focus {
    outline: none;

    > ${TitleText} {
      font-weight: bold;
    }
  }
`;

export const Contents = styled.div<IContentsProps>`
  text-align: left;

  ${({ isCollapsed }) => {
    if (isCollapsed) {
      return `
        display: none;
      `;
    }

    return '';
  }}
`;
