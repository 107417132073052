export interface ISvgProps {
  buttonType: CarouselBottomButtonType;
  height: number;
  isLoading: boolean;
  width: number;
}

export interface ISvgContainerProps {
  buttonType: CarouselBottomButtonType;
  height: number;
  isLoading: boolean;
}

export interface IButtonProps {
  buttonType: CarouselBottomButtonType;
  isLoading: boolean;
}

export interface ILabelContainerProps {
  isLoading: boolean;
}

export enum CarouselBottomButtonType {
  BACK = 'BACK',
  CLOSE = 'CLOSE',
  CONTINUE = 'CONTINUE',
  CONTINUE_DOWN = 'CONTINUE_DOWN',
  HOME = 'HOME',
  RETRY = 'RETRY',
}
export interface ICarouselBottomButtonSvgProps
  extends React.SVGAttributes<SVGElement> {
  buttonType?: CarouselBottomButtonType;
}
