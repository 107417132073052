import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

const floorStyles = `
  bottom: 100px;
  position: absolute;
`;

export const doorStyles = css`
  ${floorStyles}

  height: 170px;
  left: 44.5%;
  width: 84px;

  ${medium`
    height: 186px;
    left: 157px;
    width: 92px;
  `}

  ${large`
    left: 213px;
  `}
`;

export const endTableStyles = css`
  ${floorStyles}

  height: 122px;
  left: 25%;
  width: 52px;

  ${medium`
    height: 132px;
    left: 89px;
    width: 57px;
  `}

  ${large`
    left: 141px;
  `}
`;

const windowStyles = css`
  bottom: 158px;
  height: 108px;
  position: absolute;
  width: 62px;

  ${medium`
    height: 116px;
    width: 68px;
  `}
`;

export const windowStylesLeft = css`
  ${windowStyles}

  left: 3.5%;

  ${medium`
    left: 14px;
  `}

  ${large`
    left: 42px;
  `}
`;

export const windowStylesRight = css`
  ${windowStyles}

  right: 3.5%;

  ${medium`
    right: 11px;
  `}

  ${large`
    right: 42px;
  `}
`;
