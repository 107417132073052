import styled, { css } from 'styled-components/macro';

import { large, medium } from 'styles/breakpoints';
import { room } from 'styles/layout';

export const Container = styled.div`
  ${room}
`;

export const carStyles = css`
  bottom: 100px;
  height: 130px;
  left: -29%;
  position: absolute;
  width: 159px;

  ${medium`
    height: 146px;
    left: -111px;
    width: 179px;
  `}

  ${large`
    left: -95px;
  `}
`;

export const Light = styled.div`
  height: 52px;
  left: 34.3%;
  position: absolute;
  top: 0;
  width: 47px;

  ${medium`
    height: 59px;
    left: 117px;
    width: 53px;
  `}

  ${large`
    left: 150px;
  `}
`;

export const Switch = styled.div`
  height: 22px;
  left: 24.3%;
  position: absolute;
  top: 80px;
  width: 13px;

  ${medium`
    height: 25px;
    left: 81px;
    top: 64px;
    width: 15px;
  `}

  ${large`
    left: 103px;
    top: 98px;
  `}
`;

export const Panel = styled.div`
  height: 64px;
  position: absolute;
  right: 27.7%;
  top: 73px;
  width: 42px;

  ${medium`
    height: 72px;
    right: 97px;
    top: 56px;
    width: 47px;
  `}

  ${large`
    right: 139px;
    top: 84px;
  `}
`;

export const Outlet = styled.div`
  bottom: 125px;
  height: 22px;
  left: 24.5%;
  position: absolute;
  width: 13px;

  ${medium`
    bottom: 128px;
    height: 25px;
    left: 80px;
    width: 15px;
  `}

  ${large`
    left: 103px;
  `}
`;

export const Sink = styled.div`
  bottom: 101px;
  height: 107px;
  left: 30.2%;
  position: absolute;
  width: 74px;

  ${medium`
    height: 120px;
    left: 102px;
    width: 83px;
  `}

  ${large`
    left: 135px;
  `}
`;

export const HotWaterHeater = styled.div`
  bottom: 100px;
  height: 152px;
  position: absolute;
  right: 2.5%;
  width: 68px;

  ${medium`
    height: 171px;
    right: 10px;
    width: 77px;
  `}

  ${large`
    right: 13px;
  `}
`;

export const Vent = styled.div`
  bottom: 110px;
  height: 21px;
  position: absolute;
  right: 26.5%;
  width: 49px;

  ${medium`
    height: 23px;
    right: 92px;
    width: 54px;
  `}

  ${large`
    right: 135px;
  `}
`;
