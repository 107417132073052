import styled, { css } from 'styled-components/macro';

export const imageStyles = css`
  height: 98px;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 200px;
  position: relative;
  width: 50%;

  &:nth-child(even) {
    &:after {
      background: rgba(0, 0, 0, 0.2);
      bottom: 0;
      content: '';
      display: block;
      height: calc(100% - 20px);
      left: 0;
      margin: auto;
      position: absolute;
      top: 0;
      width: 1px;
    }
  }

  &:nth-child(1n + 3) {
    &:before {
      background: rgba(0, 0, 0, 0.2);
      content: '';
      display: block;
      height: 1px;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - 20px);
    }
  }
`;

export const Button = styled.div`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  padding: 17px 0;
`;
