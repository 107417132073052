import { IFlow } from '@homex/se-widget-flow-types';

import {
  CountryCode,
  IThemeFromUiConfig,
  ITrade,
  RoomIconKey,
  RoomKey,
  ServiceType,
  TakeDefinite,
} from 'typings';
import { IGetCustomerLocationsByPhoneQuery } from 'typings/GraphQL';

interface IPhone {
  id: string;
  isMobile: boolean;
  number: string;
}

export interface IPhonePartial extends Partial<IPhone> {}

export interface IGetServiceZoneFromZipCodeResponse {
  getServiceZoneFromZipCode?: {
    isInServiceZone: boolean;
    locality: {
      city: string;
      countryCode: CountryCode;
      state: string;
    } | null;
    serviceZoneTimezone: string | null;
  } | null;
}
export interface IGetServiceZoneFromZipCodeVariables {
  enterpriseVersion?: string;
  serviceType?: ServiceType;
  zipCode: string;
}

export interface IContact {
  avatarMediaId: string;
  firstName: string;
  id: string;
  lastName: string;
  title: string;
}

interface ICustomerContact {
  email: string;
  firstName: string;
  lastName: string;
  name: string;
}

interface ILocation {
  addressId: string;
  appointments?: Array<IAppointment>;
  city: string;
  id: string;
  name: string;
  state: string;
  street1: string;
  street2: string | null;
  timezone: string;
  zipCode: string;
}

export interface ICurrentCustomerAppointment
  extends Omit<IAppointment, 'timeSlot'> {}

interface ICurrentCustomerLocation extends Omit<ILocation, 'appointments'> {
  appointments?: Array<ICurrentCustomerAppointment>;
}

export interface ICustomer {
  contact: ICustomerContact;
  id: string;
  locations?: Array<ICurrentCustomerLocation>;
  phone: IPhone;
}

export type CustomerLocation = TakeDefinite<
  TakeDefinite<
    IGetCustomerLocationsByPhoneQuery,
    'getCustomerLocationsByPhone'
  >,
  'customerLocations'
>[0];

export interface IWidgetUiConfigResponse {
  countryCode: Array<CountryCode>;
  flow: IFlow | null;
  theme: IThemeFromUiConfig;
  validOrigins: Array<string>;
  validServiceTypes: Array<ServiceType>;
}

export interface IAddressInput {
  city: string;
  countryCode?: CountryCode;
  id: string;
  state: string;
  street1: string;
  street2?: string | null;
  timezone: string;
  zipCode: string;
}

export interface IAddress extends Partial<IAddressInput> {}

export interface IAppointment {
  id: string;
  isTentative: boolean;
  jobStatus: AppointmentJobStatus;
  location: IAppointmentLocation;
  serviceCode: IAppointmentServiceCode;
  serviceDate: string;
  serviceZone: IAppointmentServiceZone;
  technician: IAppointmentTechnician | null;
  timeSlot: IAppointmentTimeSlot;
}

interface IAppointmentLocation {
  city: string;
  point: [number, number];
  state: string;
  street1: string;
  street2?: string;
  timezone: string;
  zipCode: string;
}

interface IAppointmentServiceCode {
  icon: {
    key: RoomIconKey;
  };
  name: string;
}

interface IAppointmentServiceZone {
  timezone: string;
}

interface IAppointmentTechnician {
  avatarBannerMediaId: string;
  avatarMediaId: string;
  bio: string;
  currentLocation: [number, number] | null;
  estimatedArrivalTime: string | null;
  firstName: string;
  fullName: string;
  id: string;
  lastName: string;
  title: string;
}

export interface IAppointmentTimeSlot {
  endTime: string;
  label: string;
  startTime: string;
}

export enum AppointmentJobStatus {
  DISPATCHED = 'DISPATCHED',
  ON_SITE = 'ON_SITE',
  SCHEDULED = 'SCHEDULED',
}

export interface IIssueSpecOption {
  isEmergency: boolean | null;
  label: string | null;
  nextValue: string | null;
  note: string | null;
  serviceCodeId: string | null;
  toIssueId: string | null;
}

export interface IIssueSpecDetail {
  info: string | null;
  label: string | null;
  note: string | null;
  notice: string | null;
  options: Array<IIssueSpecOption> | null;
  serviceCodeId: string | null;
  toIssueId: string | null;
  warn: string | null;
}

export interface IIssueSpecNext {
  details: IIssueSpecDetail;
  value: string;
}

interface IIssueSpec {
  details: IIssueSpecDetail;
  next: Array<IIssueSpecNext> | null;
  title: string | null;
}

export interface IIssue {
  id: string;
  isActive: boolean;
  isEmergency: boolean;
  isGeneral: boolean;
  key: string;
  name: string;
  specifier: IIssueSpec;
  trade: ITrade;
  uiGrouping: string;
}

interface IServiceCode {
  description: string | null;
  icon: {
    key: RoomIconKey;
  };
  id: string;
  key: string;
  name: string;
  trade: ITrade;
}

export interface IAccountTrackingInput {
  organizationId?: string;
  personId?: string;
  propertyId?: string;
}

export interface ISourceTrackingAnalyticsInput {
  affiliate: string;
  bookingSource: 'schedule_engine_widget';
  bookingSourceUrl: string;
  brand: string;
  broker: string;
  campaign: string;
  clid: string;
  clientReferrer?: string;
  customField1?: string;
  customField10?: string;
  customField2?: string;
  customField3?: string;
  customField4?: string;
  customField5?: string;
  customField6?: string;
  customField7?: string;
  customField8?: string;
  customField9?: string;
  fbclid: string;
  flowSource: string;
  gclid: string;
  leadSource: string;
  preferredPartner?: string;
  rwgToken: string;
  subcontractor: string;
  utmCampaign: string;
  utmContent: string;
  utmMedium: string;
  utmSource: string;
  utmTerm: string;
}

export interface IRoomIcon {
  commonProblems: string | null;
  id: string;
  issue: IIssue | null;
  key: RoomIconKey;
  name: string;
  serviceCode: IServiceCode | null;
}

export interface IRoom {
  icons: Array<IRoomIcon>;
  id: string;
  issues: Array<IIssue>;
  key: RoomKey;
  name: string;
}

export interface IRoomsResponse {
  rooms?: Array<IRoom> | null;
}

export interface IIssueResponse {
  issue: IIssue | null;
}

export interface IServiceCodeResponse {
  serviceCode: IServiceCode | null;
}

export interface IBlackoutPeriod {
  days: number;
  id: string;
  isNonContractOnly: boolean;
  message: string;
}

export interface IPartnerAddress {
  addressLine1: string;
  addressLine2?: string | null;
  countryCode: string;
  locality: string;
  postalCode: string;
  region: string;
}

export interface IPartner {
  address: IPartnerAddress;
  distanceInMeters?: number | null;
  isSubcontractorMatch: boolean;
  name: string;
  partnerId: string;
}
