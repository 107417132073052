import { css } from 'styled-components/macro';

export const largeQuery = '(min-width: 768px)';
export const mediumQuery = '(min-width: 375px)';

export const large = (
  parts: TemplateStringsArray,
  ...args: Array<string>
) => css`
  @media ${largeQuery} {
    ${css(parts, ...args)}
  }
`;

export const medium = (
  parts: TemplateStringsArray,
  ...args: Array<string>
) => css`
  @media ${mediumQuery} {
    ${css(parts, ...args)}
  }
`;
