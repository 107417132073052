import { CarouselItemId } from '@homex/se-widget-flow-types';

export default function formatCarouselItemId(id: CarouselItemId) {
  // CarouselItemIds may be split into parts with the CarouselItemId at the
  // beginning, and a resource (appointment, issue, etc.) ID at the end
  const [title, extraId] = id.split('-');

  if (!title) {
    return id;
  }

  let result = title
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  if (extraId) {
    result += ` (${extraId})`;
  }

  return result;
}
