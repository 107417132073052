import { SET_CONTACT_INFO_PHONE_INPUT_VALUE } from 'actions';

export const setContactInfoPhoneInputValue = (
  contactInfoPhoneInputValue?: string,
) => {
  return {
    contactInfoPhoneInputValue,
    type: SET_CONTACT_INFO_PHONE_INPUT_VALUE,
  };
};
