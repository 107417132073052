import styled from 'styled-components/macro';

import { large } from 'styles/breakpoints';
import { navLabel } from 'styles/typography';
import { IWithThemeProps } from 'typings';

interface IItemProps {
  currentStepIndex: number;
  index: number;
}

export const Item = styled.li<IItemProps>`
  ${navLabel}

  letter-spacing: 0.3px;
  user-drag: none;
  user-select: none;
  width: 64px;

  ${({ theme }: IWithThemeProps) => `
    color: ${theme.topForegroundMuted};
    transition: color ${theme.transitionDurationCarouselTrack} ease;
  `}

  ${large`
    width: 80px;
  `}

  &:after {
    content: '';
    display: block;
    height: 1px;
    position: relative;
    top: -21px;
    right: -60%;
    width: 81%;

    ${({ theme }: IWithThemeProps) => `
      background-color: ${theme.topForegroundMuted};
    `}

    ${large`
      right: -46px;
      width: 68px;
    `}
  }

  &:before {
    background-color: transparent;
    border-radius: 100%;
    content: '';
    display: block;
    height: 10px;
    margin: 0 auto 2px;
    width: 10px;

    ${({ theme }: IWithThemeProps) => `
      border: 1px solid ${theme.topForegroundMuted};
      transition: background-color ${theme.transitionDurationCarouselTrack} ease;
    `}
  }

  &:last-of-type {
    &:after {
      display: none;
    }
  }

  ${({ currentStepIndex, index, theme }) => {
    if (index === currentStepIndex) {
      return `
        color: ${theme.topForegroundPrimary};

        &:before {
          background-color: ${theme.navSelectedBackground};
        }
      `;
    } else if (index < currentStepIndex) {
      return `
        cursor: pointer;

        &:before {
          background-color: ${theme.topForegroundMuted};
        }
      `;
    }

    return '';
  }}
`;
