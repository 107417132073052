import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { startSession } from 'actions';
import { IState } from 'typings';
import {
  buildDeleteOptions,
  buildGetOptions,
  buildPostOptions,
} from 'utils/request';

export function fetchWithAuth(
  input: RequestInfo,
  init?: RequestInit,
  retry?: boolean,
) {
  return async (
    dispatch: ThunkDispatch<IState, null, Action>,
    getState: () => IState,
  ) => {
    let response = await fetch(input, init);

    if (response.status === 401) {
      await dispatch(startSession({ shouldVerifyExistingSession: false }));

      if (retry) {
        const { sessionToken } = getState().auth;
        let options = {};

        if (init) {
          switch (init.method) {
            case 'post':
              options = buildPostOptions({
                body: init.body,
                token: sessionToken,
              });

              break;
            case 'delete':
              options = buildDeleteOptions({
                token: sessionToken,
              });

              break;
            case 'get':
              options = buildGetOptions({
                token: sessionToken,
              });

              break;
            default:
              throw new Error('Request method not yet supported.');
          }
        }

        response = await fetch(input, { ...init, ...options });
      }
    }

    return response;
  };
}
